import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const LikeList = lazy(() =>
  import("../../legacyPages/user/LikeList.view").then(module => ({
    default: module.LikeList,
  }))
);
const MiniShop = lazy(() =>
  import("../../modules/LikeList/MiniShop.module").then(module => ({
    default: module.MiniShop,
  }))
);

export const WishesRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={null}>
            <LikeList />
          </Suspense>
        }
      >
        <Route
          path="products"
          element={
            <Suspense fallback={null}>
              <LikeList />
            </Suspense>
          }
        />
        <Route
          path="shops"
          element={
            <Suspense fallback={null}>
              <MiniShop />
            </Suspense>
          }
        />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
