export const USER_PAGE_GRID_MENU = [
  {
    path: "/wishes/products",
    title: "찜 목록",
    img: "/assets/icons/icon_favorite.svg",
  },
  { path: "/talk", title: "쪽지", img: "/assets/icons/icon_chat.svg" },
  // {
  //   path: "/myshop/info/address",
  //   title: "내 배송지",
  //   img: "/assets/icons/icon_delivery_l.svg",
  // },
];

export const BUY_DELIVERY_STATUS = [
  { text: "입금대기", number: 3 },
  { text: "입금확인", number: 1 },
  { text: "배송중", number: 0 },
  { text: "구매완료", number: 6 },
];

export const BUY_STATUS = [
  { text: "취소", number: 3, path: "cancel" },
  { text: "반품", number: 0, path: "return" },
  { text: "교환", number: 2, path: "exchange" },
];

export const SALE_DELIVERY_STATUS = [
  { text: "미발송", number: 1 },
  { text: "배송중", number: 0 },
  { text: "배송중", number: 0 },
  { text: "구매결정", number: 3 },
];

export const ORDER_LIST_STATUS = [
  {
    process: "입금확인",
    img: "",
    item_num: "DA1998995",
    title: "지브이투어 어린이골프채 풀세트 GvTOUR JUNOOR PRO 사이즈M(7~10세)",
    option_num: "7",
    pcs: "1",
    price: "140000",
  },
];

export const BUY_ORDER_ITEMS = [
  { date: "2021.07.21", basket_number: "GM1626842881-44" },
  { date: "2021.09.05", basket_number: "basket_number" },
];

export const GRID_CAROUSEL_IMAGE = [
  {
    text: "지브이투어 어린이골프채 풀세트 GvTOUR JUNOOR PRO 사이즈M(7~10세)",
    price: "140000",
    img: "https://picsum.photos/100/50?random=1",
  },
  {
    text: "지브이투어 어린이골프채 풀세트 GvTOUR JUNOOR PRO 사이즈M(7~10세)",
    price: "22222",
    img: "",
  },
  {
    text: "지브이투어 어린이골프채 풀세트 GvTOUR JUNOOR PRO 사이즈M(7~10세)",
    price: "33333",
    img: "https://picsum.photos/50/100?random=3",
  },
  {
    text: "지브이투어 어린이골프채 풀세트 GvTOUR JUNOOR PRO 사이즈M(7~10세)",
    price: "444444",
    img: "https://picsum.photos/70/30?random=4",
  },
  {
    text: "지브이투어 어린이골프채 풀세트 GvTOUR JUNOOR PRO 사이즈M(7~10세)",
    price: "5555555",
    img: "https://picsum.photos/300/300?random=5",
  },
  {
    text: "지브이투어 어린이골프채 풀세트 GvTOUR JUNOOR PRO 사이즈M(7~10세)",
    price: "6666666",
    img: "https://picsum.photos/50/50?random=6",
  },
  {
    text: "지브이투어 어린이골프채 풀세트 GvTOUR JUNOOR PRO 사이즈M(7~10세)",
    price: "140000",
    img: "https://picsum.photos/300/300?random=7",
  },
  {
    text: "지브이투어 어린이골프채 풀세트 GvTOUR JUNOOR PRO 사이즈M(7~10세)",
    price: "22222",
    img: "https://picsum.photos/300/300?random=8",
  },
  {
    text: "지브이투어 어린이골프채 풀세트 GvTOUR JUNOOR PRO 사이즈M(7~10세)",
    price: "33333",
    img: "https://picsum.photos/300/300?random=9",
  },
  {
    text: "지브이투어 어린이골프채 풀세트 GvTOUR JUNOOR PRO 사이즈M(7~10세)",
    price: "444444",
    img: "https://picsum.photos/300/300?random=10",
  },
  {
    text: "지브이투어 어린이골프채 풀세트 GvTOUR JUNOOR PRO 사이즈M(7~10세)",
    price: "5555555",
    img: "https://picsum.photos/300/300?random=11",
  },
  {
    text: "지브이투어 어린이골프채 풀세트 GvTOUR JUNOOR PRO 사이즈M(7~10세)",
    price: "6666666",
    img: "https://picsum.photos/300/300?random=12",
  },
  {
    text: "지브이투어 어린이골프채 풀세트 GvTOUR JUNOOR PRO 사이즈M(7~10세)",
    price: "140000",
    img: "https://picsum.photos/300/300?random=13",
  },
  {
    text: "지브이투어 어린이골프채 풀세트 GvTOUR JUNOOR PRO 사이즈M(7~10세)",
    price: "22222",
    img: "https://picsum.photos/300/300?random=14",
  },
  {
    text: "지브이투어 어린이골프채 풀세트 GvTOUR JUNOOR PRO 사이즈M(7~10세)",
    price: "33333",
    img: "https://picsum.photos/300/300?random=15",
  },
  {
    text: "지브이투어 어린이골프채 풀세트 GvTOUR JUNOOR PRO 사이즈M(7~10세)",
    price: "444444",
    img: "https://picsum.photos/300/300?random=16",
  },
];

export const MONTHLY_QUOTE_ITEM_LIST = [
  {
    img: "https://picsum.photos/40/40?random=1",
    name: "상품시세아이템TS",
    titleist: "Titleist",
    year: 2018,
    type: "드라이버",
    price: 140000,
  },
  {
    img: "https://picsum.photos/40/40?random=2",
    name: "상품시세아이템TS",
    titleist: "Titleist",
    year: 2018,
    type: "드라이버",
    price: 140000,
  },
];

export const STORE_AREA_LIST = [
  { text: "전체", code: 0, image: "" },
  { text: "서울", code: 1, image: "/assets/icons/icon_region_su.png" },
  { text: "경기/인천", code: 2, image: "/assets/icons/icon_region_gg.png" },
  { text: "충청", code: 3, image: "/assets/icons/icon_region_cc.png" },
  { text: "경상", code: 4, image: "/assets/icons/icon_region_gs.png" },
  { text: "전라", code: 5, image: "/assets/icons/icon_region_jl.png" },
  { text: "강원/제주", code: 6, image: "/assets/icons/icon_region_jj.png" },
];

export const UNSAFE_TRANSACTIONS_OPTIONS = [
  {
    optionName: "① 판매자가 직거래를 유도하는 경우",
    optionValue: "① 판매자가 직거래를 유도하는 경우",
  },
  {
    optionName: "② 본문내용 & 상품 이미지에 연락처 기재",
    optionValue: "② 본문내용 & 상품 이미지에 연락처 기재",
  },
  {
    optionName:
      "③ 상품명, 상품상세설명에 현금판매조건및 이중가격표시로 직거래 유도",
    optionValue:
      "③ 상품명, 상품상세설명에 현금판매조건및 이중가격표시로 직거래 유도",
  },
  {
    optionName:
      "④ 상품피해사례접수 혹은 다른 판매자의 상품내용&이미지를 도용하여 등록한 경우",
    optionValue:
      "④ 상품피해사례접수 혹은 다른 판매자의 상품내용&이미지를 도용하여 등록한 경우",
  },
  {
    optionName:
      "⑤ 아이디를 변경하여 올리는 동종 내용의 글 혹은 동일 IP로 등록되는 도배성 글",
    optionValue:
      "⑤ 아이디를 변경하여 올리는 동종 내용의 글 혹은 동일 IP로 등록되는 도배성 글",
  },
  {
    optionName:
      "⑥ 상품목록 혹은 홍보내용에 타 사이트의 홍보에 관련한 문구가 포함된 경우",
    optionValue:
      "⑥ 상품목록 혹은 홍보내용에 타 사이트의 홍보에 관련한 문구가 포함된 경우",
  },
  {
    optionName:
      "⑦ 중고에스크로장터와 관련없는 홍보 글이나 게시판 주제에서 벗어난 글",
    optionValue:
      "⑦ 중고에스크로장터와 관련없는 홍보 글이나 게시판 주제에서 벗어난 글",
  },
  {
    optionName:
      "⑧ 허위, 분류오류 또는 유인성 가격 표시 (판매가 허위 표기[1000만원 또는 100원등])",
    optionValue:
      "⑧ 허위, 분류오류 또는 유인성 가격 표시 (판매가 허위 표기[1000만원 또는 100원등])",
  },
  {
    optionName: "⑨ 인신비방성 또는 저속어를 포함한 글",
    optionValue: "⑨ 인신비방성 또는 저속어를 포함한 글",
  },
  {
    optionName:
      "⑩ 전문딜러로써 광고성 글과 다수의 상품을 한꺼번에 올리는 전문 판매자의 글",
    optionValue:
      "⑩ 전문딜러로써 광고성 글과 다수의 상품을 한꺼번에 올리는 전문 판매자의 글",
  },
  {
    optionName: "⑪ 매매안전구매 결제신청이 되었지만 판매거부한 경우",
    optionValue: "⑪ 매매안전구매 결제신청이 되었지만 판매거부한 경우",
  },
  {
    optionName: "⑬ 기타",
    optionValue: "⑬ 기타",
  },
];

export const PRODUCT_QUERY_OPTIONS = [
  { optionName: "상품", optionValue: "Q1" },
  { optionName: "배송", optionValue: "Q2" },
  { optionName: "반품/환불/취소", optionValue: "Q3" },
  { optionName: "교환/변경", optionValue: "Q4" },
  { optionName: "기타", optionValue: "Q5" },
];

// 고객 문의
export const CUSTOMER_INQUIRIES_OPTIONS = [
  { optionName: "회원/가입", optionValue: 1 },
  { optionName: "구매/결제문의", optionValue: 2 },
  { optionName: "구매/배송문의", optionValue: 3 },
  { optionName: "구매/교환,반품", optionValue: 4 },
  { optionName: "구매/취소,환불", optionValue: 5 },
  { optionName: "판매/상품등록", optionValue: 6 },
  { optionName: "판매/정산문의", optionValue: 7 },
  { optionName: "마일리지", optionValue: 8 },
  { optionName: "사이트오류", optionValue: 9 },
];

// 버그에러 신고
export const BUG_REPORT_OPTIONS = [
  { optionName: "--일반--", optionValue: "--일반--" },
  { optionName: "결제", optionValue: "결제" },
  { optionName: "검색", optionValue: "검색" },
  { optionName: "회원가입", optionValue: "회원가입" },
  { optionName: "인증회원 전환", optionValue: "인증회원 전환" },
  { optionName: "--마이페이지--", optionValue: "--마이페이지--" },
  { optionName: "주문조회", optionValue: "주문조회" },
  { optionName: "내상품팔기", optionValue: "내상품팔기" },
  { optionName: "쪽지", optionValue: "쪽지" },
  { optionName: "상품Q&A", optionValue: "상품Q&A" },
  { optionName: "배송정보 입력/조회", optionValue: "배송정보 입력/조회" },
  { optionName: "내 정보수정", optionValue: "내 정보수정" },
  { optionName: "--쇼핑몰--", optionValue: "--쇼핑몰--" },
  { optionName: "에스크로마켓", optionValue: "에스크로마켓" },
  { optionName: "가맹점중고마켓", optionValue: "가맹점중고마켓" },
  { optionName: "신상품할인샵", optionValue: "신상품할인샵" },
  { optionName: "--커뮤니티--", optionValue: "--커뮤니티--" },
  { optionName: "중고시세표", optionValue: "중고시세표" },
  { optionName: "클럽매칭시스템", optionValue: "클럽매칭시스템" },
  { optionName: "클럽 사용후기", optionValue: "클럽 사용후기" },
  { optionName: "클럽 피팅 Q&A", optionValue: "클럽 피팅 Q&A" },
  { optionName: "고객문의게시판", optionValue: "고객문의게시판" },
];

// 가품신고
export const FAKE_REPORT_OPTION = [
  {
    optionName: "⑫ 유명브랜드 유사상품,위조상품, 가짜(짝퉁)상품이 확실한 경우",
    optionValue: "⑫ 유명브랜드 유사상품,위조상품, 가짜(짝퉁)상품이 확실한 경우",
  },
];
