import { baseQueryKeys } from "shared/constants/baseQueryKeys";
import type { UserId } from "shared/types/User.types";
import type { ProductSellerPayload } from "./sellerApi";
import type {
  SellerProductInfoPayload,
  SellerProductsPayload,
} from "./sellerProductApi";

export const sellerProductQueryKey = {
  sellerProduct: baseQueryKeys.sellerProduct,

  item: (payload: SellerProductInfoPayload) =>
    [...sellerProductQueryKey.sellerProduct, "item", payload] as const,
  list: (userId: UserId, payload: SellerProductsPayload) =>
    [...sellerProductQueryKey.sellerProduct, "list", userId, payload] as const,

  seller: (payload: ProductSellerPayload) =>
    [...sellerProductQueryKey.sellerProduct, "seller", payload] as const,
};
