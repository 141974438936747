import PinchZoom from "pinch-zoom-js";
import { useEffect, useRef } from "react";
import Shared from "..";
import styles from "./ImageZoomModal.module.scss";
import type { ModalProps } from "./Modal";

type Props = ModalProps & {
  imageUrl: string;
};

export const ImageZoomModal = ({
  imageUrl,
  onClose,
  isOpen,
  ...rest
}: Props) => {
  const targetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!targetRef.current || !imageUrl || !isOpen) return;
    const pinchZoom = new PinchZoom(targetRef.current, {
      tapZoomFactor: 4,
      onDoubleTap: (pinch, event) => {
        event.preventDefault();
      },
    });
    // eslint-disable-next-line consistent-return
    return () => {
      pinchZoom.destroy();
    };
  }, [imageUrl, isOpen]);

  return (
    <Shared.ModalBase
      className={styles.ImageZoomModal}
      overlayProps={{ colorVariants: "black", animation: "fadeIn-slow" }}
      isOpen={isOpen}
      onClose={onClose}
      {...rest}
    >
      <div className={styles.pinchZoomContainer}>
        <div ref={targetRef}>
          <Shared.Image src={imageUrl} />
        </div>
      </div>

      <Shared.Button
        className={styles.closeButton}
        text="닫기"
        onClick={() => {
          onClose();
        }}
      />
    </Shared.ModalBase>
  );
};
