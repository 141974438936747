import type { CSSProperties, ReactNode, RefObject } from "react";
import { useRef } from "react";

const ANCHOR_OFFSET = `calc(-1 * var(--size-gnb))`;

type Props = {
  children: (ref: RefObject<HTMLDivElement>) => ReactNode;
  offset?: CSSProperties["top"];
};

/**
 * ScrollAnchorWrapper는 스크롤 위치를 조정할 때 특정 앵커 지점을 기준으로 하는 컴포넌트입니다.
 * 자식 컴포넌트는 스크롤 위치에 대한 참조(ref)를 받아 사용할 수 있습니다.
 *
 * @example
 * const MyComponent = () => {
 *   const scrollToAnchor = (ref) => {
 *      ref.current?.scrollIntoView({ behavior: "smooth" });
 *   };
 *
 *   return (
 *     <Shared.ScrollIntoViewWrapper>
 *       {(anchorRef) => (
 *         <div>
 *           <button onClick={() => scrollToAnchor(anchorRef)}>
 *             스크롤 이동
 *           </button>
 *           <div style={{ height: "150vh" }}>긴 콘텐츠</div>
 *         </div>
 *       )}
 *     </Shared.ScrollIntoViewWrapper>
 *   );
 * };
 */
export const ScrollAnchorWrapper = ({
  children,
  offset = ANCHOR_OFFSET,
}: Props) => {
  const anchorRef = useRef<HTMLDivElement>(null);

  return (
    <div style={{ position: "relative" }}>
      <div ref={anchorRef} style={{ position: "absolute", top: offset }} />
      {children(anchorRef)}
    </div>
  );
};
