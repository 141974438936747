import type { SaleType } from "entities/product";
import Shared from "shared/ui";

type Props = {
  saleType: SaleType;
  havingOption: boolean;
};

export const ProductBadges = ({ saleType, havingOption }: Props) => {
  return (
    <Shared.HStack align="center">
      {saleType === 0 && (
        <Shared.Image
          src="/assets/icons/icon_sin.png"
          width={16}
          alt="신상품 뱃지"
        />
      )}
      {saleType === 1 && (
        <Shared.Image
          src="/assets/icons/icon_joong.png"
          width={16}
          alt="중고 뱃지"
        />
      )}
      {havingOption && (
        <Shared.Image
          src="/assets/icons/icon_op.jpg"
          width={26}
          alt="옵션상품 뱃지"
        />
      )}
    </Shared.HStack>
  );
};
