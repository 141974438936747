import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const UserInquiryList = lazy(() =>
  import("../../legacyPages/user/UserInquiryList.view").then(module => ({
    default: module.UserInquiryList,
  }))
);
const UserReviewList = lazy(() =>
  import("../../legacyPages/user/UserReviewList").then(module => ({
    default: module.UserReviewList,
  }))
);

export const MyshopPurchasesRoutes = () => {
  return (
    <Routes>
      <Route
        path="qna"
        element={
          <Suspense fallback={null}>
            <UserInquiryList />
          </Suspense>
        }
      />
      <Route
        path="reviews"
        element={
          <Suspense fallback={null}>
            <UserReviewList />
          </Suspense>
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
