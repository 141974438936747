import { useQuery } from "@tanstack/react-query";
import { useAppSelector } from "app/appStore";
import { instance } from "shared/api";
import { api } from "shared/constants/api";
import type { APIResponse } from "shared/types/server";
import { pushKey } from "./queryKey";

type UnreadPushResponse = {
  userId: string;
  existUnReadPush: true;
};

const unreadPushApi = {
  /**
   * 읽지 않은 PUSH 있는지 확인
   * @see https://sbridge.atlassian.net/wiki/x/G4A-Gw
   */
  unread: async () => {
    const {
      data: { data: response },
    } = await instance.get<APIResponse<UnreadPushResponse>>(api.push.unread);

    return response;
  },
};

export function useUnreadPushQuery() {
  const { userId } = useAppSelector(state => state.user);

  return useQuery({
    queryKey: pushKey.unread(userId),
    queryFn: unreadPushApi.unread,
    enabled: !!userId,
  });
}
