import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const TalkListPage = lazy(() =>
  import("pages/talk").then(module => ({
    default: module.TalkListPage,
  }))
);

const ChatRoomPage = lazy(() =>
  import("pages/talk").then(module => ({
    default: module.ChatRoomPage,
  }))
);

export const TalkRoutes = () => {
  return (
    <Routes>
      <Route
        path=""
        element={
          <Suspense fallback={null}>
            <TalkListPage />
          </Suspense>
        }
      />
      <Route
        path=":userId"
        element={
          <Suspense fallback={null}>
            <ChatRoomPage />
          </Suspense>
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
