import Shared from "../..";
import { Header } from "../Header";
import { HomeHeaderContent } from "./HomeHeaderContent";
import { HomeNavigation } from "./HomeNavigation";

export const HomeHeader = () => {
  return (
    <Header size="l" style={{ paddingBottom: 0 }}>
      <Shared.VStack align="start" fullWidth gap={10}>
        <HomeHeaderContent />
        <HomeNavigation />
      </Shared.VStack>
    </Header>
  );
};
