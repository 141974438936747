import { useEffect, useState } from "react";

export const useTitle = (initialTitle: string | undefined) => {
  // const [title, setTitle] = useState(initialTitle);
  const updateTitle = () => {
    const htmlTitle = document.querySelector("title");
    initialTitle !== ""
      ? (htmlTitle!.innerText = `${initialTitle} | 프라이스골프`)
      : (htmlTitle!.innerText = `프라이스골프`);
  };

  useEffect(updateTitle, [initialTitle]);
  return useTitle;
};
