import classNames from "classnames";
import type { CSSProperties } from "react";
import styles from "./Badge.module.scss";

type Props = {
  text: string;
  size?: "s" | "m" | "l";
  colorVariant?: "red" | "red-light" | "gray" | "white" | "none";
  style?: CSSProperties;
  className?: string;
};

export const Badge = ({
  text,
  size = "l",
  colorVariant = "gray",
  style,
  className,
}: Props) => {
  return (
    <div
      className={classNames(
        styles.Badge,
        styles[`size-${size}`],
        styles[`color-${colorVariant}`],
        className
      )}
      style={style}
    >
      {text}
    </div>
  );
};
