import { useCartList } from "entities/cart";
import { Link } from "react-router-dom";
import "./CartIcon.scss";

export const LegacyCartIcon = () => {
  const { data } = useCartList();
  const count = data?.length ?? 0;

  return (
    <Link to="/cart" style={{ height: "40px" }} className="cart_icon">
      <img
        style={{ width: "40px", height: "40px" }}
        src="/assets/icons/icon_navi_basket.svg"
        alt="장바구니 아이콘"
      />
      {count > 0 && <span className="count">{count}</span>}
    </Link>
  );
};
