import classNames from "classnames";
import { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import Shared from "shared/ui";
import styles from "./HomeNavigation.module.scss";

const PATH_LIST = [
  {
    name: "홈", //
    path: "/",
    img: "/assets/icons/icon_home.svg",
  },
  {
    name: "가격인하",
    path: "/price-reduction",
    img: "/assets/icons/icon_price_reduction.svg",
  },
  {
    name: "해외직구",
    path: "/overseas",
    img: "/assets/icons/icon_overseas.svg",
  },
  {
    name: "신상품", //
    path: "/new",
    img: "/assets/icons/icon_new.svg",
  },

  {
    name: "파워존",
    path: "/power-zone/products",
    img: "/assets/icons/icon_powerzone.svg",
  },
  {
    name: "실시간",
    path: "/real-time",
    img: "/assets/icons/icon_realtime.svg",
  },
  {
    name: "시세표", //
    path: "/used-price",
    img: "/assets/icons/icon_price.svg",
  },
  {
    name: "고객센터", //
    path: "/help",
    img: "/assets/icons/icon_help.svg",
  },
] as const;

export const HomeNavigation = () => {
  const { pathname: currentPath } = useLocation();
  const targetRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (targetRef) {
      targetRef.current?.scrollIntoView({
        inline: "center",
      });
    }
  }, [currentPath]);

  return (
    <nav className={styles.HomeNavigation}>
      <Shared.HStack className={styles.list} gap={0}>
        {PATH_LIST.map(({ img, name, path }) => {
          const isActive =
            path === "/" ? currentPath === path : currentPath.includes(path);

          return (
            <Link
              key={name}
              to={path}
              ref={isActive ? targetRef : null}
              className={classNames(styles.link, isActive && styles.isActive)}
            >
              <Shared.Image className={styles.image} src={img} />
              <span className={styles.name}>{name}</span>
            </Link>
          );
        })}
      </Shared.HStack>
    </nav>
  );
};
