import { useQuery } from "@tanstack/react-query";
import { instance } from "shared/api";
import { api } from "shared/constants/api";
import { baseQueryKeys } from "shared/constants/baseQueryKeys";
import type { APIResponse } from "shared/types/server";
import type { ProductModelPayload, ProductModelResponse } from "./types";

const queryKey = [baseQueryKeys.product, "model"] as const;

const productModelApi = {
  getModels: async ({ brandCode, 대분류 }: ProductModelPayload) => {
    try {
      const {
        data: { data: response },
      } = await instance.get<APIResponse<ProductModelResponse>>(
        api.product.models,
        {
          params: {
            brandCode,
            categoryCode: 대분류,
          },
        }
      );

      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e.response.data.data.errorCode !== "ERR_001_003_003") {
        throw e;
      }

      return { modelList: [] };
    }
  },
};

export function useProductModelsQuery(payload: ProductModelPayload) {
  return useQuery({
    queryKey,
    queryFn: () => productModelApi.getModels(payload),
    enabled: Boolean(payload.brandCode && payload.대분류),
    select: data => data?.modelList,
  });
}
