import { useLockBodyScroll } from "shared/hooks";
import Shared from "..";

type Props = {
  spinner?: boolean;
};

export const LoadingOverlay = ({ spinner = true }: Props) => {
  useLockBodyScroll();

  return (
    <Shared.Overlay colorVariants="white-opacity">
      {spinner && <Shared.Spinner />}
    </Shared.Overlay>
  );
};
