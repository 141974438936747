import type { ElementType } from "shared/types/utils";

const SIZES = ["Bytes", "KB", "MB", "GB", "TB"] as const;
const BASE = 1024;

export type FileSize = `${number}${ElementType<typeof SIZES>}`;

/**
 * 주어진 바이트 수를 사람이 읽기 쉬운 문자열 형식으로 변환합니다.
 *
 * @example
 * ```typescript
 * const sizeString = bytesToSize(10485760); // "10.0 MB"
 * console.log(sizeString);
 * ```
 */
export function bytesToSize(bytes: number): string {
  if (bytes === 0) return "n/a";

  const i = Math.min(
    Math.floor(Math.log(bytes) / Math.log(BASE)),
    SIZES.length - 1
  );

  const size = (bytes / BASE ** i).toFixed(1);
  return `${size} ${SIZES[i]}`;
}

/**
 * 사람이 읽기 쉬운 크기 문자열을 바이트로 변환합니다.
 *
 * @example
 * ```typescript
 * const bytes = sizeToBytes("10.0 MB"); // 10485760
 * console.log(bytes);
 * ```
 */
export function sizeToBytes(sizeString: FileSize): number {
  const regex = /^(\d+(?:\.\d+)?)\s*(Bytes|KB|MB|GB|TB)$/i;
  const match = sizeString.match(regex);

  if (!match) throw new Error(`Invalid size format: ${sizeString}`);

  const [, size, unit] = match;
  const sizeIndex = SIZES.findIndex(
    s => s.toLowerCase() === unit.toLowerCase()
  );

  if (sizeIndex === -1) throw new Error(`Invalid unit: ${unit}`);

  return parseFloat(size) * BASE ** sizeIndex;
}
