/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import "./CheckBox.scss";

export const LegacyCheckBox = (props: {
  label: string;
  name: any;
  checked: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onClick?: any;
  id?: any;
  inputName?: string;
  register?: any;
  disabled: boolean;
  required?: boolean;
  className: string;
}) => {
  const {
    label,
    name,
    checked,
    onChange,
    className,
    register,
    inputName,
    disabled,
    required = false,
    onClick,
  } = props;

  return (
    <div id="CheckBox">
      <label>
        {register && inputName ? (
          <input
            type="checkbox"
            {...register(inputName, { required })}
            checked={checked}
            {...(onChange ? { onChange } : {})}
            readOnly
          />
        ) : (
          <input
            type="checkbox"
            name={name}
            checked={checked}
            // spellCheck={checked}
            onChange={onChange}
            onClick={onClick}
            disabled={disabled}
            className={className}
            // readOnly
          />
        )}

        {label && <span className="label">{label}</span>}
      </label>
    </div>
  );
};

LegacyCheckBox.defaultProps = {
  label: null,
  name: null,
  checked: null,
  onChange: null,
  id: null,
  disabled: false,
  className: "",
  // inputName: null,
  // register: null,
};
