// 판매등급
const SELLER_LEVEL = {
  0: "초보",
  1: "파워셀러",
  2: "초보",
  3: "일반",
  4: "우수",
  5: "최우수",
} as const;

export const getSellerLevelName = (
  sellerLevel: string | undefined = "0"
): string => {
  const key = parseInt(sellerLevel, 10) as keyof typeof SELLER_LEVEL;
  return SELLER_LEVEL[key] ?? SELLER_LEVEL[0];
};

// 신용등급
const TRUST_LEVEL = {
  SPROUT: "새싹",
  BRONZE: "브론즈",
  SILVER: "실버",
  GOLD: "골드",
} as const;

export const getTrustLevelName = (trustLevel: string | undefined) => {
  if (!trustLevel) return "";
  return TRUST_LEVEL[trustLevel as keyof typeof TRUST_LEVEL] ?? trustLevel;
};
