import { useCallback, useEffect, useRef } from "react";
import { useScrollListener } from "./useScrollListener";

type ScrollHandler = () => void;

export const useScrollEndListener = (
  handleScroll: ScrollHandler,
  handleScrollEnd: ScrollHandler,
  delay = 100,
  throttle = true
) => {
  const scrollTimeout = useRef<number | null>(null);

  const handleScrollEvent = useCallback(() => {
    handleScroll();

    if (scrollTimeout.current !== null) {
      clearTimeout(scrollTimeout.current);
    }

    scrollTimeout.current = window.setTimeout(() => {
      handleScrollEnd();
      scrollTimeout.current = null;
    }, delay);
  }, [handleScroll, handleScrollEnd, delay]);

  useScrollListener(handleScrollEvent, { throttle });

  useEffect(() => {
    return () => {
      if (scrollTimeout.current !== null) {
        clearTimeout(scrollTimeout.current);
      }
    };
  }, []);

  return null;
};
