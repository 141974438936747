import classNames from "classnames";
import { Link } from "react-router-dom";
import Shared from "shared/ui";
import type { PanelValueProps } from "./types";

export const PanelValue = ({ to, isPink, children }: PanelValueProps) => {
  return (
    <Link to={to}>
      <Shared.HStack
        className={classNames(isPink ? "redtext" : "graytext", "font_400")}
      >
        {children}
        <Shared.Icon name="chevronSmallRight" />
      </Shared.HStack>
    </Link>
  );
};
