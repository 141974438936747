import type { Dispatch, SetStateAction } from "react";
import { useMemo, useState } from "react";

export type UseBooleanController = {
  on: VoidFunction;
  off: VoidFunction;
  toggle: VoidFunction;
  setState: Dispatch<SetStateAction<boolean>>;
};

export type useBooleanOption = {
  onOff?: VoidFunction;
  onOn?: VoidFunction;
  onToggle?: VoidFunction;
};

export const useBoolean = (
  defaultState = false,
  options: useBooleanOption = {}
) => {
  const [state, setState] = useState(defaultState);

  const { onOff, onOn, onToggle } = options;

  const controller = useMemo<UseBooleanController>(
    () => ({
      on: () => {
        setState(true);
        onOn?.();
      },
      off: () => {
        setState(false);
        onOff?.();
      },
      toggle: () => {
        setState((prev) => !prev);
        onToggle?.();
      },
      setState,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return [state, controller] as const;
};
