import classNames from "classnames";
import { forwardRef, type InputHTMLAttributes, type ReactNode } from "react";
import { Icon } from "../Icon/Icon";
import styles from "./Checkbox.module.scss";

export type CheckboxProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "size"
> & {
  label?: string | ReactNode;
  size?: "s" | "m";
};

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ label, className, checked, disabled, size = "s", ...rest }, ref) => {
    return (
      <div
        className={classNames(
          styles.Checkbox,
          checked && styles.checked,
          disabled && styles.disabled,
          className
        )}
      >
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>
          <div className={classNames(styles.inputWrapper)}>
            <input
              className={classNames(styles[`size-${size}`])}
              type="checkbox"
              checked={checked}
              disabled={disabled}
              ref={ref}
              {...rest}
            />
            <Icon className={styles.checkIcon} name="check" />
          </div>

          {label}
        </label>
      </div>
    );
  }
);
