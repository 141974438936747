import classNames from "classnames";
import styles from "./Stack.module.scss";
import type { HStackProps, StackProps, VStackProps } from "./types";

export const Stack = ({
  display = "flex",
  direction,
  justify,
  align,
  reverse,
  wrap,
  className,
  style,
  gap = 4,
  fullWidth,
  ...rest
}: StackProps) => {
  return (
    <div
      className={classNames(
        styles.Stack,
        display && styles[`display-${display}`],
        direction && styles[`direction-${direction}`],
        justify && styles[`justify-${justify}`],
        align && styles[`align-${align}`],
        reverse && styles.reverse,
        wrap && styles.wrap,
        fullWidth && styles.fullWidth,
        className
      )}
      style={{ gap, ...style }}
      {...rest}
    />
  );
};

export const HStack = (props: HStackProps) => (
  <Stack direction="horizontal" {...props} />
);

export const VStack = (props: VStackProps) => (
  <Stack direction="vertical" {...props} />
);
