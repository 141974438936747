/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from "classnames";
import type { CSSProperties, HTMLAttributes, PropsWithChildren } from "react";
import { CloseButton } from "../Button/CloseButton";
import styles from "./Modal.module.scss";
import { ModalBase, type ModalBaseProps } from "./ModalBase";

export type ModalProps = ModalBaseProps & {
  closeButtonStyle?: CSSProperties;
};

export const Modal = ({
  className,
  children,
  onClose,
  closeButtonStyle,

  ...rest
}: ModalProps) => {
  return (
    <ModalBase
      className={classNames(styles.Modal, className)}
      onClose={onClose}
      {...rest}
    >
      <CloseButton
        className={styles.closeButton}
        onClick={onClose}
        style={closeButtonStyle}
      />

      <div className={styles.modalContent}>{children}</div>
    </ModalBase>
  );
};

const Header = ({
  children,
  className,
  ...rest
}: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
  return (
    <header className={classNames(styles.Header, className)} {...rest}>
      {children}
    </header>
  );
};

const Body = ({
  children,
  className,
  style,
}: PropsWithChildren<Pick<ModalProps, "className" | "style">>) => {
  return (
    <div className={classNames(styles.Body, className)} style={style}>
      {children}
    </div>
  );
};

Modal.Header = Header;
Modal.Body = Body;
