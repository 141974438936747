import classNames from "classnames";
import styles from "./Card.module.scss";
import { CardBody } from "./CardBody";
import { CardFooter } from "./CardFooter";
import { CardHeader } from "./CardHeader";
import { CardSection } from "./CardSection";
import type { CardProps } from "./types";

export const Card = ({
  className,
  disabled,
  children,
  metadata,
  innerStyle,
  ...rest
}: CardProps) => {
  return (
    <div
      className={classNames(
        styles.Card,
        disabled && styles.disabled,
        className
      )}
      {...rest}
    >
      {metadata && <div className={styles.metadata}>{metadata}</div>}
      <article className={styles.inner} style={innerStyle}>
        {children}
      </article>
    </div>
  );
};

Card.Header = CardHeader;
Card.Body = CardBody;
Card.Section = CardSection;
Card.Footer = CardFooter;
