import type { CSSProperties, ReactNode } from "react";
import type { IconProps } from "./Icon";
import { Icon } from "./Icon";
import styles from "./Icon.module.scss";

export interface LabeledIconProps extends IconProps {
  label: ReactNode;
  IconPosition?: "left" | "right";
  gap?: CSSProperties["gap"];
}

export const LabeledIcon = ({
  label,
  IconPosition = "left",
  gap,
  ...rest
}: LabeledIconProps) => {
  return (
    <div className={styles.LabeledIcon} style={{ gap }}>
      {IconPosition === "right" && label}
      <Icon {...rest} />
      {IconPosition === "left" && label}
    </div>
  );
};
