import { useQuery } from "@tanstack/react-query";
import { instance } from "shared/api";
import { api } from "shared/constants/api";
import type { APIResponse, ResponseData } from "shared/types/server";
import { pushKey } from "./queryKey";
import type { PushDetailItem } from "./types";

export type PushDetailPayload = {
  id: number;
};

type PushDetailResponse = ResponseData & {
  response: PushDetailItem;
};

const pushDetailApi = {
  /**
   * 푸시알림 상세 조회하기
   * @see https://sbridge.atlassian.net/wiki/x/BIAYGg
   */
  detail: async ({ id }: PushDetailPayload) => {
    const {
      data: { data: response },
    } = await instance.get<APIResponse<PushDetailResponse>>(
      `${api.push.detail}/${id}`
    );

    return response;
  },
};

export function usePushDetailQuery(payload: PushDetailPayload) {
  return useQuery({
    queryKey: pushKey.detail(payload),
    queryFn: () => pushDetailApi.detail(payload),
    select: data => data.response,
  });
}
