import type {
  OrderOption,
  ProductRegisterData,
} from "entities/product/seller-product";
import { DeliveryType, OptionType } from "entities/product/seller-product";

function adjustProductOption(
  optionInfo: ProductRegisterData["optionInfo"]
): ProductRegisterData["optionInfo"] {
  if (!optionInfo) return undefined;

  const { optionType, optionVersion, orderOption } = optionInfo;

  // combination과 independent가 중복으로 들어가지 않도록 설정
  if (optionVersion === "OLD") {
    const _orderOption: OrderOption =
      optionType === OptionType.조합형
        ? { combination: orderOption?.combination }
        : { independent: orderOption?.independent };

    return {
      optionVersion,
      optionType,
      orderOption: _orderOption,
    };
  }
  return optionInfo;
}

export const transformProductPayload = (data: ProductRegisterData) => ({
  ...data,
  optionInfo: adjustProductOption(data.optionInfo),
  modelCode:
    data.brandCode && data.modelCode === ""
      ? "0" // 브랜드코드가 있고, 모델코드가 없으면 0
      : data.modelCode,
  orgPrice: Number(data.orgPrice),
  usedPeriod: Number(data.usedPeriod),

  deliveryFee:
    Number(data.deliveryType) === DeliveryType.무료
      ? 0 // 무료배송이면 0원
      : data.deliveryFee,
});
