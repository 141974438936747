import { Link } from "react-router-dom";
import Shared from "shared/ui";
import styles from "./HomeSearchLink.module.scss";

export const HomeSearchLink = () => {
  return (
    <Link to="/search" className={styles.HomeSearchLink}>
      <Shared.HStack gap={8} fullWidth>
        <h1 className={styles.title}>
          <span>골프의 시작과 끝, &nbsp;프라이스골프</span>
        </h1>
        <span className={styles.icon}>
          <Shared.Icon
            name="search"
            size={25}
            color="var(--color-gray-darkest)"
          />
        </span>
      </Shared.HStack>
    </Link>
  );
};
