/* eslint-disable no-param-reassign */
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { UserState } from "@store/Store.types";
import type { RootState } from "app/appStore";

const initialState: UserState = {
  isLoggedIn: false,
  userId: null,
  role: [],
  navigationPath: {
    to: "",
    from: "",
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logIn(state, action: PayloadAction<Pick<UserState, "userId" | "role">>) {
      state.isLoggedIn = true;
      state.userId = action.payload?.userId;
      state.role = action.payload?.role;
    },
    logOut(state) {
      state.isLoggedIn = false;
      state.userId = null;
      state.role = [];
    },
    updateNavigationPath(
      state,
      action: PayloadAction<UserState["navigationPath"]>
    ) {
      state.navigationPath = action.payload;
    },
    resetNavigationPath(state) {
      state.navigationPath = {
        to: "",
        from: "",
      };
    },
    updateUserRole(state, action: PayloadAction<Pick<UserState, "role">>) {
      state.role = action.payload?.role;
    },
  },
  extraReducers: {},
});

export const selectHasAuthRole = (state: RootState): boolean =>
  state.user.role?.includes("auth") || false;

export default userSlice;
export const {
  logOut,
  logIn,
  updateNavigationPath,
  resetNavigationPath,
  updateUserRole,
} = userSlice.actions;
