import { Link } from "react-router-dom";
import Shared from "shared/ui";
import { useCartList } from "../../api/cartApi";
import styles from "./CartIcon.module.scss";

export const CartIcon = () => {
  const { data: cartList } = useCartList();
  const count = cartList?.length ?? 0;

  return (
    <Link to="/cart" className={styles.CartIcon}>
      <Shared.HStack
        align="center"
        justify="center"
        style={{ width: 40, height: 40 }}
      >
        <Shared.Icon name="cart" size={40} />
        {count > 0 && <span className={styles.count}>{count}</span>}
      </Shared.HStack>
    </Link>
  );
};
