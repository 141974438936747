import { useInfiniteQuery } from "@tanstack/react-query";
import { useAppSelector } from "app/appStore";
import { useCallback, useMemo } from "react";
import { instance } from "shared/api";
import { api } from "shared/constants/api";
import type { APIResponse, ResponseData } from "shared/types/server";
import { pushKey } from "./queryKey";
import type { PushCategory, PushListItem } from "./types";

export type PushListPayload = {
  category?: PushCategory;
  size?: number;
  lastCursor?: number;

  /** 전체리스트와 안읽은 메시지 리스트 구별
   *  전체: true, 안읽은 메시지: false
   */
  onlyUnRead?: boolean;
};

export type PushListResponse = ResponseData & {
  alramList: PushListItem[];
};

const pushApi = {
  /**
   * 푸시알림 리스트 조회하기
   * @see https://sbridge.atlassian.net/wiki/x/AwAYGg
   */
  list: async ({
    category = "전체",
    lastCursor,
    size,
    onlyUnRead,
  }: PushListPayload) => {
    const {
      data: { data: response },
    } = await instance.get<APIResponse<PushListResponse>>(api.push.list, {
      params: {
        category,
        size,
        lastCursor,
        onlyUnRead,
      },
    });

    return response;
  },
};

export function usePushListQuery(payload: PushListPayload) {
  const { userId } = useAppSelector(state => state.user);

  const { data, ...query } = useInfiniteQuery({
    queryKey: pushKey.list(userId, payload),
    queryFn: ({ pageParam }) => {
      return pushApi.list({
        ...payload,
        lastCursor: pageParam,
      });
    },

    getNextPageParam: lastPage => {
      const { alramList } = lastPage;
      const alramLength = alramList.length;
      const lastCursor = alramList[alramLength - 1]?.id;

      const hasNextPage = alramLength !== 0;
      return hasNextPage && lastCursor;
    },
    enabled: Boolean(userId),
  });

  const pushList = useMemo(
    () =>
      data?.pages.flatMap(({ alramList }) => alramList).filter(item => item),
    [data]
  );

  /** 오늘 받은 알림 */
  const todayList = useMemo(
    () => pushList?.filter(item => item.isToday),
    [pushList]
  );

  /** 이전 알림 */
  const restDayList = useMemo(
    () => pushList?.filter(item => !item.isToday),
    [pushList]
  );

  const refetchPage = useCallback(
    (notifyId: string | undefined) =>
      query.refetch({
        refetchPage: (lastPage, index, allPages) => {
          const { alramList } = allPages[index] as PushListResponse;
          return !!alramList.find(({ id }) => id.toString() === notifyId);
        },
      }),
    [query]
  );

  return { ...query, data, pushList, todayList, restDayList, refetchPage };
}
