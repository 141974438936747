import { useQuery } from "@tanstack/react-query";
import { instance } from "shared/api";
import { api } from "shared/constants/api";
import type { productReturnInfo } from "shared/types/Products.types";
import type { APIResponse, ResponseData } from "shared/types/server";
import { sellerProductQueryKey } from "./queryKey";

export type ProductSellerPayload = {
  productId: string;
};

type ProductSellerResponse = ResponseData & productReturnInfo;

const sellerApi = {
  /**
   * 상품 판매자 조회
   * @see https://sbridge.atlassian.net/wiki/x/AQBbBw
   */
  productSeller: async (payload: ProductSellerPayload) => {
    const {
      data: { data: response },
    } = await instance.get<APIResponse<ProductSellerResponse>>(
      api.product.seller(payload.productId)
    );

    return response;
  },
};

export function useProductSellerQuery(payload: ProductSellerPayload) {
  return useQuery({
    queryKey: sellerProductQueryKey.seller(payload),
    queryFn: () => sellerApi.productSeller(payload),
    enabled: Boolean(payload.productId),
  });
}
