export const ERROR_CODE = {
  common: {
    DuplicateRequest: "Duplicate request",
  },
  users: {
    ERR_COMMON_VALIDATION: "ERR_COMMON_VALIDATION",
    NOT_AUTHORIZED: "NOT_AUTHORIZED",

    // ===== 회원 로그인(/users/signin) 관련 =====
    /**  존재하지 않는 아이디거나 비밀번호가 잘못되었을 시 */
    ERR_002_002_001: "ERR_002_002_001",
    /**  인증회원(group: 23), 010번호가 아니고, 6개월 이내 인증한 이력이 없을 시 */
    ERR_002_002_003: "ERR_002_002_003",
    /**  비밀번호 변경 이력 체크 후 비밀번호 변경 대상 회원일 시 */
    ERR_002_002_004: "ERR_002_002_004",
    /**  토큰 만료시간이 종료되지 않았는데 로그인 시도 했을 시 */
    ERR_002_002_005: "ERR_002_002_005",
    /**  휴면계정일 때 */
    ERR_002_002_006: "ERR_002_002_006",

    // ===== 로그인 유지(/users/signin/keep/login) 관련 =====
    /** 존재하지 않는 ID입니다. */
    USERID_NOT_EXIST: "USERID_NOT_EXIST",
    /** 존재하지 않는 로그인 서비스 아이디 입니다. */
    ERR_002_002_007: "ERR_002_002_007",
    /** user-agent 불일치 */
    ERR_002_002_008: "ERR_002_002_008",

    // ===== 비회원 주문조회 문자인증(/users/nonmember/auth) 관련 =====
    /** 일치하는 주문번호가 없을 때 */
    ERR_002_003_001: "ERR_002_003_001",
    /** 비회원 주문건이 아닐 때 */
    ERR_002_003_002: "ERR_002_003_002",
    /** 제휴사에서 주문한 주문번호를 입력했을 때 */
    ERR_002_003_003: "ERR_002_003_003",
    /** 인증 요청한 휴대폰 번호와 주문건의 휴대폰 번호가 다를 시 */
    ERR_002_003_005: "ERR_002_003_005",
    /** 인증번호 유효시간 초과 시 */
    ERR_002_003_006: "ERR_002_003_006",
    /** 인증번호가 일치하지 않을 때 */
    ERR_002_003_007: "ERR_002_003_007",
  },
} as const;
