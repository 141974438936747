import classNames from "classnames";
import type { CSSProperties, ReactNode } from "react";
import styles from "./Header.module.scss";

type Props = {
  className?: string;
  children: ReactNode;

  size?: "m" | "l";
  style?: CSSProperties;
};

export const Header = ({ className, children, style, size = "m" }: Props) => {
  return (
    <header
      className={classNames(styles.Header, styles[`size-${size}`], className)}
      style={style}
    >
      {children}
    </header>
  );
};
