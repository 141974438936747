import { baseQueryKeys } from "shared/constants/baseQueryKeys";
import type { UserId } from "shared/types/User.types";
import type { PushDetailPayload } from "./pushDetailApi";
import type { PushListPayload } from "./pushListApi";

export const pushKey = {
  push: baseQueryKeys.push,

  list: (userId: UserId, payload: PushListPayload) =>
    [pushKey.push, userId, payload] as const,

  detail: (payload: PushDetailPayload) => [pushKey.push, payload] as const,

  unread: (userId: UserId) => [pushKey.push, "unread", userId] as const,
};
