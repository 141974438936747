import { removeLocalDataForSignOut } from "features/auth/sign-out";
import { lazy, Suspense, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const SignUp = lazy(() =>
  import("../../legacyPages/sign/SignUp.view").then(module => ({
    default: module.SignUp,
  }))
);

const SignUpAgreement = lazy(() =>
  import("../../legacyPages/sign/SignUpAgreement.view").then(module => ({
    default: module.SignUpAgreement,
  }))
);
const SignUpInformation = lazy(() =>
  import("../../legacyPages/sign/SignUpInformation.view").then(module => ({
    default: module.SignUpInformation,
  }))
);
const SignUpComplete = lazy(() =>
  import("../../legacyPages/sign/SignUpComplete.view").then(module => ({
    default: module.SignUpComplete,
  }))
);

const SignUpVerification = lazy(() =>
  import("../../legacyPages/sign/Verification.view").then(module => ({
    default: module.Verification,
  }))
);

export const SignupRoutes = () => {
  useEffect(() => {
    removeLocalDataForSignOut();
  }, []);

  return (
    <Routes>
      <Route
        path=""
        element={
          <Suspense fallback={null}>
            <SignUp />
          </Suspense>
        }
      />
      <Route
        path="agreement"
        element={
          <Suspense fallback={null}>
            <SignUpAgreement />
          </Suspense>
        }
      />
      <Route
        path="verification"
        element={
          <Suspense fallback={null}>
            <SignUpVerification />
          </Suspense>
        }
      />
      <Route
        path="info"
        element={
          <Suspense fallback={null}>
            <SignUpInformation />
          </Suspense>
        }
      />
      <Route
        path="welcome"
        element={
          <Suspense fallback={null}>
            <SignUpComplete />
          </Suspense>
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
