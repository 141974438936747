import { COOKIE_KEYS } from "shared/constants/cookieKeys";
import { removeCookie, setCookie } from "shared/lib/legacyUtils/cookie";

type PersistUserIdProps = {
  isPersist: boolean;
  id: string;
  expireDays?: number;
};

/** 로그인 아이디 저장 유무 체크 */
export function persistUserId({
  isPersist,
  id,
  expireDays = 30,
}: PersistUserIdProps) {
  if (isPersist) {
    const expires = new Date();
    expires.setDate(expires.getDate() + expireDays);
    setCookie(COOKIE_KEYS.USER_SAVE_ID, id, { expires, path: "/" });
  }
  if (!isPersist) {
    removeCookie(COOKIE_KEYS.USER_SAVE_ID, { path: "/" });
  }
}
