import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const UserInfo = lazy(() =>
  import("../../legacyPages/UserInfo.view").then(module => ({
    default: module.UserInfo,
  }))
);
const UserInfoEdit = lazy(() =>
  import("../../legacyPages/user/UserInfoEdit.view").then(module => ({
    default: module.UserInfoEdit,
  }))
);
const MyBusinessInfo = lazy(() =>
  import("../../legacyPages/user/MyBusinessInfo.view").then(module => ({
    default: module.MyBusinessInfo,
  }))
);
const MySettlementInfo = lazy(() =>
  import("../../legacyPages/user/MySettlementInfo.view").then(module => ({
    default: module.MySettlementInfo,
  }))
);
const MyInfoCheck = lazy(() =>
  import("../../legacyPages/user/MyInfoCheck.view").then(module => ({
    default: module.MyInfoCheck,
  }))
);
const MySettlementInfoCheck = lazy(() =>
  import("../../legacyPages/user/MySettlementInfoCheck.view").then(module => ({
    default: module.MySettlementInfoCheck,
  }))
);
const MyBusinessInfoCheck = lazy(() =>
  import("../../legacyPages/user/MyBusinessInfoCheck.view").then(module => ({
    default: module.MyBusinessInfoCheck,
  }))
);
const EditDeliveryAddr = lazy(() =>
  import("../../legacyPages/user/CheckoutAddr").then(module => ({
    default: module.EditDeliveryAddr,
  }))
);
const Withdrawal = lazy(() =>
  import("../../legacyPages/sign/Withdrawal.view").then(module => ({
    default: module.Withdrawal,
  }))
);

export const MyshopInfoRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={null}>
            <UserInfo />
          </Suspense>
        }
      />
      <Route
        path="user/check"
        element={
          <Suspense fallback={null}>
            <MyInfoCheck />
          </Suspense>
        }
      />

      <Route
        path="user-modify"
        element={
          <Suspense fallback={null}>
            <UserInfoEdit />
          </Suspense>
        }
      />
      <Route
        path="business"
        element={
          <Suspense fallback={null}>
            <MyBusinessInfo />
          </Suspense>
        }
      />
      <Route
        path="business/check"
        element={
          <Suspense fallback={null}>
            <MyBusinessInfoCheck />
          </Suspense>
        }
      />
      <Route
        path="settlement"
        element={
          <Suspense fallback={null}>
            <MySettlementInfo />
          </Suspense>
        }
      />
      <Route
        path="settlement/check"
        element={
          <Suspense fallback={null}>
            <MySettlementInfoCheck />
          </Suspense>
        }
      />

      <Route
        path="address/edit/:number"
        element={
          <Suspense fallback={null}>
            <EditDeliveryAddr />
          </Suspense>
        }
      />
      <Route
        path="withdrawal"
        element={
          <Suspense fallback={null}>
            <Withdrawal />
          </Suspense>
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
