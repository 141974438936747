import type { QueryObserverBaseResult } from "@tanstack/react-query";
import { useState } from "react";
import Shared from "..";
import { OverlayBase } from "./OverlayBase";

type Props = {
  retryFunction: QueryObserverBaseResult["refetch"];
};

export const ErrorOverlay = ({ retryFunction, ...rest }: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <OverlayBase
      position="absolute"
      colorVariants="white"
      style={{ zIndex: 10 }}
      {...rest}
    >
      <Shared.VStack align="center" justify="center" gap={16} fullWidth>
        <Shared.VStack align="center" justify="center" gap={8}>
          <span
            className="font_500"
            style={{ fontSize: 18, color: "var(--color-gray-darkest)" }}
          >
            잠시 후 다시 시도해주세요.
          </span>
          <Shared.VStack
            justify="center"
            fullWidth
            style={{
              textAlign: "center",
              color: "var(--color-gray-darker)",
              fontSize: 12,
              lineHeight: 1.25,
            }}
          >
            <span>데이터를 가지고 오는데 실패했습니다.</span>
            <span>문제가 지속되면 고객센터로 문의해주시기 바랍니다.</span>
          </Shared.VStack>
        </Shared.VStack>

        <Shared.HStack
          fullWidth
          justify="center"
          style={{ width: "82%", marginBottom: 8 }}
        >
          <Shared.LinkButton to="/help">고객센터로 이동</Shared.LinkButton>
          <Shared.Button
            colorVariant="red"
            fullWidth
            style={{ maxWidth: 180 }}
            isLoading={isLoading}
            onClick={async () => {
              setIsLoading(true);
              const result = await retryFunction();
              setIsLoading(result.isLoading);
            }}
          >
            다시 시도하기
          </Shared.Button>
        </Shared.HStack>
      </Shared.VStack>
    </OverlayBase>
  );
};
