type Direction = "top" | "bottom";

export const scrollTo = (direction: Direction, behavior?: ScrollBehavior) => {
  window.scrollTo({
    top: direction === "top" ? 0 : document.body.scrollHeight,
    behavior,
  });
};

export const scrollToTop = (behavior: ScrollBehavior = "auto") => {
  scrollTo("top", behavior);
};
