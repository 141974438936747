import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const ReportListPage = lazy(() =>
  import("pages/help/report").then(module => ({
    default: module.ReportListPage,
  }))
);
const ReportInquire = lazy(() =>
  import("../../modules/boards/Report/ReportInquire.view").then(module => ({
    default: module.ReportInquire,
  }))
);
const ReportRegister = lazy(() =>
  import("../../modules/boards/Report/ReportRegister").then(module => ({
    default: module.ReportRegister,
  }))
);

export const HelpReportRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={null}>
            <ReportListPage />
          </Suspense>
        }
      />
      <Route
        path=":number"
        element={
          <Suspense fallback={null}>
            <ReportInquire />
          </Suspense>
        }
      />
      <Route
        path="register"
        element={
          <Suspense fallback={null}>
            <ReportRegister />
          </Suspense>
        }
      />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
