import { useEffect, useRef, useState } from "react";
import { useDebounce } from "shared/hooks";

const LISTENER_OPTION = { passive: true };

export const useBottomSheetScroll = () => {
  const [canSwipe, setCanSwipe] = useState(true);
  const isInteractingRef = useRef(false); // 터치 또는 마우스 사용 중인지 여부
  const ref = useRef<HTMLDivElement | null>(null);
  const isMountedRef = useRef(true); // 컴포넌트가 마운트 상태인지 추적

  // 스크롤이 끝난 후에 실행될 함수
  const handleScrollEnd = useDebounce(() => {
    if (!isMountedRef.current || isInteractingRef.current) return; // 컴포넌트가 언마운트되었거나 상호작용 중이면 실행하지 않음

    if (ref.current?.scrollTop === 0) {
      setCanSwipe(true);
    } else {
      setCanSwipe(false);
    }
  }, 150); // 150ms 후에 스크롤이 멈춘 것으로 간주

  useEffect(() => {
    isMountedRef.current = true; // 마운트 상태로 설정

    const handleScroll = () => {
      if (!isInteractingRef.current) {
        setCanSwipe(false); // 스크롤 중에는 canSwipe을 false로 설정
      }
      handleScrollEnd(); // 디바운스된 함수 호출
    };

    const handleTouchStart = () => {
      isInteractingRef.current = true;
      if (ref.current?.scrollTop !== 0) {
        setCanSwipe(false);
      }
    };

    const handleTouchEnd = () => {
      isInteractingRef.current = false;
      handleScrollEnd();
    };

    const handleMouseDown = () => {
      isInteractingRef.current = true;
      if (ref.current?.scrollTop !== 0) {
        setCanSwipe(false);
      }
    };

    const handleMouseUp = () => {
      isInteractingRef.current = false;
      handleScrollEnd();
    };

    const element = ref.current;
    element?.addEventListener("scroll", handleScroll, LISTENER_OPTION);
    element?.addEventListener("touchstart", handleTouchStart, LISTENER_OPTION);
    element?.addEventListener("touchend", handleTouchEnd, LISTENER_OPTION);
    element?.addEventListener("mousedown", handleMouseDown, LISTENER_OPTION);
    element?.addEventListener("mouseup", handleMouseUp, LISTENER_OPTION);

    return () => {
      isMountedRef.current = false; // 컴포넌트가 언마운트될 때 상태 업데이트 중지

      element?.removeEventListener("scroll", handleScroll);
      element?.removeEventListener("touchstart", handleTouchStart);
      element?.removeEventListener("touchend", handleTouchEnd);
      element?.removeEventListener("mousedown", handleMouseDown);
      element?.removeEventListener("mouseup", handleMouseUp);
    };
  }, [handleScrollEnd]);

  return {
    canSwipe,
    ref,
  };
};
