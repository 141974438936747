import { BottomNavigation } from "@components/zLegacyLayouts/Navigations/BottomNavigation";
import type { ReactNode } from "react";
import styles from "./HomeLayout.module.scss";

type HomeLayoutProps = {
  children: ReactNode;
};

export const HomeLayout = ({ children }: HomeLayoutProps) => {
  return (
    <div className={styles.HomeLayout}>
      <div className={styles.contents}>{children}</div>
      <BottomNavigation noMargin />
    </div>
  );
};
