import { LegacyCartIcon } from "@components/zLegacyAtom/LegacyIcons/CartIcon";
import { LegacySellProductIcon } from "@components/zLegacyAtom/LegacyIcons/SellProductIcon";
import { PushLinkIcon } from "entities/push-notification";
import { Link } from "react-router-dom";
import Shared from "shared/ui";
import { HomeSearchLink } from "./HomeSearchLink";

export const HomeHeaderContent = () => {
  return (
    <Shared.HStack
      justify="start"
      align="center"
      fullWidth
      style={{ overflow: "hidden", padding: "0 var(--layout-header-padding)" }}
    >
      {/* 로고 + 검색 컨테이너 */}
      <Shared.HStack
        align="center"
        style={{
          overflow: "hidden",
          flex: 1,
          minWidth: 176,
          height: 40,
        }}
      >
        <Link to="/">
          <Shared.Icon name="logo" size={40} />
        </Link>
        <HomeSearchLink />
      </Shared.HStack>

      {/* 아이콘 링크 컨테이너 */}
      <Shared.HStack
        align="center"
        justify="start"
        gap={0}
        style={{ flexShrink: 0 }}
      >
        <LegacySellProductIcon />
        <LegacyCartIcon />
        <PushLinkIcon />
      </Shared.HStack>
    </Shared.HStack>
  );
};
