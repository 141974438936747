import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useAppSelector } from "app/appStore";
import type { SaleStatus } from "entities/product";
import { instance } from "shared/api";
import { api } from "shared/constants/api";
import type { APIResponse, ResponseData } from "shared/types/server";
import { sellerProductQueryKey } from "./queryKey";
import type { ProductRegisterData, ProductSalesData } from "./types";

// === 상품 등록을 위한 상세 조회(판매자) ===
export interface SellerProductInfoPayload {
  productId: string;
}
interface SellerProductInfoResponse {
  result: {
    productList: ProductRegisterData[];
  };
}

// === 판매자 상품 목록 조회(판매자) ===
export type SellerProductsPayload = {
  /** 대분류 카테고리 코드  */
  largeCategoryCode?: string;
  /** 중분류 카테고리 코드  */
  mediumCategoryCode?: string;
  /** 소분류 카테고리 코드 */
  smallCategoryCode?: string;
  /** 브랜드 */
  brandName?: string;
  /** 상품 판매 상태 */
  saleStatus?: SaleStatus;
  /** 프리미엄쿠폰 사용 상품 조회시(기본 false) */
  usePremiumCoupon?: boolean;
  /**
   * 검색어
   * 검색어가 있으면 기간 무시
   * 검색어는 띄어쓰기 기준으로 and 검색
   */
  searchKeyword?: string;
  /** 페이지 */
  page?: number;
};

type SellerProductsResponse = ResponseData & {
  resultList: ProductSalesData[];
  lastPage: number;
};

const sellerProductApi = {
  /**
   * 상품 등록을 위한 상세 조회(판매자)
   * @see https://sbridge.atlassian.net/wiki/x/AQAUDQ
   */
  getSellerProductInfo: async ({ productId }: SellerProductInfoPayload) => {
    const {
      data: { data: response },
    } = await instance.get<APIResponse<SellerProductInfoResponse>>(
      `${api.product.sellerProducts}/${productId}`
    );

    return response;
  },

  /**
   * 판매자 상품 목록 조회(판매자)
   * @see https://sbridge.atlassian.net/wiki/x/AQC-D
   */
  sellerProducts: async (payload: SellerProductsPayload) => {
    const {
      data: { data: response },
    } = await instance.get<APIResponse<SellerProductsResponse>>(
      api.product.sellerProducts,
      { params: payload }
    );

    return response;
  },
};

/**
 * 상품 등록을 위한 상세 조회(판매자)
 */
export function useSellerProductQuery({
  productId = "",
}: Partial<SellerProductInfoPayload>) {
  return useQuery({
    queryKey: sellerProductQueryKey.item({ productId }),
    queryFn: () => sellerProductApi.getSellerProductInfo({ productId }),
    select: data => data.result.productList?.[0],
    enabled: Boolean(productId),
  });
}

/**
 * 판매자 상품 목록 조회(판매자)
 */
export function useSellerProductListQuery(payload: SellerProductsPayload) {
  const { userId } = useAppSelector(state => state.user);

  return useInfiniteQuery({
    queryKey: sellerProductQueryKey.list(userId, payload),
    queryFn: ({ pageParam = 1 }) => {
      return sellerProductApi.sellerProducts({ ...payload, page: pageParam });
    },
    getNextPageParam: (lastPage, allPages) => {
      const currentPage = allPages.length;
      const nextPage = currentPage + 1;

      return lastPage.lastPage > currentPage ? nextPage : undefined;
    },
  });
}
