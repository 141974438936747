import type { ReactNode } from "react";
import { Link } from "react-router-dom";
import { useTitle } from "shared/hooks/useTitle";
import Shared from "..";
import { Header } from "./Header";
import styles from "./LocalHeader.module.scss";

type Props = {
  title: string;
  backRoute?: string | number;
  onClick?: VoidFunction;

  rightChildren?: ReactNode;
};

export const LocalHeader = ({
  title,
  rightChildren,
  onClick,
  backRoute = -1,
}: Props) => {
  useTitle(title);

  return (
    <Header className={styles.LocalHeader}>
      <Shared.HStack className={styles.left}>
        <Shared.BackIcon
          className={styles.back}
          route={backRoute}
          onClick={onClick}
        />

        <Link to="/">
          <Shared.Icon className={styles.logo} name="logo" size={40} />
        </Link>
      </Shared.HStack>

      <div className={styles.center}>
        <div className={styles.title}>{title}</div>
      </div>

      <div className={styles.right}>{rightChildren}</div>
    </Header>
  );
};
