import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const QnaBoard = lazy(() =>
  import("../../modules/boards/Qna/QnaBoard.view").then(module => ({
    default: module.QnaBoard,
  }))
);
const QnaRegiser = lazy(() =>
  import("../../modules/boards/Qna/QnaRegiser.view").then(module => ({
    default: module.QnaRegiser,
  }))
);
const QnaInquire = lazy(() =>
  import("../../modules/boards/Qna/QnaInquire.view").then(module => ({
    default: module.QnaInquire,
  }))
);

export const HelpQnaRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={null}>
            <QnaBoard />
          </Suspense>
        }
      />
      <Route
        path="register"
        element={
          <Suspense fallback={null}>
            <QnaRegiser />
          </Suspense>
        }
      />
      <Route
        path=":number"
        element={
          <Suspense fallback={null}>
            <QnaInquire />
          </Suspense>
        }
      />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
