import { useCallback, type Dispatch, type SetStateAction } from "react";
import type { PreviewImage } from "../types";

type Props = {
  setPreviewImages: Dispatch<SetStateAction<PreviewImage[]>>;
};

export const useImageUploaderHandlers = ({ setPreviewImages }: Props) => {
  // add
  const handleAddImage = (uploadedImages: PreviewImage[]) => {
    setPreviewImages(prevImages => [...prevImages, ...uploadedImages]);
  };

  //  delete
  const handleDeleteImage = useCallback(
    (uid: string) => {
      setPreviewImages(prevImages => {
        const nextImages = prevImages.filter(image => image.id !== uid);
        return nextImages;
      });
    },
    [setPreviewImages]
  );

  //  update
  const handleUpdateImage = useCallback(
    (uploadedImages: PreviewImage[]) => {
      setPreviewImages(prevImages => {
        const nextImages = prevImages.map(prevImage => {
          const nextImage = uploadedImages.find(img => img.id === prevImage.id);
          return nextImage
            ? {
                ...prevImage,
                ...nextImage,
              }
            : prevImage;
        });
        return nextImages;
      });
    },
    [setPreviewImages]
  );

  return { handleAddImage, handleDeleteImage, handleUpdateImage };
};
