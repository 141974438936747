import html2canvas from "html2canvas";
import { useEffect, useState } from "react";
import CallApi from "shared/api/CallApi";
import Shared from "shared/ui";
import "./IssueTaxInvoice.scss";

export const IssueTaxInvoice = ({ openModal, setOpenModal }: any) => {
  const [data, setData] = useState<any>();

  useEffect(() => {
    document.body.setAttribute("style", "overflow: hidden");
    (async () => {
      const res = await CallApi.request("/api/v1/seller/tax-bill", "GET", {
        searchYearMonth: openModal?.date,
      });
      res && setData(res.result);
    })();

    return () => document.body.setAttribute("style", "overflow: auto");
  }, []);

  return (
    <div id="VatContainer">
      <Shared.LocalHeader
        title="간이세금계산서"
        onClick={() => setOpenModal({ ...openModal, isOpen: false })}
        backRoute="#"
      />
      <div id="IssueTaxInvoice">
        <div className="table_wrapper">
          <div style={{ padding: "10px", borderRadius: 0 }}>
            <div style={{ marginBottom: "10px" }}>
              <b>간이세금계산서</b>
              <span style={{ color: "#178fff", paddingLeft: "5px" }}>
                공급받는자용
              </span>
            </div>

            <table>
              <tbody>
                <tr>
                  <td className="title w80">번호</td>
                  <td>
                    {data?.li_number0 +
                      data?.li_number1 +
                      data?.li_number2 +
                      data?.li_number3 +
                      data?.li_number4}
                  </td>
                </tr>

                {/* <tr>
                      <td className="title w80">구매자이름</td>
                      <td>{data?.name}</td>
                    </tr> */}
              </tbody>
            </table>

            <table>
              <tbody>
                <tr>
                  <td className="title w80">사업자등록번호</td>
                  <td>{data?.sellerBusinessNumber}</td>
                </tr>

                <tr>
                  <td className="title w80">상호</td>
                  <td>{data?.company}</td>
                </tr>

                <tr>
                  <td className="title w80">성명</td>
                  <td>{data?.name}</td>
                </tr>

                <tr>
                  <td className="title w80">사업장소재지</td>
                  <td>{data?.address}</td>
                </tr>

                <tr>
                  <td className="title w80">업태</td>
                  <td>{data?.uptae}</td>
                </tr>

                <tr>
                  <td className="title w80">종목</td>
                  <td>{data?.jongmok}</td>
                </tr>
              </tbody>
            </table>

            <table>
              <tbody>
                <tr>
                  <td className="title w80">작성일자</td>
                  <td className="title">공급가액</td>
                  <td className="title">세액</td>
                  <td className="title">비고</td>
                </tr>
                <tr>
                  <td>
                    {data?.day_info1}.{data?.day_info2}.{data?.day_info3}
                  </td>
                  <td align="right">{data?.total_susuryo_nine_comma} 원</td>
                  <td align="right">{data?.total_susuryo_one_comma} 원</td>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </table>

            <table>
              <tbody>
                <tr>
                  <td className="title w40">월/일</td>
                  <td className="title w40">품목</td>
                  <td className="title w25">수량</td>
                  <td className="title w40">단가</td>
                  <td className="title w40">공급가액</td>
                  <td className="title w40">세액</td>
                </tr>

                <tr>
                  <td className="date">
                    {data?.day_info2}/{data?.day_info3}
                  </td>
                  <td className="ellipsis">{data?.title}</td>
                  <td align="right">{data?.quantity}</td>
                  <td align="right">{data?.total_susuryo_danga_comma}</td>
                  <td align="right">{data?.total_susuryo_nine_comma}</td>
                  <td align="right">{data?.total_susuryo_one_comma}</td>
                </tr>

                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>

                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>

                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>

                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </table>
            <table>
              <tbody>
                <tr>
                  <td className="title w80">합계</td>
                  <td>{data?.total_susuryo_money_comma}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="btnWrap">
        <p>정식 세금계산서 폼은 PC 웹으로 확인바랍니다.</p>
        <GenericPdfDownloader
          downloadFileName={`세금계산서${openModal?.date}`}
        />
      </div>
      {/* </div> */}
    </div>
  );
};

const GenericPdfDownloader = ({ downloadFileName }: any) => {
  const downloadPdfDocument = () => {
    const input = document.getElementById("IssueTaxInvoice");
    input &&
      html2canvas(input).then(canvas => {
        const imgData = canvas.toDataURL("image/png");

        onSaveAs(imgData, `${downloadFileName}.png`);
      });
  };

  const onSaveAs = (uri: string, filename: string) => {
    const link = document.createElement("a");
    document.body.appendChild(link);
    link.href = uri;
    link.download = filename;
    link.click();
    document.body.removeChild(link);
  };
  return <button onClick={downloadPdfDocument}>다운로드</button>;
};

export default GenericPdfDownloader;
