// import { ChangeEvent } from "react";
import { Link } from "react-router-dom";
import "./style/OutlinedBtn.scss";

export const LegacyOutlinedBtn = (props: {
  text: any;
  type: string;
  link: any;
  onClick: any;
  submit: true;
  state?: any;
  ref?: any;
  disabled?: boolean;
  className?: string;
  button?: boolean;
  imgSrc?: string;
  isOpen?: boolean;
}) => {
  const {
    text,
    type,
    link,
    onClick,
    submit,
    state,
    ref,
    disabled,
    className,
    button,
    imgSrc,
    isOpen,
  } = props;
  const btnType = ["red", "hide", "small"].includes(type) ? type : "default";

  return (
    <>
      {submit ? (
        <button
          ref={ref}
          type="submit"
          className={["OutlinedBtn", `OutlinedBtn_${btnType}`, className].join(
            " "
          )}
          onClick={onClick || null}
        >
          {text}
        </button>
      ) : link ? (
        <Link
          style={{ width: btnType === "small" ? "" : "100%" }}
          to={link || "#"}
          state={state}
        >
          <button
            ref={ref}
            className={[
              "OutlinedBtn",
              `OutlinedBtn_${btnType}`,
              className,
            ].join(" ")}
            onClick={onClick || null}
            type="button"
          >
            {text}
          </button>
        </Link>
      ) : button ? (
        <button
          ref={ref}
          className={["OutlinedBtn", `OutlinedBtn_${btnType}`, className].join(
            " "
          )}
          onClick={onClick || null}
          disabled={disabled}
          type="button"
        >
          {text}
        </button>
      ) : imgSrc ? (
        <button
          ref={ref}
          className={[
            "OutlinedBtn",
            `OutlinedBtn_${btnType}`,
            `OutlinedBtn_img`,
          ].join(" ")}
          onClick={onClick || null}
          disabled={disabled}
        >
          {text}
          <img
            src={imgSrc}
            alt="화살표 아이콘"
            className={isOpen ? `active` : ``}
          />
        </button>
      ) : (
        <button
          ref={ref}
          className={["OutlinedBtn", `OutlinedBtn_${btnType}`, className].join(
            " "
          )}
          onClick={onClick || null}
          disabled={disabled}
          type="button"
        >
          {text}
        </button>
      )}
    </>
  );
};

LegacyOutlinedBtn.defaultProps = {
  type: "default",
  link: null,
  onClick: null,
  submit: false,
};
