import { appendMobileAppInfo } from "entities/user-info";
import { getKeepLoginCookie } from "features/auth/keep-login";
import { COOKIE_KEYS } from "shared/constants/cookieKeys";
import { getCookie } from "shared/lib/legacyUtils/cookie";
import type { SignOutPayload } from "../api/signOutApi";

export function getSignOutPayload(): SignOutPayload {
  const payload = {
    refreshToken: getCookie(COOKIE_KEYS.REFRESH_TOKEN),
    loginServiceId: getKeepLoginCookie().loginServiceId,
  };

  return appendMobileAppInfo(payload);
}
