/* eslint-disable jsx-a11y/label-has-associated-control */
import classNames from "classnames";
import type { InputHTMLAttributes, ReactNode } from "react";
import { forwardRef } from "react";
import Shared from "..";
import styles from "./Switch.module.scss";

export type SwitchProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "size"
> & {
  label?: ReactNode;
  size?: "m" | "l";
};

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(
  ({ className, disabled, checked, label, size = "m", ...rest }, ref) => {
    return (
      <label
        className={classNames(
          styles.Switch,
          styles[`size-${size}`],
          disabled && styles.disabled,
          checked && styles.checked,
          className
        )}
      >
        <Shared.HStack gap={6}>
          <input
            ref={ref}
            type="checkbox"
            disabled={disabled}
            checked={checked}
            {...rest}
          />
          {label}
        </Shared.HStack>
      </label>
    );
  }
);
