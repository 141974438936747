import classNames from "classnames";
import type { ButtonColorVariant } from "../Button/types";
import styles from "./Spinner.module.scss";

export type SpinnerSize = "xs" | "s" | "m";

type Props = {
  className?: string;
  size?: SpinnerSize;
  colorVariant?: ButtonColorVariant | "white";
};

export const Spinner = ({ className, colorVariant, size = "m" }: Props) => {
  return (
    <div
      className={classNames(
        styles.Spinner,
        styles[`color-${colorVariant}`],
        styles[`size-${size}`],
        className
      )}
    />
  );
};
