import { IssueTaxInvoice } from "modules/MyPage/Settlement/IssueTaxInvoice.module";
import { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const Vat = lazy(() =>
  import("../../modules/MyPage/Settlement/Vat").then(module => ({
    default: module.Vat,
  }))
);
const TaxInvoice = lazy(() =>
  import("../../modules/MyPage/Settlement/TaxInvoice.module").then(module => ({
    default: module.TaxInvoice,
  }))
);

const ExpandLimit = lazy(() =>
  import("../../legacyPages/user/ExpandLimit.view").then(module => ({
    default: module.ExpandLimit,
  }))
);
const SalesProductListPage = lazy(() =>
  import("pages/myshop/sales/products").then(module => ({
    default: module.SalesProductListPage,
  }))
);

const SaleOrderHistoryPage = lazy(() =>
  import("pages/myshop/sales/orders").then(module => ({
    default: module.SaleOrderHistoryPage,
  }))
);

const SaleQna = lazy(() =>
  import("../../legacyPages/user/SalesQna.view").then(module => ({
    default: module.SaleQna,
  }))
);
const BuyerInfo = lazy(() =>
  import("../../legacyPages/user/BuyerInfo.view").then(module => ({
    default: module.BuyerInfo,
  }))
);
const AdditionalService = lazy(() =>
  import("../../legacyPages/user/AdditionalService.view").then(module => ({
    default: module.AdditionalService,
  }))
);
const PurchasePowerSeller = lazy(() =>
  import("../../legacyPages/user/PurchasePowerSeller.view").then(module => ({
    default: module.PurchasePowerSeller,
  }))
);
const ResetRegisterDate = lazy(() =>
  import("../../legacyPages/user/ResetRegisterDate.view").then(module => ({
    default: module.ResetRegisterDate,
  }))
);
const PremiumProducts = lazy(() =>
  import("../../legacyPages/user/PremiumProducts.view").then(module => ({
    default: module.PremiumProducts,
  }))
);
const OpenMiniShop = lazy(() =>
  import("../../legacyPages/user/OpenMiniShop.view").then(module => ({
    default: module.OpenMiniShop,
  }))
);
const Settlement = lazy(() =>
  import("../../legacyPages/user/Settlement.view").then(module => ({
    default: module.Settlement,
  }))
);
const MonthlySettlementDetail = lazy(() =>
  import("../../legacyPages/user/MonthlySettlementDetail.view").then(
    module => ({
      default: module.MonthlySettlementDetail,
    })
  )
);
const OrderSettlementInfo = lazy(() =>
  import("../../legacyPages/user/OrderSettlementInfo.view").then(module => ({
    default: module.OrderSettlementInfo,
  }))
);
const SaleOrderDetailPage = lazy(() =>
  import("pages/myshop/sales/order-detail").then(module => ({
    default: module.SaleOrderDetailPage,
  }))
);
const Sending = lazy(() =>
  import("../../legacyPages/user/Sending.view").then(module => ({
    default: module.Sending,
  }))
);
const EditInvoice = lazy(() =>
  import("../../legacyPages/user/EditInvoice.view").then(module => ({
    default: module.EditInvoice,
  }))
);
const ReturnPickUpHold = lazy(() =>
  import("../../legacyPages/user/ReturnPickUpHold.view").then(module => ({
    default: module.ReturnPickUpHold,
  }))
);
const CancelSale = lazy(() =>
  import("../../legacyPages/user/CancelSale.view").then(module => ({
    default: module.CancelSale,
  }))
);
const CancelInfo = lazy(() =>
  import("../../legacyPages/user/CancelInfo.view").then(module => ({
    default: module.CancelInfo,
  }))
);
const ReturnInfo = lazy(() =>
  import("pages/myshop/sales/orders/return-info").then(module => ({
    default: module.ReturnInfoPage,
  }))
);

export const MyshopSaleTabRoutes = () => {
  return (
    <Routes>
      <Route
        path="qna"
        element={
          <Suspense fallback={null}>
            <SaleQna />
          </Suspense>
        }
      />
      <Route
        path="orders/sending"
        element={
          <Suspense fallback={null}>
            <Sending />
          </Suspense>
        }
      />
      <Route
        path="orders/cancel-sale"
        element={
          <Suspense fallback={null}>
            <CancelSale />
          </Suspense>
        }
      />
      <Route
        path="orders/edit-invoice"
        element={
          <Suspense fallback={null}>
            <EditInvoice />
          </Suspense>
        }
      />
      <Route
        path="orders/buyer-info"
        element={
          <Suspense fallback={null}>
            <BuyerInfo />
          </Suspense>
        }
      />
      <Route
        path="orders/cancel-info"
        element={
          <Suspense fallback={null}>
            <CancelInfo />
          </Suspense>
        }
      />
      <Route
        path="orders/:productOrderId/settlement-info"
        element={
          <Suspense fallback={null}>
            <OrderSettlementInfo />
          </Suspense>
        }
      />

      <Route
        path="settlement"
        element={
          <Suspense fallback={null}>
            <Settlement />
          </Suspense>
        }
      >
        <Route
          path="tax-invoice"
          element={
            <Suspense fallback={null}>
              <TaxInvoice />
            </Suspense>
          }
        />
        <Route
          path="tax-invoice/issue"
          element={
            <Suspense fallback={null}>
              <IssueTaxInvoice />
            </Suspense>
          }
        />
        <Route
          path="vat"
          element={
            <Suspense fallback={null}>
              <Vat />
            </Suspense>
          }
        />
      </Route>
      <Route
        path="settlement/tax-invoice/detail/:date"
        element={
          <Suspense fallback={null}>
            <MonthlySettlementDetail />
          </Suspense>
        }
      />

      <Route
        path="additional-services"
        element={
          <Suspense fallback={null}>
            <AdditionalService />
          </Suspense>
        }
      />
      <Route
        path="additional-services/power-seller"
        element={
          <Suspense fallback={null}>
            <PurchasePowerSeller />
          </Suspense>
        }
      />
      <Route
        path="additional-services/products-register-unlimit"
        element={
          <Suspense fallback={null}>
            <ExpandLimit />
          </Suspense>
        }
      />
      <Route
        path="additional-services/reset-regdate"
        element={
          <Suspense fallback={null}>
            <ResetRegisterDate />
          </Suspense>
        }
      />
      <Route
        path="additional-services/premium-products"
        element={
          <Suspense fallback={null}>
            <PremiumProducts />
          </Suspense>
        }
      />
      <Route
        path="additional-services/minishop"
        element={
          <Suspense fallback={null}>
            <OpenMiniShop />
          </Suspense>
        }
      />

      <Route
        path="orders"
        element={
          <Suspense fallback={null}>
            <SaleOrderHistoryPage />
          </Suspense>
        }
      />
      <Route
        path="orders/order-detail"
        element={
          <Suspense fallback={null}>
            <SaleOrderDetailPage />
          </Suspense>
        }
      />

      <Route
        path="products"
        element={
          <Suspense fallback={null}>
            <SalesProductListPage />
          </Suspense>
        }
      />
      <Route
        path="/orders/pickup-hold/:productOrderId"
        element={
          <Suspense fallback={null}>
            <ReturnPickUpHold />
          </Suspense>
        }
      />
      <Route
        path="/orders/return-info"
        element={
          <Suspense fallback={null}>
            <ReturnInfo />
          </Suspense>
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
