import type {
  DragEndEvent,
  DragStartEvent,
  UniqueIdentifier,
} from "@dnd-kit/core";
import { closestCenter, DndContext, DragOverlay } from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
} from "@dnd-kit/sortable";
import { useState } from "react";
import { createPortal } from "react-dom";
import { Grid } from "../Grid/Grid";
import {
  dropAnimation,
  useDraggableAreaSensors,
} from "./lib/useDraggableAreaSensors";
import type { DraggableAreaProps, DraggableItemData } from "./types";

export const DraggableArea = <T extends DraggableItemData>({
  items,
  setItems,
  children,
  overlay: Overlay,
  ...rest
}: DraggableAreaProps<T>) => {
  const sensors = useDraggableAreaSensors();
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);

  const handleDragStart = ({ active }: DragStartEvent) => {
    if (!active) return;
    setActiveId(active.id);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setItems(items => {
        const oldIndex = items.findIndex(item => item.id === active.id);
        const newIndex = items.findIndex(item => item.id === over?.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }

    setActiveId(null);
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      {...rest}
    >
      <SortableContext items={items} strategy={rectSortingStrategy}>
        <Grid columns={3} gap={10} autoRows>
          {children(activeId)}
        </Grid>
      </SortableContext>

      {createPortal(
        <DragOverlay adjustScale dropAnimation={dropAnimation}>
          {activeId && Overlay(activeId)}
        </DragOverlay>,
        document.body
      )}
    </DndContext>
  );
};
