import type { SweetAlertOptions } from "sweetalert2";
import Swal from "sweetalert2";

type ShowAlertProps = Parameters<typeof showAlert>;
export function showAlert(
  text: string,
  {
    allowOutsideClick = false,
    confirmButtonText = "확인",
    cancelButtonText = "취소",
    reverseButtons = true,
    ...otherOptions
  }: SweetAlertOptions = {}
) {
  return Swal.fire({
    confirmButtonText,
    allowOutsideClick,
    cancelButtonText,
    reverseButtons,
    ...otherOptions,
    text,
  });
}

type ShowAlertForErrorProps = {
  errorConditions: string[];
  errorCode: string | undefined;
  errorMessage: string | undefined;
};

// eslint-disable-next-line consistent-return
function showAlertForError({
  errorConditions,
  errorCode,
  errorMessage,
}: ShowAlertForErrorProps) {
  const shouldShowAlert = errorConditions.some((code) => code === errorCode);

  if (shouldShowAlert && errorMessage) {
    return showAlert(errorMessage);
  }
}

function showAlertAndRedirect(
  redirectUrl: string | -1,
  ...showAlertProps: ShowAlertProps
) {
  showAlert(...showAlertProps).then(({ isConfirmed }) => {
    if (isConfirmed) {
      if (redirectUrl === -1) {
        window.history.back();
      } else {
        window.location.href = redirectUrl;
      }
    }
  });
}

function showAlert고객센터(...showAlertProps: ShowAlertProps) {
  const [text, options]: ShowAlertProps = showAlertProps;

  showAlertAndRedirect("/help", text, {
    icon: "error",
    showCancelButton: true,
    confirmButtonText: "고객센터로 가기",
    ...options,
  });
}

showAlert.error = showAlertForError;
showAlert.redirect = showAlertAndRedirect;
showAlert.redirectToHelp = showAlert고객센터;
