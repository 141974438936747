import type { CheckboxProps } from "shared/ui";
import Shared from "shared/ui";

type CartProductSellerLabelProps = {
  label: string;
  disabled: boolean | undefined;
};

const CartProductSellerLabel = ({
  label,
  disabled,
}: CartProductSellerLabelProps) => {
  return (
    <span className="font_600 text14" style={{ opacity: disabled ? 0.6 : 1 }}>
      {label}
    </span>
  );
};

export const CartSellerCheckbox = ({
  label,
  disabled,
  ...props
}: Omit<CheckboxProps, "label"> &
  Pick<CartProductSellerLabelProps, "label">) => {
  if (disabled) {
    return <CartProductSellerLabel label={label} disabled={disabled} />;
  }

  return (
    <Shared.Checkbox
      label={<CartProductSellerLabel label={label} disabled={disabled} />}
      disabled={disabled}
      {...props}
    />
  );
};
