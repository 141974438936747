import classNames from "classnames";
import styles from "./ProductModules.module.scss";

type Props = {
  imageUrl: string | undefined;
  size?: "s" | "m" | "l";
  className?: string;
};

export const ProductImage = ({
  imageUrl = "/assets/images/default.png",
  size = "m",
  className,
}: Props) => {
  return (
    <div
      className={classNames(
        styles.ProductImage,
        styles[`size-${size}`],
        className
      )}
    >
      <img src={imageUrl} alt="" />
    </div>
  );
};
