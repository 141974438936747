import classNames from "classnames";
import type { ImgHTMLAttributes } from "react";
import styles from "./Image.module.scss";

type ImageProps = ImgHTMLAttributes<HTMLImageElement> & {
  src: string;
  aspectRatio?: 1;
};

// TODO: Next.js 이미지 컴포넌트 확인
// 로딩일때도 적용
export const Image = ({
  alt = "",
  className,
  width,
  height,
  aspectRatio,
  ...rest
}: ImageProps) => {
  return (
    <div
      className={classNames(
        styles.Image,
        styles[`ratio-${aspectRatio}`],
        className
      )}
      style={{ width, height }}
    >
      <img alt={alt} width={width} height={height} {...rest} />
    </div>
  );
};
