import loadImage from "blueimp-load-image";

/**
 * HTMLCanvasElement를 Blob으로 변환합니다.
 */
const convertCanvasToBlob = (
  canvas: HTMLCanvasElement,
  type?: string
): Promise<Blob> =>
  new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      if (blob) {
        resolve(blob);
      } else {
        reject(new Error("Blob conversion failed"));
      }
    }, type);
  });

/**
 * HTMLCanvasElement를 File으로 변환합니다.
 */
const convertCanvasImageToFile = async (
  canvas: HTMLCanvasElement,
  file: File
): Promise<File | null> => {
  const blob = await convertCanvasToBlob(canvas, file.type);
  if (blob) {
    return new File([blob], file.name, { type: file.type });
  }
  return null;
};

/**
 * 이미지의 orientation의 값으로 정방향으로 회전시킵니다.
 * 모바일 기기에서 사진을 업로드할 시 90도 회전하는 문제를 해결하기 위한 함수입니다.
 */
export const orientImageFile = async (file: File): Promise<File | null> => {
  try {
    const data = await loadImage(file, {
      orientation: true,
      canvas: true,
      meta: true,
    });

    if (!data.exif) {
      return file;
    }

    if (data.image instanceof HTMLCanvasElement) {
      const convertedFile = await convertCanvasImageToFile(data.image, file);
      return convertedFile;
    }

    return null;
  } catch (error) {
    console.error("Image processing failed:", error);
    return null;
  }
};
