import { useEffect, useState } from "react";

type Props = {
  isLoading: boolean;
  delay?: number;
};

export const useDelayState = ({ isLoading, delay = 500 }: Props) => {
  const [state, setState] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isLoading) {
        setState(true);
      } else {
        setState(false);
      }
    }, delay);

    return () => clearTimeout(timer);
  }, [delay, isLoading]);

  return [state, setState] as const;
};
