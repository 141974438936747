/* eslint-disable max-classes-per-file */
export interface Storage {
  get(key: string): string | null;

  set(key: string, value: string): void;

  remove(key: string): void;

  clear(): void;
}

class MemoStorage implements Storage {
  private storage = new Map<string, string>();

  public get(key: string) {
    return this.storage.get(key) || null;
  }

  public set(key: string, value: string) {
    this.storage.set(key, value);
  }

  public remove(key: string) {
    this.storage.delete(key);
  }

  public clear() {
    this.storage.clear();
  }
}

abstract class BrowserStorage implements Storage {
  protected abstract storage: globalThis.Storage;

  public static canUse(storage: globalThis.Storage): boolean {
    const TEST_KEY = generateTestKey();
    try {
      storage.setItem(TEST_KEY, "test");
      storage.removeItem(TEST_KEY);
      return true;
    } catch {
      return false;
    }
  }

  public get(key: string): string | null {
    return this.storage.getItem(key);
  }

  public set(key: string, value: string): void {
    this.storage.setItem(key, value);
  }

  public remove(key: string): void {
    this.storage.removeItem(key);
  }

  public clear(): void {
    this.storage.clear();
  }
}

class LocalStorage extends BrowserStorage {
  protected storage = window.localStorage;

  public static canUse(): boolean {
    return super.canUse(window.localStorage);
  }
}

class SessionStorage extends BrowserStorage {
  protected storage = window.sessionStorage;

  public static canUse(): boolean {
    return super.canUse(window.sessionStorage);
  }
}

function generateTestKey() {
  return new Array(4)
    .fill(null)
    .map(() => Math.random().toString(36).slice(2))
    .join("");
}

export function generateStorage(storageType: "local" | "session"): Storage {
  if (storageType === "local" && LocalStorage.canUse()) {
    return new LocalStorage();
  }
  if (storageType === "session" && SessionStorage.canUse()) {
    return new SessionStorage();
  }
  return new MemoStorage();
}

export const safeLocalStorage = generateStorage("local");
export const safeSessionStorage = generateStorage("session");
