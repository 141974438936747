import classNames from "classnames";
import hasProductOption from "entities/product/lib/hasProductOption";
import styles from "./ProductModules.module.scss";

type Props = {
  orderOptionName: string | undefined;
  className?: string;

  hasPrefix?: boolean;
  disabled?: boolean;
};

export const ProductOption = ({
  orderOptionName,
  hasPrefix,
  disabled,
  className,
}: Props) => {
  return (
    <div className={classNames(styles.OptionName, className)}>
      <p
        className="graytext"
        style={{
          color: disabled ? "var(--color-gray)" : "var(--color-gray-darker)",
        }}
      >
        {hasPrefix && "옵션 | "}
        {hasProductOption(orderOptionName) && orderOptionName}
      </p>
    </div>
  );
};
