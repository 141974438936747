import { useQuery } from "@tanstack/react-query";
import { instance } from "shared/api";
import { api } from "shared/constants/api";
import type { productListInfo } from "shared/types/Products.types";
import type { APIResponse, ResponseData } from "shared/types/server";
import { productQueryKey } from "./queryKey";
import type { ProductSection } from "./types";

export type ProductListPayload = {
  section: ProductSection;
  isPremium: boolean;
  size: number;
  largeCategoryCode: string;
};

type ProductResponse = ResponseData & {
  productList: productListInfo[];
  reviewCount: number;
  /** 정렬을 위한 현재 커서 */
  currentCursor?: string;
  /** 다음 페이지 조회를 위한 마지막 커서 */
  lastCursor: string;
};

const productApi = {
  /**
   * 상품 목록 조회
   * @see https://sbridge.atlassian.net/wiki/spaces/PGMRENEW/pages/87293995 */
  productList: async (payload: Partial<ProductListPayload>) => {
    const {
      data: { data: response },
    } = await instance.get<APIResponse<ProductResponse>>(api.product.list, {
      params: payload,
    });

    return response;
  },

  /**
   * 상품 수 정보 가져오기
   * @see https://sbridge.atlassian.net/wiki/spaces/PGMRENEW/pages/212664637/mobile-
   */
  count: async () => {
    const {
      data: { data: response },
    } = await instance.get<
      APIResponse<
        ResponseData & {
          result: { allProductCount: number; saleProductCount: number };
        }
      >
    >(api.product.count);

    return response;
  },
};

export function useProductListQuery(payload: Partial<ProductListPayload>) {
  return useQuery({
    queryKey: productQueryKey.list(payload),
    queryFn: () => productApi.productList(payload),
    select: data => data.productList,
  });
}

export function useProductCountQuery() {
  return useQuery({
    queryKey: productQueryKey.count(),
    queryFn: productApi.count,
    select: data => data.result,
  });
}
