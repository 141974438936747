import { LegacyContainedBtn } from "@components/zLegacyAtom/Buttons/ContainedBtn";
import { Select } from "@components/zLegacyLayouts/Select/Select";
import { useAppSelector } from "app/appStore";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import CallApi from "shared/api/CallApi";
import { BANK } from "shared/constants/Strings";
import Shared from "shared/ui";
import Swal from "sweetalert2";
import "./style/RefundAccountChange.scss";

export const RefundAccountChange = () => {
  interface RefundAccountProps {
    Vbank: string;
    accountNumber: string;
    accountHolder?: string;
  }
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
    setFocus,
    setValue,
    getValues,
    watch,
  } = useForm<RefundAccountProps>({
    mode: "onChange",
  });
  const { search } = useLocation();
  const qString = qs.parse(search.slice(1));
  const navigate = useNavigate();
  const { isLoggedIn } = useAppSelector(state => state.user);
  const [isDisabled, setIsDisabled] = useState(false); // 버튼 비활성화

  const onSubmit = async (data: RefundAccountProps) => {
    setIsDisabled(true);
    const res = await CallApi.request(
      `/api/v1/orders/purchases/change-return-account`,
      "PUT",
      {
        orderId: qString?.orderId,
        number: qString?.number,
      },
      {
        bank: data.Vbank,
        accountHolder: data.accountHolder,
        accountNumber: data.accountNumber,
      }
    );

    if (res?.responseType === "SUCCESS") {
      setIsDisabled(false);
      if (isLoggedIn) {
        navigate("/myshop/orders");
      } else {
        navigate(`/non-member-orders?orderId=${qString?.orderId}`);
      }
    } else {
      setIsDisabled(false);
    }
  };

  useEffect(() => {
    setValue("Vbank", "은행 선택");
    // 다이렉트로 접근하는 경우
    if (!qString.result && !qString.orderId) {
      Swal.fire({
        text: "잘못된 접근입니다.",
        confirmButtonText: "확인",
        allowOutsideClick: false,
      }).then(function (result) {
        if (result.isConfirmed) {
          navigate("/");
        }
      });
    }
    // 휴대폰 인증 실패
    else if (qString.result === "false") {
      Swal.fire({
        text: "휴대폰 인증에 실패하였습니다. 다시 시도해주세요.",
        confirmButtonText: "확인",
        allowOutsideClick: false,
      }).then(function (result) {
        if (result.isConfirmed) {
          if (isLoggedIn) {
            navigate("/myshop/orders");
          } else {
            navigate(`/non-member-orders?orderId=${qString?.orderId}`);
          }
        }
      });
    }
    // 휴대폰 인증 성공
    else {
      (async () => {
        const res = await CallApi.request(
          `/api/v1/orders/purchases/return-account?orderId=${qString?.orderId}`,
          "GET"
        );
        if (res) {
          setValue("accountHolder", res.result?.accountHolder);
          setValue("accountNumber", res.result?.accountNumber);
          setValue("Vbank", res.result?.bank);
        }
      })();
    }
  }, []);
  return (
    <>
      <Shared.LocalHeader
        title="환불계좌 변경"
        backRoute={
          isLoggedIn
            ? "/myshop/orders"
            : `/non-member-orders?orderId=${qString?.orderId}`
        }
      />
      <div id="RefundAccountChange">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h4>환불계좌 변경</h4>
          <Select
            value={watch("Vbank")}
            register={register}
            inputName="Vbank"
            required
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setValue("Vbank", e.target.value);
            }}
          >
            <option value="은행 선택" disabled>
              은행 선택
            </option>
            {BANK.map((text: string) => (
              <option value={text} key={text}>
                {text}
              </option>
            ))}
          </Select>
          {isSubmitted && watch("Vbank") === "은행 선택" && (
            <p className="validationErrorMessage">은행을 선택해주세요.</p>
          )}
          <input
            type="text"
            placeholder="계좌번호"
            {...register("accountNumber", {
              required: true,
            })}
            autoFocus={false}
            value={watch("accountNumber") ? watch("accountNumber") : ""}
          />
          {errors.accountNumber?.type === "required" && (
            <p className="validationErrorMessage">계좌번호를 입력해주세요.</p>
          )}
          <input
            type="text"
            {...register("accountHolder", {
              required: false,
            })}
            value={watch("accountHolder") ? watch("accountHolder") : ""}
            readOnly
          />
          <LegacyContainedBtn text="환불계좌 변경하기" disabled={isDisabled} />
        </form>
      </div>
    </>
  );
};
