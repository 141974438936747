import { formatPrice } from "shared/lib";
import styles from "./ProductModules.module.scss";

type Props = {
  totalPrice: number;
};

export const ProductTotalPrice = ({ totalPrice }: Props) => {
  return (
    <div className={styles.TotalPrice}>
      {formatPrice(totalPrice, {
        suffix: "원",
      })}
    </div>
  );
};
