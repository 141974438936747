import classNames from "classnames";
import styles from "./Card.module.scss";
import type { CardChildrenProps } from "./types";

export const CardSection = ({
  className,
  children,
  ...rest
}: CardChildrenProps) => {
  return (
    <section className={classNames(styles.CardSection, className)} {...rest}>
      {children}
    </section>
  );
};
