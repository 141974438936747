import { Cookies } from "react-cookie";

const cookies = new Cookies();
type CookeySetOptions = Parameters<Cookies["set"]>[2];

export const setCookie = (
  name: string,
  value: any,
  options?: CookeySetOptions
) => {
  return cookies.set(name, value, options);
};

export const getCookie = (name: string) => {
  return cookies.get(name);
};

export const removeCookie = (name: string, options?: CookeySetOptions) => {
  return cookies.remove(name, options);
};
