import type { UniqueIdentifier } from "@dnd-kit/core";
import type { ReactNode } from "react";
import { useCallback } from "react";
import { DraggableItem } from "shared/ui/Draggable";
import { DraggableArea } from "shared/ui/Draggable/DraggableArea";
import { ImageUploaderInput } from "./ImageUploaderInput/ImageUploderInput";
import { ImageUploaderPreview } from "./ImageUploaderPreview/ImageUploaderPreview";
import { useImageUploaderHandlers } from "./lib/useImageUploaderHandlers";
import { useInitializePreviewImages } from "./lib/useInitializePreviewImages";
import { ThumbnailBadge } from "./ThumbnailBadge/ThumbnailBadge";
import type { ImageUploaderProps } from "./types";

export const DraggableImageUploader = ({
  uploadType,
  multiple,
  maxCount,

  defaultImages,
  onChange,
}: ImageUploaderProps) => {
  const { previewImages, setPreviewImages } = useInitializePreviewImages({
    defaultImages,
    onChange,
  });

  const { handleAddImage, handleDeleteImage, handleUpdateImage } =
    useImageUploaderHandlers({ setPreviewImages });

  const overlayRenderer = useCallback<
    (activeId: UniqueIdentifier) => ReactNode
  >(
    activeId => {
      const res = previewImages.find(item => item.id === activeId);
      if (!res) return null;

      return (
        <DraggableItem id={String(activeId)} isDragOverlay>
          <ImageUploaderPreview
            image={res}
            handleDeleteImage={handleDeleteImage}
          />
        </DraggableItem>
      );
    },
    [handleDeleteImage, previewImages]
  );

  return (
    <div className="none-select">
      <DraggableArea
        items={previewImages}
        setItems={setPreviewImages}
        overlay={overlayRenderer}
      >
        {activeId => (
          <>
            <div>
              <ImageUploaderInput
                aspectRatio={1}
                accept="image/jpg,image/png,image/jpeg"
                uploadType={uploadType}
                handleAddImage={handleAddImage}
                handleUpdateImage={handleUpdateImage}
                multiple={multiple}
                currentCount={previewImages.length}
                maxCount={maxCount}
                limit="10MB"
              />
              <div style={{ height: 5, width: "5px" }} />
            </div>

            {previewImages.map((image, index) => {
              const { id } = image;
              return (
                <DraggableItem
                  isDragged={id === activeId}
                  key={id}
                  id={String(id)}
                >
                  <div style={{ position: "relative" }}>
                    {index === 0 && <ThumbnailBadge />}

                    <ImageUploaderPreview
                      image={image}
                      handleDeleteImage={handleDeleteImage}
                    />
                  </div>
                </DraggableItem>
              );
            })}
          </>
        )}
      </DraggableArea>
    </div>
  );
};
