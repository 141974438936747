import jwtDecode from "jwt-decode";
import { COOKIE_KEYS } from "shared/constants/cookieKeys";
import { getCookie } from "shared/lib/legacyUtils/cookie";
import type { Token } from "../model/token";
import { EMPTY_USER_INFO, type UserInfo } from "../model/userInfo";

export function getUserInfoFromToken(token: any): UserInfo {
  try {
    const _token: Token = jwtDecode(token);
    return {
      id: _token?.sub,
      role: _token?.role,
    };
  } catch (e) {
    console.error(e);
    return EMPTY_USER_INFO;
  }
}

export function getUserInfoFromAccessToken() {
  const accessToken = getCookie(COOKIE_KEYS.ACCESS_TOKEN);
  if (!accessToken) return null;
  return getUserInfoFromToken(accessToken);
}
