import type { 상품약관 } from "features/product/save-product";
import type { RegisterMode } from "legacyPages/SellProduct.view";
import { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import type { UseFormReturn } from "react-hook-form";
import Shared from "shared/ui";
import { LegacyContainedBtn } from "../../zLegacyAtom/Buttons/ContainedBtn";
import { LegacyCheckBox } from "../../zLegacyAtom/Inputs/CheckBox/CheckBox";
import "./style/NoSellFakeProductModal.scss";

const MODAL_KEY = "doNotshowFakeSalesNotice";

export const useNoSellFakeProductModal = (registerMode: RegisterMode) => {
  const cookies = new Cookies();
  const NOT_SHOW_FAKE_SALES_NOTICE = cookies.get(MODAL_KEY);

  const [modalPopUp, setModalPopUp] = useState(false);

  useEffect(() => {
    if (registerMode === "normal" && !NOT_SHOW_FAKE_SALES_NOTICE) {
      setModalPopUp(true);
    }
  }, [NOT_SHOW_FAKE_SALES_NOTICE, registerMode]);

  return {
    NOT_SHOW_FAKE_SALES_NOTICE,
    modalPopUp,
    setModalPopUp,
  };
};

export const NoSellFakeProductModal = ({
  setOpen,
  termsAgreement,
  setTermsAgreement,
  termForm,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  termsAgreement?: any;
  setTermsAgreement?: any;
  termForm?: UseFormReturn<상품약관>;
}) => {
  const [todayNotOpen, setTodayNotOpen] = useState(false);
  const cookies = new Cookies();

  const clickClose = () => {
    setOpen(false);
  };

  const doNotShowAgainToday = () => {
    const now = new Date();
    const tomorrow = new Date(now.setDate(now.getDate() + 1));
    cookies.set(MODAL_KEY, true, {
      expires: tomorrow,
      path: "/",
    });
    if (termsAgreement)
      setTermsAgreement?.({ ...termsAgreement, fakeSales: true });
    termForm?.setValue("fakeSales", true);
    setOpen(false);
  };

  const onClickAgreeTerms = () => {
    // 오늘 하루 열지 않기 체크
    if (todayNotOpen) {
      doNotShowAgainToday();
    } else {
      if (termsAgreement)
        setTermsAgreement?.({ ...termsAgreement, fakeSales: true });
      termForm?.setValue("fakeSales", true);
      setOpen(false);
    }
  };

  useEffect(() => {
    document.body.setAttribute("style", "overflow: hidden");

    return () => document.body.setAttribute("style", "overflow: auto");
  }, []);

  return (
    <>
      <Shared.Overlay colorVariants="black-opacity" onClick={clickClose} />
      <div className="NoSellFakeProductModal active">
        <div className="title">
          <h3>가품판매 금지 약관</h3>
          <img
            src="/assets/icons/icon_close_2.svg"
            alt="닫기 아이콘"
            className="iconClose"
            onClick={clickClose}
          />
        </div>

        <h4 className="font_600">가품 거래의 책임</h4>
        <ul>
          <li>
            -가품 등록이나 거래로 발생되는 법적 책임 및 손해 배상은 판매자
            책임을 명시한다.
          </li>
        </ul>
        <h4 className="font_600">가품 판매로 인한 손해배상</h4>
        <ul>
          <li>
            -가입 판매자의 가품 또는 정품미확인 상품의 판매가
            제휴연동채널(지마켓,옥션,11st,스마트스토어,쿠팡 등)의 '위조상품
            판매자 정책' 위반을 초래하여 프라이스골프에 피해를 입혔을 경우,
            <span className="font_600">
              해당 가품판매자는 프라이스골프가 입은 손해액의 200%를 배상한다.
            </span>
          </li>
        </ul>
        <h4 className="font_600">가품 판매시 처벌조항</h4>
        <ul>
          <li>
            -상표법위반의 범죄행위로서
            <span className="font_600">7년이하의 징역 1억원 이하의 벌금형</span>
            에 처해집니다.(상표법 93조)
          </li>
          <li>
            -모조품임을 모르고 판매한 경우도 처벌을 받으며, 알고 판매한 경우는
            <span className="font_600">사기죄의 적용</span>도 받습니다.
          </li>
          <li>
            -신상이 공개된 시장에서 모조품을 판매하는 것은 경찰수사대상 일순위를
            자처하는 행위입니다.
          </li>
          <li>
            -모조품 판매자는 프라이스골프의 영업을 방해하는 자로 간주하여
            <span className="font_600">회원삭제, 경찰에 고발</span>합니다.
          </li>
          <li>
            -판매자의 모든 로그자료는 회원탈퇴 후 6개월까지 프라이스골프에 남아
            고발 자료로 사용됩니다.
          </li>
        </ul>
        <h2 className="font_600 warning">
          가품이 확인되었을 시<br /> 이유 불문 발생하는 모든 책임은
          <br /> 판매자에게 있습니다.
        </h2>
        <LegacyCheckBox
          label="오늘 하루 열지 않기"
          className="checkbox"
          checked={todayNotOpen}
          onChange={() => setTodayNotOpen(!todayNotOpen)}
        />
        <LegacyContainedBtn text="약관동의" onClick={onClickAgreeTerms} />
      </div>
    </>
  );
};
