import { useQuery } from "@tanstack/react-query";
import { useAppSelector } from "app/appStore";
import { instance } from "shared/api";
import { api } from "shared/constants/api";
import { baseQueryKeys } from "shared/constants/baseQueryKeys";
import type { APIResponse, ResponseData } from "shared/types/server";
import type { UserId } from "shared/types/User.types";
import type { SellerInfo } from "./types";

type SellerInfoResponse = ResponseData & {
  result: SellerInfo;
};

const queryKey = {
  sellerInfo: (userId: UserId) =>
    [...baseQueryKeys.myShop, "seller-info", userId] as const,
} as const;

const sellerInfoApi = {
  /**
   * 판매자 관련 정보 조회(판매자)
   * @see https://sbridge.atlassian.net/wiki/x/AQDYD
   */
  sellerInfo: async () => {
    const {
      data: { data: response },
    } = await instance.get<APIResponse<SellerInfoResponse>>(
      api.myshop.seller.addInfo
    );

    return response;
  },
};

/**
 * 판매자 관련 정보 조회(판매자)
 */
export function useSellerInfo() {
  const { userId, role } = useAppSelector(state => state.user);

  return useQuery({
    queryKey: queryKey.sellerInfo(userId),
    queryFn: () => sellerInfoApi.sellerInfo(),
    select: data => data?.result,
    enabled: role?.includes("auth"),
    retry: false,
  });
}
