import type { Address } from "react-daum-postcode";
import DaumPostcode from "react-daum-postcode";
import { useBoolean } from "shared/hooks";
import type { InputProps } from "../..";
import Shared from "../..";
import type { InputLabelProps } from "../InputLabel";

type Props = {
  labelProps: InputLabelProps;
  onComplete: (address: Address) => void;

  postCode: InputProps;
  roadAddress: InputProps;
  jibunAddress: InputProps;
  details: InputProps;
};

export const AddressInput = ({
  labelProps,
  onComplete,

  roadAddress,
  jibunAddress,
  postCode,
  details,
}: Props) => {
  const [isModalOpen, modalController] = useBoolean();

  const handleComplete = (address: Address) => {
    onComplete(address);
    modalController.off();
  };

  return (
    <>
      <Shared.InputLabel {...labelProps}>
        <Shared.VStack>
          <Shared.SearchInput
            type="number"
            readOnly
            placeholder="우편번호 찾기"
            onClick={() => {
              modalController.on();
            }}
            {...postCode}
          />
          <Shared.Input
            readOnly
            placeholder="도로명주소"
            onClick={() => {
              modalController.on();
            }}
            {...roadAddress}
          />
          <Shared.Input placeholder="상세주소" {...details} />
          <input
            type="hidden"
            readOnly
            placeholder="지번주소"
            {...jibunAddress}
          />
        </Shared.VStack>
      </Shared.InputLabel>

      <Shared.Modal
        isOpen={isModalOpen}
        onClose={modalController.off}
        overlayProps={{ colorVariants: "black" }}
      >
        <Shared.Modal.Body>
          <DaumPostcode
            onComplete={handleComplete}
            style={{
              width: "100%",
              height: "75%",
              border: "1px solid rgba(0, 0, 0, 0.5)",
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: 11,
            }}
          />
        </Shared.Modal.Body>

        <Shared.Button
          text="닫기"
          onClick={() => modalController.off()}
          style={{
            position: "fixed",
            bottom: "10%",
            left: "50%",
            transform: "translateX(-50%)",

            paddingRight: 24,
            paddingLeft: 24,
          }}
        />
      </Shared.Modal>
    </>
  );
};
