import { useQuery } from "@tanstack/react-query";
import { instance } from "shared/api";
import { api } from "shared/constants/api";
import { baseQueryKeys } from "shared/constants/baseQueryKeys";
import type { APIResponse } from "shared/types/server";
import type { ProductBrandResponse } from "./types";

const queryKey = [baseQueryKeys.product, "brand"] as const;

const productBrandApi = {
  getBrands: async () => {
    const {
      data: { data: response },
    } = await instance.get<APIResponse<ProductBrandResponse>>(
      api.product.brand
    );

    const { brandList } = response;
    const mainBrandList = brandList?.filter(
      ({ brandMainDisplay }) => brandMainDisplay
    );
    const subBrandList = brandList?.filter(
      ({ brandMainDisplay }) => !brandMainDisplay
    );

    return [...mainBrandList, ...subBrandList];
  },
};

export function useProductBrandsQuery() {
  return useQuery({
    queryKey,
    queryFn: productBrandApi.getBrands,
  });
}
