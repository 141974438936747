import type { ReactNode } from "react";
import { Fragment } from "react";
import Shared from "..";

type Props = {
  children: ReactNode;
  gap?: number;
  count?: number;
};

export const SkeletonGroup = ({ children, count = 3, gap }: Props) => {
  return (
    <Shared.VStack gap={gap}>
      {Array.from({ length: count }).map((_, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={i}>{children}</Fragment>
      ))}
    </Shared.VStack>
  );
};
