import { useMutation } from "@tanstack/react-query";
import { queryClient } from "app/appQueryClient";
import type { ProductRegisterData } from "entities/product/seller-product";
import { useNavigate } from "react-router-dom";
import { instance } from "shared/api";
import { api } from "shared/constants/api";
import { baseQueryKeys } from "shared/constants/baseQueryKeys";
import { showAlert } from "shared/lib";
import type {
  APIAxiosError,
  APIResponse,
  ResponseData,
} from "shared/types/server";
import { transformProductPayload } from "../model/transformProductPayload";

type EditProductPayload = {
  productId: string;
  data: ProductRegisterData;
};

const editProductApi = {
  /**
   * 상품 등록/수정
   * @see https://sbridge.atlassian.net/wiki/x/DoB5B
   */
  editProduct: async ({ data, productId }: EditProductPayload) => {
    const {
      data: { data: response },
    } = await instance.put<APIResponse<ResponseData>>(
      `${api.product.sellerProducts}/${productId}`,
      data
    );

    return response;
  },
};

export function useEditProductMutation() {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: ({ data, productId }: EditProductPayload) =>
      editProductApi.editProduct({
        productId,
        data: transformProductPayload(data),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([baseQueryKeys.product]);
      showAlert("상품이 수정되었습니다.").then(({ isConfirmed }) => {
        if (isConfirmed) {
          navigate("/myshop/sales/products");
        }
      });
    },
    onError: (error: APIAxiosError) => {
      const errorMessage = error.response?.data?.message;
      errorMessage && showAlert(errorMessage);
    },
  });
}
