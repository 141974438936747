import { Badge } from "shared/ui/Badge/Badge";

const PADDING = 4;

export const ThumbnailBadge = () => {
  return (
    <div
      style={{
        position: "absolute",
        top: PADDING,
        left: PADDING,
        zIndex: 1,
      }}
    >
      <Badge text="대표" size="s" colorVariant="red" />
    </div>
  );
};
