import Shared from "shared/ui";

export const ProductCardSkeleton = () => {
  return (
    <Shared.Card>
      <Shared.Card.Header>
        <Shared.Skeleton width="40%" />
      </Shared.Card.Header>
      <Shared.Card.Body>
        <Shared.VStack fullWidth>
          <Shared.Skeleton width="30%" />

          <Shared.HStack fullWidth>
            <Shared.VStack>
              <Shared.Skeleton width="80px" height="80px" />
            </Shared.VStack>

            <Shared.VStack fullWidth gap={2}>
              <Shared.Skeleton width="80%" />
              <Shared.Skeleton width="50%" />
              <Shared.Skeleton width="40%" />
              <Shared.VStack fullWidth align="end">
                <Shared.Skeleton width="30%" />
              </Shared.VStack>
            </Shared.VStack>
          </Shared.HStack>
        </Shared.VStack>

        <Shared.HStack fullWidth style={{ marginTop: 12 }}>
          <Shared.Skeleton height="28px" />
          <Shared.Skeleton height="28px" />
        </Shared.HStack>
      </Shared.Card.Body>
    </Shared.Card>
  );
};

type Props = {
  count?: number;
};

export const ProductCardSkeletons = ({ count = 3 }: Props) => {
  return (
    <Shared.VStack gap={12}>
      {Array.from({ length: count }).map((_, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <ProductCardSkeleton key={i} />
      ))}
    </Shared.VStack>
  );
};
