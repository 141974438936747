import classNames from "classnames";
import { forwardRef } from "react";
import styles from "./Input.module.scss";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  fullWidth?: boolean;
  error?: boolean;

  inputSize?: "s" | "m";
};

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      fullWidth,
      disabled,
      error,
      inputSize = "m",
      type,
      pattern,
      inputMode,
      ...rest
    },
    ref
  ) => {
    const attribute =
      type === "tel"
        ? ({
            pattern: "[0-9]*",
            inputMode: "numeric",
          } as const)
        : {
            pattern,
            inputMode,
          };

    return (
      <input
        className={classNames(
          styles.Input,
          fullWidth && styles.fullWidth,
          disabled && styles.disabled,
          error && styles.error,

          inputSize && styles[`size-${inputSize}`],

          className
        )}
        disabled={disabled}
        ref={ref}
        type={type}
        {...attribute}
        {...rest}
      />
    );
  }
);
