import type { DeliveryType } from "entities/product/seller-product";

export const enum 판매요청_주문상태 {
  전체 = 0,
  입금확인 = 1,
  배송중 = 2,
  구매확정 = 3,
  취소관리 = 4,
  반품관리 = 5,
  교환관리 = 6,
  정산관리 = 7,
  정산확정 = 8,
}

export const enum 판매조회_주문상태 {
  입금대기 = 2,
  입금확인 = 3,
  배송중 = 4,
  구매확정 = 5,
  정산예정 = 21,
  정산완료 = 6,
  구매취소요청 = 7,
  구매취소완료 = 14,
  판매취소 = 11,
  판매취소완료 = 12,
  반품요청 = 8,
  반품성사 = 9,
  반품환불완료 = 10,
  교환요청 = 17,
  교환입고 = 18,
}

export const enum PickupStatus {
  회수신청완료 = 0,
  회수보류 = 1,
}

export interface SalesOrder {
  /** 주문번호 */
  orderId: string;
  /** 상품주문번호 */
  productOrderId: string;
  /** 주문 사이트 */
  orderSite: string;
  /** 상품 ID */
  productId: string;
  /** 상품코드 */
  productCode: string;
  /** 주문 진행 상태 */
  status: 판매조회_주문상태;
  statusText: string;
  /** 상품명 */
  productName: string;
  /** 판매가 (상품 1개당) */
  salePrice: number;
  totalPrice: number;
  /** 주문 수량 */
  orderQuantity: number;
  /** 해외배송 여부 */
  deliveryOverseas: boolean;
  /** 배송비 유형 */
  deliveryType: DeliveryType;
  /** 배송비 (도서산간 배송비 포함) */
  deliveryFee: number;
  /** 출고예정일 (YYYY-MM-DD) */
  deliveryTransDate: string;
  /** 주문 옵션 */
  orderOptionName: string;
  /** 주문 일시 (YYYY-MM-DDThh:mm:ss) */
  orderDateTime: string;
  /** 상품 썸네일 (선택적 필드) */
  image?: string;
  /** 회수 정보 (선택적 필드) */
  pickStatus?: PickupStatus;
  /** 회수 보류 메세지 (선택적 필드) */
  pickUpHoldReason?: string;
}

export type SalesOrderDetail = {
  productOrderId: number;
  productId: string;
  productCode: string;
  status: number;
  statusText: string;
  pickStatus?: number;
  productName: string;
  salePrice: number;
  orgPrice: number;
  orderQuantity: number;
  deliveryOverseas: false;
  deliveryType: number;
  deliveryFee: number;
  deliveryCompanyName: string;
  invoiceNumber: string;
  deliveryTransDate: string;
  sellerMessage: string;
  orderDateTime: string;
  img: string;
  paymentInfo: {
    paymentMileage: number;
    paymentPrice: number;
  };

  optionInfo: {
    orderOptionName: string;
    orderOptionQuantity: number;
  }[];
};

type BuyerInfo = {
  name: string;
  phone1: string;
  phone2: string;
  email: string;
};

type ReceiverInfo = {
  name: string;
  phone: string;
  postCode: string;
  addr1: string;
  addr2: string;
  deliveryMemo: string;
  personalCustomsCode: string;
};

export interface SalesDetailOrder {
  orderId: string;
  productOrderList: SalesOrderDetail[];
  buyerInfo: BuyerInfo;
  receiverInfo: ReceiverInfo;
}
