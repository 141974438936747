import type { CSSProperties } from "react";
import Shared from "..";
import type { AccordionProps } from "../Accordion/Accordion";
import styles from "./Section.module.scss";

type Props = AccordionProps & {
  contentStyle?: CSSProperties;
};

export const AccordionSection = ({
  children,
  title,
  isOpen: defaultValue,
  contentStyle,
  ...rest
}: Props) => {
  return (
    <Shared.Section
      className={styles.AccordionSection}
      type="card"
      style={{ paddingBottom: 0 }}
      {...rest}
    >
      <Shared.Accordion
        title={<span className={styles.title}>{title}</span>}
        isOpen={defaultValue}
        triggerStyle={{
          paddingBottom: "10px",
        }}
      >
        <div className={styles.content} style={contentStyle}>
          {children}
        </div>
      </Shared.Accordion>
    </Shared.Section>
  );
};
