import { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { HomeLayout } from "widgets/layout/ui/HomeLayout";

const OverseasDirectPurchases = lazy(
  () => import("../../legacyPages/OverseasDirectPurchases.view")
);
const PriceGolfHome = lazy(() => import("../../legacyPages/Home.view"));
const RealTimeProduct = lazy(
  () => import("../../legacyPages/RealTimeProduct.view")
);
const LowPriceProduct = lazy(
  () => import("../../legacyPages/LowPriceProduct.view")
);
const UsedPrice = lazy(() => import("../../legacyPages/UsedPrice.view"));
const ModelPrice = lazy(
  () => import("../../modules/UsedPrice/ModelPrice.module")
);
const PowerZone = lazy(() => import("../../legacyPages/PowerZone.view"));
const PowerSellerList = lazy(
  () => import("../../modules/PowerZone/PowerSellerList")
);
const NewProductShop = lazy(
  () => import("../../legacyPages/NewProductShop.view")
);
const HelpCenterPage = lazy(() =>
  import("pages/help").then(module => ({ default: module.HelpCenterPage }))
);

export const TopNavRoutes = () => {
  return (
    <HomeLayout>
      <Routes>
        <Route
          path=""
          element={
            <Suspense fallback={null}>
              <PriceGolfHome />
            </Suspense>
          }
        />

        <Route
          path="real-time"
          element={
            <Suspense fallback={null}>
              <RealTimeProduct />
            </Suspense>
          }
        />
        <Route
          path="price-reduction"
          element={
            <Suspense fallback={null}>
              <LowPriceProduct />
            </Suspense>
          }
        />
        <Route
          path="used-price"
          element={
            <Suspense fallback={null}>
              <UsedPrice />
            </Suspense>
          }
        >
          <Route
            path="model-price"
            element={
              <Suspense fallback={null}>
                <ModelPrice />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="power-zone/products"
          element={
            <Suspense fallback={null}>
              <PowerZone />
            </Suspense>
          }
        >
          <Route
            path="shops"
            element={
              <Suspense fallback={null}>
                <PowerSellerList />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="overseas"
          element={
            <Suspense fallback={null}>
              <OverseasDirectPurchases />
            </Suspense>
          }
        />

        <Route
          path="new"
          element={
            <Suspense fallback={null}>
              <NewProductShop />
            </Suspense>
          }
        />
        <Route
          path="help"
          element={
            <Suspense fallback={null}>
              <HelpCenterPage />
            </Suspense>
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </HomeLayout>
  );
};
