import "./Select.scss";

export const Select = (props: any) => {
  return (
    <div id="Select">
      <select
        {...(props.register &&
          !props.required && {
            ...props.register(props.inputName),
          })}
        {...(props.register &&
          props.required && {
            ...props.register(props.inputName, { required: props.required }),
          })}
        defaultValue={props.default}
        id={props.id}
        onChange={props.onChange}
        disabled={props.disabled}
        ref={props.ref}
        className={props.className}
        value={props.value}
      >
        {props.default && (
          <option value={props.default} disabled>
            {props.default}
          </option>
        )}
        {props.children}
      </select>
    </div>
  );
};
