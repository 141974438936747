import classNames from "classnames";
import type { CSSProperties, ReactNode } from "react";
import styles from "./Grid.module.scss";

type Props = {
  children: ReactNode[] | ReactNode;
  columns: number;
  gap?: CSSProperties["gap"];
  autoRows?: boolean;
};

export const Grid = ({ children, columns, gap = 4, autoRows }: Props) => {
  return (
    <div
      className={classNames(styles.Grid, autoRows && styles[`auto-rows`])}
      style={{ gridTemplateColumns: `repeat(${columns}, 1fr)`, gap }}
    >
      {children}
    </div>
  );
};
