import classNames from "classnames";
import type { HTMLAttributes, ReactNode } from "react";
import { LinkTitle } from "./LinkTitle";
import styles from "./Section.module.scss";
import { Title } from "./Title";

type SectionProps = Omit<HTMLAttributes<HTMLDivElement>, "title"> & {
  title?: ReactNode;

  type?: "none" | "card";
  fullWidthOffset?: boolean;

  hideContent?: boolean;
};

export const Section = ({
  title,
  children,
  className,
  type = "none",
  fullWidthOffset,
  hideContent,
  ...rest
}: SectionProps) => {
  return (
    <section
      className={classNames(
        styles.Section,
        styles[`type-${type}`],
        fullWidthOffset && styles.fullWidthOffset,
        className
      )}
      {...rest}
    >
      {title && (
        <h2
          className={styles.title}
          style={{ paddingBottom: hideContent ? 0 : "" }}
        >
          {title}
        </h2>
      )}

      {!hideContent && <div className={styles.container}>{children}</div>}
    </section>
  );
};

Section.Title = Title;
Section.LinkTitle = LinkTitle;
