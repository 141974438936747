import type { ProductModelPayload } from "entities/product/product-model";
import { useProductModelsQuery } from "entities/product/product-model";
import type { ProductRegisterData } from "entities/product/seller-product";
import { useEffect, useRef, useState } from "react";
import Shared from "shared/ui";
import { ProductBrandSelectorModal } from "./ProductBrandSelectorModal/ProductBrandSelectorModal";

type Props = {
  대분류: ProductModelPayload["대분류"];

  brandCode: ProductRegisterData["brandCode"];
  onBrandChange: (brandCode: ProductRegisterData["brandCode"]) => void;

  modelCode: ProductRegisterData["modelCode"];
  onModelChange: (modelCode: ProductRegisterData["modelCode"]) => void;

  isSubmitted: boolean;
};

export const ProductBrandModelSelect = ({
  대분류,

  brandCode,
  onBrandChange,

  modelCode,
  onModelChange,

  isSubmitted,
}: Props) => {
  const [currentBrandCode, setCurrentBrandCode] = useState<string>(brandCode);

  useEffect(() => {
    setCurrentBrandCode(brandCode);
  }, [brandCode]);

  const { data: modelList, isLoading } = useProductModelsQuery({
    대분류,
    brandCode: currentBrandCode,
  });

  const isInitialized = useRef(false);

  useEffect(() => {
    if (isLoading) return;
    if (isInitialized.current) {
      modelList?.length ? onModelChange("") : onModelChange(0);
    }

    isInitialized.current = true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelList, isLoading]);

  return (
    <Shared.VStack gap={10}>
      <div>
        {/* 브랜드 선택 */}
        <ProductBrandSelectorModal
          defaultBrandCode={currentBrandCode}
          onSelect={brand => {
            setCurrentBrandCode(brand.brandCode);
            onBrandChange(brand.brandCode);
          }}
        />
        {isSubmitted && !brandCode && (
          <p className="validationErrorMessage">브랜드를 선택해주세요.</p>
        )}
      </div>

      <div>
        {/* 모델 선택 */}
        <Shared.Select
          defaultOption={{ name: "모델 선택" }}
          // defaultValue=""
          value={modelCode}
          onChange={e => onModelChange(e.target.value)}
          options={[
            ...(modelList
              ? modelList.map(({ modelCode, modelName }) => ({
                  value: modelCode,
                  name: modelName,
                }))
              : []),
            {
              value: 0,
              name: "등록된 모델 없음",
            },
          ]}
        />
        {isSubmitted && modelList && modelList.length > 1 && !modelCode && (
          <p className="validationErrorMessage">모델을 선택해주세요.</p>
        )}
      </div>
    </Shared.VStack>
  );
};
