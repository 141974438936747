import type { FieldError, UseFormRegisterReturn } from "react-hook-form";
import Shared from "shared/ui";

type Props = {
  register: UseFormRegisterReturn<"productName">;
  isSubmitted: boolean;
  error: FieldError | undefined;
  isEditMode?: boolean;
};

export const ProductNameInput = ({
  register,
  error,
  isSubmitted,
  isEditMode,
}: Props) => {
  return (
    <Shared.VStack>
      <Shared.Input
        placeholder="상품명"
        {...register}
        fullWidth
        disabled={isEditMode}
      />

      <p className="redtext">* 상품명/옵션명은 등록 후 수정이 불가합니다.</p>

      {isSubmitted && error?.type === "required" && (
        <p className="validationErrorMessage">상품명을 입력해주세요.</p>
      )}

      {error?.type === "minLength" && (
        <p className="validationErrorMessage">
          상품명은 최소 5글자이상 입력해주세요.
        </p>
      )}

      {error?.type === "maxLength" && (
        <p className="validationErrorMessage">
          상품명은 최대 60글자 이하로 제한됩니다.
        </p>
      )}
    </Shared.VStack>
  );
};
