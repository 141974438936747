import type React from "react";
import { useEffect, useRef, useState } from "react";

type DefaultKey = React.Key | undefined | null;
type DefaultKeyFunction = () => DefaultKey;

type FunctionalChildren = () => JSX.Element;

type Item = {
  key: React.Key;
  label: string;
  children: React.ReactNode | FunctionalChildren;
};

export type useTabsProps = {
  items: Item[];
  onChange?: (activeKey: React.Key) => void;
  defaultActiveKey?: DefaultKey | DefaultKeyFunction;
};

export const useTabs = ({
  items,
  defaultActiveKey,
  onChange,
}: useTabsProps) => {
  const isInitialized = useRef(false);
  const [activeItem, setActiveItem] = useState<Item>();

  useEffect(() => {
    if (isInitialized.current || defaultActiveKey === null) return;

    let defaultKey: DefaultKey;

    if (typeof defaultActiveKey === "function") {
      defaultKey = defaultActiveKey();
      if (!defaultKey) return;
    } else {
      defaultKey = defaultActiveKey;
    }

    const activeItem = items.find(({ key }) => key === defaultKey) ?? items[0];
    setActiveItem(activeItem);
    isInitialized.current = true;
  }, [defaultActiveKey, items]);

  useEffect(() => {
    if (!activeItem) return;
    onChange?.(activeItem?.key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItem]);

  return {
    activeItem,
    handleTabClick: (item: Item) => setActiveItem(item),
  };
};
