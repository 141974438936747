import classNames from "classnames";
import { useRef, type InputHTMLAttributes, type ReactNode } from "react";
import Shared from "..";
import { BaseButton } from "../Button/BaseButton";
import { Button } from "../Button/Button";
import { Icon } from "../Icon/Icon";
import { HStack } from "../Stack/Stack";
import styles from "./Uploader.module.scss";

export type UploaderProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "type"
> & {
  children?: ReactNode;
  isLoading?: boolean;
};

export const Uploader = ({
  children,
  className,
  isLoading,
  disabled,
  onChange,
  ...rest
}: UploaderProps) => {
  const ref = useRef<HTMLInputElement>(null);

  return (
    <BaseButton
      className={classNames(
        styles.Uploader,
        disabled && styles.disabled,
        className
      )}
      onClick={() => {
        ref.current?.click();
      }}
    >
      <div className={styles.inner}>
        <input
          ref={ref}
          type="file"
          className={styles.fileInput}
          disabled={isLoading || disabled}
          onChange={e => {
            onChange?.(e);
            e.target.value = "";
          }}
          {...rest}
        />

        {!isLoading &&
          (children || (
            <Button className={styles.defaultButton}>
              <HStack align="center" gap={6}>
                <Icon name="plus" size={12} />
                <span className={styles.text}>파일 업로드</span>
              </HStack>
            </Button>
          ))}

        {isLoading && (
          <div className={styles.loading}>
            <div className={styles.spinner}>
              <Shared.Spinner />
            </div>
          </div>
        )}
      </div>
    </BaseButton>
  );
};
