/* eslint-disable no-param-reassign */
import { useAppSelector } from "app/appStore";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { showAlert } from "shared/lib";
import type { LinkButtonProps } from "shared/ui/Button/LinkButton";
import { useSellerInfo } from "../api/sellerInfoApi";

const alertMessage = `
  <div style="word-break: keep-all;">
    <b>개인정보 파기확인서를 필히 작성(서명)해주세요!</b>
    <br/>
    PC 버전으로 접속하여 개인정보 파기 확인서를 반드시 작성해 주셔야 프라이스골프 모바일에서 상품 등록 및 수정하실 수 있습니다.
  </div>
`;

/**
 * 파기확인서 작성 여부 확인
 */
export const useCheckRequestCustomerInfoDestroyConfirm = (redirect = true) => {
  const isAlertShowed = useRef(false);

  const navigate = useNavigate();
  const { navigationPath } = useAppSelector(state => state.user);

  const { data: sellerInfo, isFetched } = useSellerInfo();
  const hasToAlert = isFetched && sellerInfo?.requestCustomerInfoDestroyConfirm;

  useEffect(() => {
    if (!hasToAlert || isAlertShowed.current) return;
    requestCustomerInfoDestroyConfirmAlert().then(() => {
      if (!redirect) return;
      if (navigationPath.from) {
        navigate(-1);
      } else {
        navigate("/");
      }
    });

    isAlertShowed.current = true;
  }, [hasToAlert, navigate, navigationPath.from, redirect]);
};

export const getDestroyLinkButtonProps = (
  hasToAlert: boolean | undefined
): Pick<LinkButtonProps, "disabledNavigate" | "onClick"> => {
  return {
    disabledNavigate: hasToAlert,
    onClick: () => {
      if (hasToAlert) {
        requestCustomerInfoDestroyConfirmAlert();
      }
    },
  };
};

export const requestCustomerInfoDestroyConfirmAlert = () => {
  return showAlert("", {
    html: alertMessage,
    icon: "warning",
  });
};
