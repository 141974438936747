export const FILE_UPLOAD_TYPE = {
  상품_썸네일_이미지: 1,
  사업자_등록증_사본: 2,
  통신판매업_신고증_사본: 3,
  사업자_통장_사본: 4,
  상품평_이미지: 5,
  게시판_이미지: 6,
  시큐렛_이미지: 7,
} as const;

export type FileUploadType = keyof typeof FILE_UPLOAD_TYPE;
export type FileUploadTypeValues = (typeof FILE_UPLOAD_TYPE)[FileUploadType];

export type FileUploadFormData = { files: File };

export type UploadedFile = { seq: number; fileUrl: string; filePath: string };
