import type { InputProps } from "..";
import Shared from "..";

const EMAIL_DOMAIN = [
  "naver.com",
  "hanmail.net",
  "gmail.com",
  "nate.com",
  "yahoo.co.kr",
  "empas.com",
  "dreamwiz.com",
  "freechal.com",
  "lycos.co.kr",
  "korea.com",
  "hotmail.com",
  "hanmir.com",
  "paran.com",
];

type Props = {
  idProps: InputProps;
  domainProps: InputProps;
  domainSelectProps: {
    value: string | undefined;
    onChange: React.ChangeEventHandler<HTMLSelectElement>;
  };
};

export const EmailInput = ({
  idProps,
  domainProps,
  domainSelectProps,
}: Props) => {
  return (
    <Shared.VStack>
      <Shared.HStack align="center">
        <Shared.Input {...idProps} />
        <span>@</span>
        <Shared.Input {...domainProps} />
      </Shared.HStack>

      <Shared.Select
        value={domainSelectProps.value ?? ""}
        onChange={domainSelectProps.onChange}
        defaultOption={{ name: "직접입력", enable: true }}
        options={EMAIL_DOMAIN.map(domain => ({ value: domain, name: domain }))}
      />
    </Shared.VStack>
  );
};
