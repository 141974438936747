import classNames from "classnames";
import type { CSSProperties, ReactNode } from "react";
import { useBoolean } from "shared/hooks";
import Shared from "shared/ui";
import { Icon } from "shared/ui/Icon/Icon";
import styles from "./Accordion.module.scss";

export type AccordionProps = {
  title: ReactNode;
  children: ReactNode;

  triggerStyle?: CSSProperties;

  isOpen?: boolean;
};

export const Accordion = ({
  title,
  children,
  isOpen: defaultValue,
  triggerStyle,
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useBoolean(defaultValue);

  return (
    <div className={classNames(styles.AccordionItem, isOpen && styles.isOpen)}>
      <h3 style={triggerStyle}>
        <Shared.BaseButton
          className={styles.trigger}
          aria-expanded={isOpen}
          onClick={() => setIsOpen.toggle()}
        >
          <span className={styles.title}>{title}</span>
          <Icon className={styles.arrowIcon} name="arrowDown" size={20} />
        </Shared.BaseButton>
      </h3>

      <div className={styles.panel} role="region">
        {isOpen && children}
      </div>
    </div>
  );
};
