import {
  getUserInfoFromAccessToken,
  getUserInfoFromToken,
  validateTokenTime,
} from "entities/user-info";
import { isKeepLogin } from "features/auth/keep-login/lib/keepLogin";
import { getSignOutPayload, signOutApi } from "features/auth/sign-out";
import type { KeepLoginResponse } from "service/users/userService";
import { userService } from "service/users/userService";
import { COOKIE_KEYS } from "shared/constants/cookieKeys";
import { getCookie } from "shared/lib/legacyUtils/cookie";
import { showAlert } from "shared/lib/showAlert";
import { instance } from "./axios";

// TODO: 리펙토링 필요!

let refreshing_token: any = null;
let req_login: any = null;

export const errorHandlers: { [key: string]: (err: any) => Promise<any> } = {
  handleNetworkError: async err => {
    showAlert("네트워크 연결에 실패하였습니다.").then(result => {
      if (result.isConfirmed) {
        window.location.href = "/";
      }
    });

    return Promise.reject(err);
  },

  handleCartId: async ({ config }) => {
    return instance({
      ...config,
      headers: { ...config.headers, "Cart-Id": getCartId() },
    });
  },

  handleAuthorizationError: async err => {
    if (validateTokenTime("refreshToken")) {
      return refreshToken(err);
    }
    return isKeepLogin() ? keepLogin(err) : logout(err);

    async function refreshToken(err: any) {
      // [api 호출이 여러개여도 accessToken 재발급은 한 번만 실행하도록 설정]
      // -> 최초 refreshing_token에 값이 null이므로 refresh_token()실행 O
      // -> 그 다음부터는 refreshing_token의 값이 refresh_token()에서 return한 res값이므로 refresh_token()실행 X
      refreshing_token = refreshing_token || userService.refreshToken();
      const res: KeepLoginResponse = await refreshing_token;
      refreshing_token = null;

      if (res.responseType === "SUCCESS") {
        return instance({
          ...err.config,
          headers: {
            ...err.config.headers,
            Authorization: `Bearer ${res.accessToken}`,
            "Cart-Id": getUserInfoFromToken(res.accessToken).id,
          },
        });
      }
    }

    async function keepLogin(err: any) {
      return (async () => {
        req_login = req_login || userService.keepLogin();
        const res: KeepLoginResponse = await req_login;
        req_login = null;
        if (res.responseType === "SUCCESS") {
          return instance({
            ...err.config,
            headers: {
              ...err.config.headers,
              Authorization: `Bearer ${res.accessToken}`,
              "Cart-Id": getUserInfoFromToken(res.accessToken).id,
            },
          });
        }
      })();
    }

    async function logout(err: any) {
      if (!err.config._signout) {
        const res = await signOutApi.signout(getSignOutPayload());
        if (res?.logout) {
          window.location.href = "/signin";
        }
      }
      err.config._signout = true;
    }
  },
};

export function getCartId() {
  const userInfo = getUserInfoFromAccessToken();
  return userInfo ? userInfo.id : (getCookie(COOKIE_KEYS.TEMP_CART_ID) ?? "");
}
