import { useAppDispatch, useAppSelector } from "app/appStore";
import { useEffect } from "react";
import Shared from "shared/ui";
import { updateNavigationPath } from "../../../store/features/user/userSlice";
import "./PopUpView.scss";

export const PopUpView = ({
  title,
  setClose,
  setOpenPopUpPage,
  children,
}: {
  title?: string;
  setClose?: any;
  children: any;
  setOpenPopUpPage?: any;
}) => {
  const dispatch = useAppDispatch();
  const { navigationPath } = useAppSelector(state => state.user);

  useEffect(() => {
    if (window.location.pathname !== navigationPath?.to) {
      dispatch(
        updateNavigationPath({
          from: navigationPath?.to,
          to: window.location.pathname,
        })
      );
    }
    document.body.setAttribute("style", "overflow: hidden");
    return () => document.body.setAttribute("style", "overflow: auto");
  }, []);

  return (
    <div id="PopUpView">
      {title ? (
        <>
          <Shared.LocalHeader title={title} />
          <img
            id="icon_close"
            src="/assets/icons/icon_navi_close.svg"
            alt="닫기 아이콘"
            onClick={() => setClose(false)}
          />
        </>
      ) : null}
      <div className={title ? "wrapper" : "no_title"}>{children}</div>
    </div>
  );
};
