import { useQuery } from "@tanstack/react-query";
import { instance } from "shared/api";
import { api } from "shared/constants/api";
import { baseQueryKeys } from "shared/constants/baseQueryKeys";
import type { APIResponse } from "shared/types/server";
import type { CategoryResponse } from "./types";

const queryKey = [baseQueryKeys.product, "category"] as const;

const productCategoryApi = {
  getCategories: async () => {
    const {
      data: { data: response },
    } = await instance.get<APIResponse<CategoryResponse>>(
      api.product.categories
    );

    return response;
  },
};

export function useProductCategoriesQuery() {
  return useQuery({
    queryKey,
    queryFn: productCategoryApi.getCategories,
    select: data => data.categoryList,
  });
}
