import type { InfiniteQueryObserverBaseResult } from "@tanstack/react-query";
import { useCallback } from "react";
import { useIntersect } from "shared/hooks";

type InfiniteScrollState = Pick<
  InfiniteQueryObserverBaseResult,
  "hasNextPage" | "fetchNextPage" | "isFetching"
>;

export type UseInfiniteScrollProps = InfiniteScrollState & {
  rootMargin?: IntersectionObserverInit["rootMargin"];
};

export const useInfiniteScrollRef = ({
  hasNextPage,
  fetchNextPage,
  isFetching,

  rootMargin = "120%",
}: UseInfiniteScrollProps) => {
  const loadMoreItems = useCallback(() => {
    if (hasNextPage && !isFetching) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetching, fetchNextPage]);

  const ref = useIntersect(
    async (entry, observer) => {
      if (!entry.isIntersecting) return;

      observer.unobserve(entry.target);
      loadMoreItems();
    },
    { rootMargin }
  );

  return ref;
};
