import { ActionButton } from "./ActionButton";
import type { ActionButtonProps } from "./types";

export const CloseButton = ({
  styleVariant = "none",
  ...rest
}: Omit<ActionButtonProps, "icon" | "iconProps">) => {
  return (
    <ActionButton
      icon="close"
      iconProps={{ size: 30 }}
      style={{ padding: "2px 3px" }}
      styleVariant={styleVariant}
      {...rest}
    />
  );
};
