import { useQuery } from "@tanstack/react-query";
import { cartQueryKey } from "entities/cart";
import { instance } from "shared/api";
import { api } from "shared/constants/api";
import type { APIResponse, ResponseData } from "shared/types/server";

export type CartDeliveryFeePayload = {
  cartIds: number[];
};

type CartDeliveryFeeResponse = ResponseData & {
  totalDeliveryFee: number;
};

const cartDeliveryFeeApi = {
  /**
   * 장바구니 배송비 계산
   * @see https://sbridge.atlassian.net/wiki/x/AYBRC
   */
  calc: async (data: CartDeliveryFeePayload) => {
    const {
      data: { data: response },
    } = await instance.post<APIResponse<CartDeliveryFeeResponse>>(
      api.basket.deliveryfee,
      data
    );

    return response;
  },
};

export function useCartDeliveryFeeQuery(payload: CartDeliveryFeePayload) {
  return useQuery({
    queryKey: cartQueryKey.deliveryFee(payload),
    queryFn: () => cartDeliveryFeeApi.calc(payload),
    select: data => data.totalDeliveryFee,
  });
}
