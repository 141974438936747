import { BottomNavigation } from "@components/zLegacyLayouts/Navigations/BottomNavigation";
import classNames from "classnames";
import { useEffect, type HTMLAttributes, type ReactNode } from "react";
import { scrollToTop } from "shared/lib";
import Shared from "..";
import styles from "./ItemListLayout.module.scss";

export type ItemListLayoutProps = HTMLAttributes<HTMLDivElement> & {
  itemCount: number | undefined;
  CustomSkeleton?: ReactNode;
  CustomEmptyView?: ReactNode;
  isFetching: boolean;
  isLoading: boolean;
  noNavigation?: boolean;

  panelHeight?: number;

  children: ReactNode;

  colorVariants?: "white" | "none";
};

export const ItemListLayout = ({
  itemCount,
  CustomSkeleton = <Shared.LoadingOverlay />,
  CustomEmptyView = "조회 결과가 없습니다.",
  isLoading,
  isFetching,
  children,
  noNavigation,
  panelHeight,
  colorVariants,
  ...rest
}: ItemListLayoutProps) => {
  const showSkeleton = isLoading || isFetching;

  useEffect(() => {
    isLoading && scrollToTop();
  }, [isLoading]);

  return (
    <div
      className={classNames(
        styles.ItemListLayout,
        styles[`color-${colorVariants}`]
      )}
      {...rest}
    >
      <div
        className={styles.itemList}
        style={{
          minHeight: `calc(100vh - var(--size-gnb) - var(--size-bottom-navigation) ${panelHeight ? `- ${panelHeight}px` : ""})`,
        }}
      >
        {children}

        {!isLoading && itemCount === 0 && (
          <div className={styles.emptyView}>{CustomEmptyView}</div>
        )}

        {showSkeleton && CustomSkeleton}
      </div>

      {!noNavigation && <BottomNavigation noMargin />}
    </div>
  );
};
