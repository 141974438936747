import { useEffect } from "react";

//------------------------------------------------------------

/**
 *
 * @deprecated formatPrice 로 변경하기
 */
export const priceToString = (price) => {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const Date = () => {
  const today = new Date();
  const month = today.getMonth() + 1;
  return { month };
};

export const getHighlightedText = (text, higlight) => {
  const parts = text.slice(text.indexOf(`${higlight}`), 1);
  return parts.map((part, idx) =>
    part === higlight ? (
      // eslint-disable-next-line react/no-array-index-key
      <span key={idx} style={{ color: "#ef2287" }}>
        {part}
      </span>
    ) : (
      <span>part</span>
    )
  );
};

// TODO: 확인
export const useGetHistory = (setClose, url = "") => {
  useEffect(() => {
    window.history.pushState("히스토리 생성", "", url);
    window.onpopstate = () => setClose(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [useEffect];
};

export const debounce = (func, delay) => {
  let timeoutId = null;
  return (...arg) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...arg), delay);
  };
};

export const onErrorImg = (e) => {
  e.target.src = "/assets/images/default.png";
};
