import { useEffect } from "react";

export const useLockBodyScroll = (isLock = true) => {
  useEffect(() => {
    isLock
      ? document.body.setAttribute("style", "overflow: hidden")
      : document.body.setAttribute("style", "overflow: auto");

    return () => document.body.setAttribute("style", "overflow: auto");
  }, [isLock]);
};
