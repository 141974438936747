/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  scrollY: 0,
  page: 1,
};

const scrollSlice = createSlice({
  name: "scroll",
  initialState,
  reducers: {
    storePosition(state, action) {
      state.scrollY = action.payload?.scrollY;
      state.page = action.payload?.page;
    },
  },
  extraReducers: {},
});

export default scrollSlice;
export const { storePosition } = scrollSlice.actions;
