import classNames from "classnames";
import { forwardRef } from "react";
import styles from "./BaseButton.module.scss";
import type { ButtonBaseProps } from "./types";

export const BaseButton = forwardRef<HTMLButtonElement, ButtonBaseProps>(
  ({ type = "button", children, className, ...rest }, ref) => {
    return (
      <button
        className={classNames(styles.BaseButton, className)}
        // eslint-disable-next-line react/button-has-type
        type={type}
        ref={ref}
        {...rest}
      >
        {children}
      </button>
    );
  }
);
