import { removeLocalDataForSignOut } from "features/auth/sign-out";
import { COOKIE_KEYS } from "shared/constants/cookieKeys";
import { removeCookie } from "shared/lib/legacyUtils/cookie";
import { showAlert } from "shared/lib/showAlert";

export type ErrorHandler = (
  errorCode: string | undefined,
  errorMessage: string | undefined,
  error?: any
) => void;

/**
 * /api/users 관련 에러 핸들러 모음
 */
export const userErrorHandlers: { [key: string]: ErrorHandler } = {
  // 회원가입
  signup: (errorCode, errorMessage) => {
    showAlert.error({
      errorConditions: [
        "ERR_002_005_002",
        "ERR_002_005_008",
        "ERR_002_005_011",
        "ERR_002_005_012",
        "ERR_002_005_014",
        "ERR_002_005_010",
        "ERR_002_005_015",
        "ERR_COMMON_VALIDATION",
      ],
      errorCode,
      errorMessage,
    });

    if (errorCode === "ERR_002_013_001" && errorMessage) {
      showAlert(errorMessage).then(result => {
        if (result.isConfirmed) {
          window.location.href = "/signup";
        }
      });
    }
  },

  keepLogin: (errorCode, errorMessage, error) => {
    removeLocalDataForSignOut();
    removeCookie(COOKIE_KEYS.TEMP_CART_ID, { path: "/" });

    if (errorMessage) {
      showAlert(errorMessage).then(result => {
        if (result.isConfirmed) {
          window.location.href = "/signin";
        }
      });
    }

    return Promise.reject(error);
  },
};
