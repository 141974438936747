import classNames from "classnames";
import styles from "./ProductModules.module.scss";

type Props = {
  productName: string;
  className?: string;
};

export const ProductName = ({
  productName,

  className,
}: Props) => {
  return (
    <div className={classNames(styles.ProductName, className)}>
      <p className={styles.name}>{productName}</p>
    </div>
  );
};
