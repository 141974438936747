import type { ProductBrand } from "entities/product/product-brand";
import { useProductBrandsQuery } from "entities/product/product-brand";
import { useEffect, useState } from "react";
import Shared from "shared/ui";
import styles from "./ProductBrandSelectorModal.module.scss";

const DEFAULT_BRAND_NAME = "브랜드";

type Props = {
  defaultBrandCode: string;
  onSelect: (brand: ProductBrand) => void;
};

export const ProductBrandSelectorModal = ({
  defaultBrandCode,
  onSelect,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentBrand, setCurrentBrand] = useState<ProductBrand>();

  const { data: brandList } = useProductBrandsQuery();
  const [filteredBrandList, setFilteredBrandList] = useState<ProductBrand[]>(
    []
  );

  useEffect(() => {
    setFilteredBrandList(brandList || []);
  }, [brandList]);

  useEffect(() => {
    const _defaultBrand = brandList?.find(
      ({ brandCode }) => brandCode === defaultBrandCode
    );

    setCurrentBrand(_defaultBrand);
  }, [defaultBrandCode, brandList]);

  const filterBrands = (searchKeyword: string) => {
    if (!brandList) return;
    if (searchKeyword === "") {
      setFilteredBrandList(brandList || []);
      return;
    }

    const result = brandList.filter(
      ({ brandNameKor, brandNameEng }) =>
        brandNameKor
          .toLocaleLowerCase()
          ?.includes(searchKeyword.toLocaleLowerCase()) ||
        brandNameEng
          .toLocaleLowerCase()
          ?.includes(searchKeyword.toLocaleLowerCase())
    );
    setFilteredBrandList(result);
  };

  const handleBrandSelect = (brand: ProductBrand) => {
    onSelect(brand);
    setCurrentBrand(brand);
    setIsOpen(false);
  };

  return (
    <>
      <button
        className="styledSelect"
        type="button"
        onClick={() => setIsOpen(true)}
      >
        {currentBrand?.brandNameEng ?? DEFAULT_BRAND_NAME}
      </button>

      <Shared.Modal
        className={styles.ProductBrandSelectorModal}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <Shared.Modal.Header className={styles.header}>
          <h3 className={styles.title}>브랜드 선택</h3>

          <Shared.SearchForm
            placeholder="브랜드 검색"
            onChange={e => {
              const newKeyword = e.target.value;
              filterBrands(newKeyword);
            }}
            onKeyDown={e => {
              const target = e.target as EventTarget & HTMLInputElement;
              if (e.key === "Enter") {
                filterBrands(target.value);
              }
            }}
            colorVariants="gray"
          />
        </Shared.Modal.Header>

        <div className={styles.contents}>
          <ul className={styles.brandList}>
            {filteredBrandList.map(brand => (
              <li key={brand.brandCode} className={styles.brandItem}>
                <Shared.BaseButton onClick={() => handleBrandSelect(brand)}>
                  {brand.brandNameEng}
                </Shared.BaseButton>
              </li>
            ))}

            {filteredBrandList.length === 0 && (
              <div className={styles.empty}>
                <p>검색 결과가 없습니다.</p>
              </div>
            )}
          </ul>
        </div>
      </Shared.Modal>
    </>
  );
};
