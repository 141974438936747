import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const IllegalDealListPage = lazy(() =>
  import("pages/help/illegal-deal").then(module => ({
    default: module.IllegalDealListPage,
  }))
);

const UnsafetyInquire = lazy(() =>
  import("../../modules/boards/unsafety/UnsafetyInquire.view").then(module => ({
    default: module.UnsafetyInquire,
  }))
);

const UnsafetyRegiser = lazy(() =>
  import("../../modules/boards/unsafety/UnsafetyRegiser.view").then(module => ({
    default: module.UnsafetyRegiser,
  }))
);

export const IllegalDealRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={null}>
            <IllegalDealListPage />
          </Suspense>
        }
      />
      <Route
        path=":number"
        element={
          <Suspense fallback={null}>
            <UnsafetyInquire />
          </Suspense>
        }
      />
      <Route
        path="register"
        element={
          <Suspense fallback={null}>
            <UnsafetyRegiser />
          </Suspense>
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
