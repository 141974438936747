import { useEffect } from "react";

const $body = document.body;
const defaultBgColor = "#f6f6f6";

export const useSetBackgroundColor = ({
  backgroundColor = defaultBgColor,
  dep,
}: {
  backgroundColor?: string;
  dep?: unknown;
} = {}) => {
  useEffect(() => {
    $body.style.backgroundColor = backgroundColor;

    return () => {
      $body.style.backgroundColor = "#ffffff";
    };
  }, [backgroundColor, dep]);
};
