import { ThumbnailImageUpload } from "@components/zLegacyAtom/Inputs/FileUpLoad/ThumbnailImageUpload";
import type { Dispatch, SetStateAction } from "react";
import { useEffect, useState } from "react";

type Props = {
  title: string;
  setAttachedImages: (images: string[]) => void;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

export const ImageUploadManager = ({
  title,
  setAttachedImages,
  setIsLoading,
}: Props) => {
  const [ThumbnailImages, setThumbnailImages] = useState([
    { idx: 0, path: "", url: "" },
    { idx: 1, path: "", url: "" },
    { idx: 2, path: "", url: "" },
  ]);

  useEffect(() => {
    const filterdList = ThumbnailImages.filter((img) => img.path !== "");
    const imagesInfoArr: string[] = [];
    filterdList.forEach((img) => imagesInfoArr.push(img.path));
    setAttachedImages(imagesInfoArr);
  }, [ThumbnailImages, setAttachedImages]);

  return (
    <>
      <p>{title}</p>
      <div className="image_section">
        {ThumbnailImages?.map(({ idx }, index) => (
          <ThumbnailImageUpload
            imageFiles={ThumbnailImages}
            setImageFiles={setThumbnailImages}
            key={index}
            idx={idx}
            type={6}
            setIsLoading={setIsLoading}
          />
        ))}
      </div>
      <span className="graytext">
        * JPG, JPEG, PNG, GIF 파일만 업로드가 가능합니다.
      </span>
    </>
  );
};
