import classNames from "classnames";
import styles from "./Card.module.scss";
import type { CardChildrenProps } from "./types";

export const CardFooter = ({
  className,
  children,
  ...rest
}: CardChildrenProps) => {
  return (
    <footer className={classNames(styles.CardFooter, className)} {...rest}>
      {children}
    </footer>
  );
};
