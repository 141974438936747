/* eslint-disable no-param-reassign */
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

export type 카테고리 = {
  대분류?: string;
  중분류?: string;
  소분류?: string;
};

type SaveProductState = {
  카테고리: 카테고리;

  상품고시정보_제조국?: string;
};

const initialState: SaveProductState = {
  카테고리: {
    대분류: undefined,
    중분류: undefined,
    소분류: undefined,
  },
};

const saveProductSlice = createSlice({
  name: "save-product",
  initialState,
  reducers: {
    setCategory(state, action: PayloadAction<카테고리>) {
      const { 대분류, 중분류, 소분류 } = action.payload;

      if (대분류 !== undefined) {
        state.카테고리.대분류 = 대분류;
      }
      if (중분류 !== undefined) {
        state.카테고리.중분류 = 중분류;
      }
      if (소분류 !== undefined) {
        state.카테고리.소분류 = 소분류;
      }
    },

    상품고시정보_제조국_입력(state, action: PayloadAction<string>) {
      state.상품고시정보_제조국 = action.payload;
    },

    resetSaveProductState(state) {
      state.상품고시정보_제조국 = undefined;
      state.카테고리 = initialState.카테고리;
    },
  },
});

export default saveProductSlice;
export const { setCategory, 상품고시정보_제조국_입력, resetSaveProductState } =
  saveProductSlice.actions;
