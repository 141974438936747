import type { ReactNode } from "react";
import Shared from "shared/ui";
import { HomeHeader } from "shared/ui/Headers/HomeHeader/HomeHeader";

type Props = {
  children: ReactNode | ReactNode[];
};

export const HomeLayout = ({ children }: Props) => {
  return (
    <Shared.Layout
      Header={<HomeHeader />}
      style={{
        padding: "0",
        paddingTop: "var(--size-lnb)",
      }}
    >
      <main style={{ flex: 1 }}>{children}</main>
    </Shared.Layout>
  );
};
