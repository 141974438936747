import Shared from "..";
import type { SearchInputProps } from "../Input/SearchInput";

type SearchFormProps = Omit<SearchInputProps, "onSubmit"> & {
  onSubmit?: (value: string) => void;
};

export const SearchForm = ({
  className,
  style,
  onSubmit,
  buttonType = "submit",
  ...rest
}: SearchFormProps) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const input = form.querySelector(
      'input[type="search"]'
    ) as HTMLInputElement;
    onSubmit?.(input?.value ?? "");
  };

  return (
    <form className={className} style={style} onSubmit={handleSubmit}>
      <Shared.SearchInput buttonType={buttonType} {...rest} />
    </form>
  );
};
