import type { FileUploadType } from "features/file/upload-file";
import { useFileUpload } from "features/file/upload-file";
import { useCallback, type RefObject } from "react";
import type ReactQuill from "react-quill";
import { generateFormDataWithRotatedImages } from "../ImageUploader/ImageUploaderInput/ImageUploaderInput.utils";

export const useImageHandler = (
  quillRef: RefObject<ReactQuill>,
  type: FileUploadType
) => {
  const { mutate: fileUpload, isLoading } = useFileUpload();

  /**
   * @see https://velog.io/@runprogrmm/Next.js-React-react-quill-에디터로-이미지-처리하기
   */
  const imageHandler = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      if (!input.files) return;

      const files = Array.from([input.files[0]]);
      const formData = await generateFormDataWithRotatedImages(files);

      fileUpload(
        { type, formData },
        {
          onSuccess: ({ fileList }) => {
            const _image = fileList[0].fileUrl;

            if (quillRef.current) {
              const quill = quillRef.current.getEditor();
              const range = quill.getSelection();
              if (range) {
                quill.insertEmbed(range.index, "image", _image);
                quill.setSelection(range.index + 1, 0);
              }
            }
          },
        }
      );
    };
  }, [fileUpload, quillRef, type]);

  return { imageHandler, isLoading };
};
