import { useNoSellFakeProductModal } from "@components/zLegacyLayouts/Modals/NoSellFakeProductModal";
import type { RegisterMode } from "pages/products/resister";
import { useForm, useWatch } from "react-hook-form";
import { showAlert } from "shared/lib/showAlert";

export type 상품약관 = {
  regProducts: boolean;
  fakeSales: boolean;
  directTrade: boolean;
  salesRules: boolean;
};

type Props = {
  registerMode: RegisterMode;
};

export const useTermForm = ({ registerMode }: Props) => {
  const modal = useNoSellFakeProductModal(registerMode);

  const termForm = useForm<상품약관>({
    mode: "onChange",
    defaultValues: {
      regProducts: true,
      fakeSales: !!(
        (registerMode === "normal" && modal.NOT_SHOW_FAKE_SALES_NOTICE) ||
        registerMode === "productCopy"
      ),
      directTrade: true,
      salesRules: true,
    },
  });

  const { directTrade, fakeSales, regProducts, salesRules } = useWatch({
    control: termForm.control,
  });

  const validateTerms = () => {
    if (!regProducts) {
      showAlert("상품판매 약관에 동의하셔야 합니다.");
      return false;
    }

    if (!fakeSales) {
      showAlert("가품판매 금지 약관에 동의하셔야 합니다.");
      return false;
    }

    if (!directTrade) {
      showAlert("직거래 금지 약관에 동의하셔야 합니다.");
      return false;
    }

    if (!salesRules) {
      showAlert("판매규정준수 약관에 동의하셔야 합니다.");
      return false;
    }

    return true;
  };

  return { termForm, modal, validateTerms };
};
