import { resetOrderState } from "@store/features/order/orderSlice";
import { logOut, resetNavigationPath } from "@store/features/user/userSlice";
import { useMutation } from "@tanstack/react-query";
import { useAppDispatch } from "app/appStore";
import type { MobileAppInfoPayload } from "entities/user-info";
import { getUserInfoFromAccessToken } from "entities/user-info";
import { useNavigate } from "react-router-dom";
import { instance } from "shared/api";
import { api } from "shared/constants/api";
import { COOKIE_KEYS } from "shared/constants/cookieKeys";
import { ERROR_CODE } from "shared/constants/errorCode";
import { showAlert } from "shared/lib";
import { removeCookie } from "shared/lib/legacyUtils/cookie";
import type { APIResponse } from "shared/types/server";
import { getSignOutPayload } from "../lib/getSignOutPayload";
import { removeLocalDataForSignOut } from "../lib/removeLocalDataForSignOut";

const DEFAULT_LOGOUT_RESPONSE = { logout: false };

export type SignOutPayload = {
  /** 로그아웃 refreshToken, 입력 없는 경우 업데이트 안 함 */
  refreshToken: string;
  /** 로그아웃 loginServiceId, 입력 없는 경우 업데이트 안 함 */
  loginServiceId: string;
} & MobileAppInfoPayload;

export type SignOutResponse = {
  /** logout USER ID */
  id: string;
  /** 로그인 유지 비활성화 ID */
  loginServiceId: string;
  /** 로그아웃 fcmToken */
  fcmToken: string | null;
  /** token, loginServiceId 비활성화 여부 */
  logout: boolean;
  /** fcmToken 의 logged_id 초기화 여부 */
  androidLogout: boolean;
};

export const signOutApi = {
  /**
   * mobile-회원 로그아웃
   * @see https://sbridge.atlassian.net/wiki/x/DgCZEg
   */
  signout: async (payload: SignOutPayload) => {
    const userInfo = getUserInfoFromAccessToken();
    if (!userInfo?.id) {
      removeLocalDataForSignOut();
      removeCookie(COOKIE_KEYS.TEMP_CART_ID, { path: "/" });
      return DEFAULT_LOGOUT_RESPONSE;
    }

    try {
      const {
        data: { data: response },
      } = await instance.post<APIResponse<SignOutResponse>>(
        api.users.logout,
        payload
      );
      return response;
    } catch (error: any) {
      if (String(error).includes(ERROR_CODE.common.DuplicateRequest)) {
        return DEFAULT_LOGOUT_RESPONSE;
      }

      showAlert("로그인 페이지로 이동합니다.").then(({ isConfirmed }) => {
        if (isConfirmed) {
          window.location.href = "/signin";
        }
      });
      return DEFAULT_LOGOUT_RESPONSE;
    } finally {
      removeLocalDataForSignOut();
      removeCookie(COOKIE_KEYS.TEMP_CART_ID, { path: "/" });
    }
  },
};

// 로그아웃
export function useSignOut(to?: string) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: () => {
      return signOutApi.signout(getSignOutPayload());
    },
    onSettled: () => {
      dispatch(logOut());
      dispatch(resetNavigationPath());
      dispatch(resetOrderState());
    },
    onSuccess: () => {
      if (to) navigate(to);
    },
  });
}
