import type { CartItemDto } from "entities/cart/api/type";
import { formatPrice } from "shared/lib";
import Shared from "shared/ui";

type Props = Pick<
  CartItemDto,
  "deliveryDay" | "deliveryFee" | "deliveryOverseas" | "deliveryType"
>;

// TODO: Product으로 이동
export const CartProductShipping = ({
  deliveryDay,
  deliveryFee,
  deliveryOverseas,
  deliveryType,
}: Props) => {
  const shippingFee =
    deliveryType === "배송비"
      ? `${deliveryType} ${formatPrice(deliveryFee, { suffix: "원" })}`
      : deliveryType;

  const overseas = deliveryOverseas === "해외배송" ? "해외배송 /" : "";

  return (
    <Shared.HStack className="graytext text12">
      <span>{shippingFee}</span>
      <span>
        {overseas} {deliveryDay}일 후 도착
      </span>
    </Shared.HStack>
  );
};
