import { useAppSelector } from "app/appStore";
import type { MouseEvent } from "react";
import type { To } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { BaseButton } from "../Button/BaseButton";
import type { ButtonBaseProps } from "../Button/types";
import { Icon } from "./Icon";

type Props = ButtonBaseProps & {
  route?: To | number;
};

export const BackIcon = ({ route = -1, onClick, ...rest }: Props) => {
  const { navigationPath } = useAppSelector(state => state.user);
  const navigate = useNavigate();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    const isGoHome = ["", navigationPath.to].some(
      path => navigationPath.from === path
    );
    navigate(isGoHome ? "/" : (route as To));
    onClick?.(event);
  };

  return (
    <BaseButton onClick={handleClick} {...rest}>
      <Icon name="leftArrow" size={20} />
    </BaseButton>
  );
};
