import { useMutation } from "@tanstack/react-query";
import { instance } from "shared/api";
import { api } from "shared/constants/api";
import { showAlert, type TypedFormData } from "shared/lib";
import type {
  APIAxiosError,
  APIResponse,
  ResponseData,
} from "shared/types/server";
import type { FileUploadFormData, FileUploadType, UploadedFile } from "./types";
import { FILE_UPLOAD_TYPE } from "./types";

interface FileUploadPayload {
  type: FileUploadType;
  formData: TypedFormData<FileUploadFormData>;
}

interface FileUploadResponse extends ResponseData {
  fileList: UploadedFile[];
}

const uploadFileApi = {
  /**
   * 파일 업로드
   * @see https://sbridge.atlassian.net/wiki/x/TwGtD
   */
  upload: async ({ type, formData }: FileUploadPayload) => {
    const {
      data: { data: response },
    } = await instance.post<APIResponse<FileUploadResponse>>(
      `${api.file.upload}/${FILE_UPLOAD_TYPE[type]}`,
      formData
    );

    return response;
  },
};

export function useFileUpload() {
  return useMutation({
    mutationFn: (payload: FileUploadPayload) => uploadFileApi.upload(payload),
    onError: (error: APIAxiosError) => {
      const errorCode = error.response?.data?.data?.errorCode;
      const errorMessage = error.response?.data?.message;
      showAlert.error({
        errorConditions: [
          "ERR_000_001_001",
          "ERR_000_001_002",
          "ERR_000_001_003",
          "ERR_000_001_004",
          "ERR_000_001_005",
          "ERR_000_001_006",
          "ERR_000_001_007",
          "ERR_000_001_008",
          "ERR_000_001_009",
        ],
        errorCode,
        errorMessage,
      });
    },
  });
}
