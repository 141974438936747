import type { TouchEventHandler } from "react";
import { useCallback, useEffect, useRef, useState } from "react";

export const useBottomSheetTouchEvent = (
  onClose: VoidFunction,
  config: { offset: number; canSwipe: boolean }
) => {
  const sheetRef = useRef<HTMLDivElement>(null);

  const [startY, setStartY] = useState(0);
  const [translateY, setTranslateY] = useState(0);

  const resetTranslate = useCallback(() => setTranslateY(0), []);

  const handleTouchStart: TouchEventHandler = e => {
    if (!config.canSwipe) return;
    setStartY(e.touches[0].clientY);
  };

  const handleTouchMove: TouchEventHandler = e => {
    if (!config.canSwipe) return;
    const currentY = e.touches[0].clientY;
    const diffY = currentY - startY;

    if (diffY > 0) {
      setTranslateY(diffY);
    }
  };

  const handleTouchEnd = () => {
    if (!config.canSwipe) return;
    if (translateY > config.offset) {
      onClose();
    } else {
      setTranslateY(0);
    }
  };

  const applyTranslation = useCallback(() => {
    if (sheetRef.current) {
      sheetRef.current.style.transform = `translateY(${translateY}px)`;
    }
  }, [translateY]);

  const removeTranslation = useCallback(() => {
    if (sheetRef.current) {
      sheetRef.current.style.transform = `translateY(0px)`;
    }
  }, []);

  useEffect(() => {
    applyTranslation();
  }, [applyTranslation, removeTranslation, translateY]);

  return {
    sheetRef,
    handlers: {
      onTouchStart: handleTouchStart,
      onTouchMove: handleTouchMove,
      onTouchEnd: handleTouchEnd,
    },
    resetTranslate,
  };
};
