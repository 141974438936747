/* eslint-disable no-param-reassign */
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { OrderState } from "@store/Store.types";

const initialState: OrderState = {
  orderProductList: [],
  orderPrice: { totalProductPrice: undefined, deliveryFee: undefined },
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setOrderProductList(
      state,
      action: PayloadAction<OrderState["orderProductList"]>
    ) {
      state.orderProductList = action.payload;
    },
    setOrderPrice(state, action: PayloadAction<OrderState["orderPrice"]>) {
      state.orderPrice = {
        totalProductPrice: action.payload.totalProductPrice,
        deliveryFee: action.payload.deliveryFee,
      };
    },
    resetOrderState(state) {
      Object.assign(state, initialState);
    },
  },
});

export default orderSlice;
export const { setOrderProductList, setOrderPrice, resetOrderState } =
  orderSlice.actions;
