import { useMemo } from "react";
import type { DataListProps } from "shared/ui";
import Shared from "shared/ui";
import { useSellerInfo } from "../../../api/sellerInfoApi";
import { PanelTitle } from "./PanelTitle";
import { PanelValue } from "./PanelValue";
import type { UpCommercialDataList } from "./types";

const BASE_ROUTE = "/myshop/sales/additional-services";

export const UPCommercialPanel = () => {
  const { data: sellerInfo, isLoading, isError, refetch } = useSellerInfo();

  const formattedData = useMemo<UpCommercialDataList>(() => {
    return [
      {
        id: "1",
        title: "상품 등록 개수 추가 서비스",
        value: `${sellerInfo?.enrolledProductNum} / ${
          sellerInfo?.powerSeller ? "무제한" : sellerInfo?.canEnrollProductNum
        }`,
        to: `${BASE_ROUTE}/products-register-unlimit`,
        isPink: true,
      },
      {
        id: "2",
        title: "파워셀러 서비스 (1/3/6/12개월)",
        value: sellerInfo?.powerSeller ? "사용중" : "미사용",
        to: `${BASE_ROUTE}/power-seller`,
        isPink: Boolean(sellerInfo?.powerSeller),
      },
      {
        id: "3",
        title: "자동 등록일 초기화 서비스",
        value: sellerInfo?.autoResetService ? "사용중" : "미사용",
        to: `${BASE_ROUTE}/reset-regdate`,
        isPink: Boolean(sellerInfo?.autoResetService),
      },
      {
        id: "4",
        title: "프리미엄 상품 서비스",
        value: `${sellerInfo?.countPremiumProduct} / ${sellerInfo?.countPremiumCoupon}`,
        to: `${BASE_ROUTE}/premium-products`,
        isPink: true,
      },
      {
        id: "5",
        title: "미니샵 서비스",
        value: sellerInfo?.openMiniShop ? "사용중" : "미사용",
        to: `${BASE_ROUTE}/minishop`,
        isPink: Boolean(sellerInfo?.openMiniShop),
      },
    ];
  }, [sellerInfo]);

  const dataToShow = useMemo<DataListProps["data"]>(
    () =>
      formattedData.map(({ id, isPink, title, to, value }) => ({
        id,
        title: <PanelTitle title={title} to={to} />,
        value: (
          <PanelValue isPink={isPink} to={to}>
            {value}
          </PanelValue>
        ),
      })),
    [formattedData]
  );

  return (
    <div style={{ position: "relative" }}>
      <Shared.DataList
        data={dataToShow}
        dtStyle={null}
        justify="between"
        rowGap={16}
        status={{
          isError,
          isLoading,
        }}
      />

      {isError && <Shared.Overlay.Error retryFunction={refetch} />}
    </div>
  );
};
