import { useAppSelector } from "app/appStore";
import { useUnreadPushQuery } from "entities/push-notification";
import { scrollToTop } from "shared/lib";
import Shared from "shared/ui";
import styles from "./PushLinkIcon.module.scss";

type Props = {
  size?: number;
};

export const PushLinkIcon = ({ size = 40 }: Props) => {
  const { isLoggedIn } = useAppSelector(state => state.user);

  const { data } = useUnreadPushQuery();
  const hasNewAlert = data?.existUnReadPush;

  if (!isLoggedIn) return null;

  return (
    <Shared.LinkButton
      to="/notify"
      onClick={() => {
        scrollToTop();
      }}
      style={{ height: size, padding: 0 }}
      className={styles.PushLinkIcon}
      styleVariant="none"
    >
      <Shared.Icon name="bell" size={size} />
      {hasNewAlert && <span className={styles.alertBadge} />}
    </Shared.LinkButton>
  );
};
