import classNames from "classnames";
import { Link } from "react-router-dom";
import Shared from "shared/ui";
import styles from "./ProductModules.module.scss";

type Props = {
  deliveryTransDate: string;
  to: string;
  className?: string;
};

export const ProductDeliveryTracking = ({
  deliveryTransDate,
  to,
  className,
}: Props) => {
  return (
    <div className={classNames(styles.ProductDeliveryTracking, className)}>
      <p className="graytext">
        {deliveryTransDate && <span>· {deliveryTransDate} 출고 예정</span>}
      </p>
      <Link to={to}>
        <span>배송조회</span>
        <Shared.Icon name="chevronSmallRight" size={20} />
      </Link>
    </div>
  );
};
