import { useQuery } from "@tanstack/react-query";
import { useAppSelector } from "app/appStore";
import { instance } from "shared/api";
import { api } from "shared/constants/api";
import type { APIResponse, ResponseData } from "shared/types/server";
import { cartQueryKey } from "./cartQueryKey";
import type { CartItemDto } from "./type";

export type CartResponse = ResponseData & {
  cartList: CartItemDto[];
};

const cartApi = {
  /**
   * 장바구니 조회
   * @see https://sbridge.atlassian.net/wiki/x/AYAMC
   */
  list: async () => {
    const {
      data: { data: response },
    } = await instance.get<APIResponse<CartResponse>>(api.basket.basket);

    return response;
  },
};

export function useCartList() {
  const { userId } = useAppSelector(state => state.user);

  return useQuery({
    queryKey: cartQueryKey.list(userId),
    queryFn: () => cartApi.list(),
    select: data => data?.cartList,
  });
}
