import classNames from "classnames";
import type { ReactNode } from "react";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useLockBodyScroll } from "shared/hooks";
import Shared from "..";
import styles from "./BottomSheet.module.scss";
import { useBottomSheetTouchEvent } from "./useBottomSheetTouchEvent";

export type BottomSheetProps = {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  canSwipe?: boolean;
  hasNavigation?: boolean;
};

export const BottomSheet = ({
  className,
  isOpen,
  onClose,
  children,
  canSwipe = true,
  hasNavigation = true,
}: BottomSheetProps) => {
  const [modalStatus, setModalStatus] = useState<"open" | "closing" | "closed">(
    isOpen ? "open" : "closed"
  );

  useLockBodyScroll(modalStatus !== "closed");

  const { handlers, resetTranslate, sheetRef } = useBottomSheetTouchEvent(
    onClose,
    { offset: 50, canSwipe }
  );

  useEffect(() => {
    if (isOpen) {
      setModalStatus("open");
    } else {
      setModalStatus("closing");

      setTimeout(() => {
        setModalStatus("closed");
        resetTranslate();
      }, 300);
    }
  }, [isOpen, resetTranslate]);

  return createPortal(
    <>
      {modalStatus === "open" && (
        <Shared.Overlay
          onClick={onClose}
          style={{ zIndex: "var(--z-index-bottom-sheet)" }}
          animation="fadeIn"
        />
      )}
      <div
        className={classNames(
          styles.BottomSheet,
          styles[`modal-${modalStatus}`],
          className
        )}
        ref={sheetRef}
        role="button"
        onClick={e => {
          e.stopPropagation();
        }}
        tabIndex={0}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onKeyUp={() => {}}
        {...handlers}
      >
        <Shared.HStack
          className={styles.sheetHeader}
          justify="center"
          align="center"
          role="button"
          onClick={() => onClose()}
        >
          <Shared.Icon name="sheetHandle" />
        </Shared.HStack>

        <div
          className={classNames(
            styles.sheetContent,
            hasNavigation && styles.hasNavigation
          )}
        >
          {children}
        </div>
      </div>
    </>,
    document.body
  );
};
