import {
  useMemo,
  type CSSProperties,
  type HTMLAttributes,
  type ReactNode,
} from "react";
import { Skeleton } from "../Skeleton/Skeleton";
import { HStack, VStack } from "../Stack/Stack";
import type { HStackProps } from "../Stack/types";

export type DataListProps = HTMLAttributes<HTMLDListElement> &
  Pick<HStackProps, "justify" | "align"> & {
    data: {
      id?: string;
      title: ReactNode;
      value: ReactNode;
    }[];

    dtWidth?: CSSProperties["width"];
    dtStyle?: CSSProperties | null;
    rowGap?: CSSProperties["gap"];
    colGap?: CSSProperties["gap"];

    status?: {
      isLoading?: boolean;
      isError?: boolean;
    };

    LoadingSkeleton?: ReactNode;
  };

export const DataList = ({
  data,
  dtWidth,
  dtStyle = { color: "var(--color-gray-dark)" },
  rowGap,
  colGap,
  justify,
  align = "center",
  status,
  LoadingSkeleton = <Skeleton width="50px" />,
  ...rest
}: DataListProps) => {
  const dataToShow = useMemo<DataListProps["data"]>(() => {
    if (status?.isLoading) {
      return data.map(props => ({
        ...props,
        value: LoadingSkeleton,
      }));
    }

    if (status?.isError) {
      return data.map(props => ({
        ...props,
        value: "",
      }));
    }

    return data;
  }, [data, status?.isError, status?.isLoading, LoadingSkeleton]);

  const dtStyles = dtStyle ?? {};

  return (
    <dl {...rest}>
      <VStack gap={rowGap}>
        {dataToShow?.map(({ title, value, id }) => (
          <HStack
            key={`${title}-${id}`}
            gap={colGap}
            align={align}
            justify={justify}
          >
            <dt style={{ width: dtWidth, flexShrink: 0, ...dtStyles }}>
              {title}
            </dt>
            <dd>{value}</dd>
          </HStack>
        ))}
      </VStack>
    </dl>
  );
};
