import { Link } from "react-router-dom";
import "./style/ContainedBtn.scss";

export const LegacyContainedBtn = (props: {
  text: any;
  type: string;
  link?: any;
  customData?: any;
  onClick?: any;
  submit?: true;
  disabled: boolean;
  onTouchEnd?: any;
  button?: boolean;
}) => {
  const {
    text,
    type,
    link,
    customData,
    onClick,
    submit,
    disabled,
    onTouchEnd,
    button,
  } = props;
  const btnType = ["black", "disabled", "blue"].includes(type)
    ? type
    : "default";
  return (
    <>
      {submit ? (
        <input
          type="submit"
          value={text}
          style={{ width: "100%" }}
          className={["ContainedBtn", `ContainedBtn_${btnType}`].join(" ")}
          disabled={disabled}
        />
      ) : link ? (
        <Link
          to={link || "#"}
          state={customData?.savedOrderId ? { ...customData } : {}}
        >
          <button
            className={["ContainedBtn", `ContainedBtn_${btnType}`].join(" ")}
            onClick={onClick}
            disabled={disabled}
          >
            {text}
          </button>
        </Link>
      ) : onTouchEnd ? (
        <button
          type="submit"
          className={
            disabled === true
              ? "ContainedBtn ContainedBtn_disabled"
              : "ContainedBtn ContainedBtn_default"
          }
          disabled={disabled}
          // onTouchEnd={onTouchEnd}
        >
          {text}
        </button>
      ) : button ? (
        // </Link>
        <button
          type="button"
          className={
            disabled === true
              ? "ContainedBtn ContainedBtn_disabled"
              : ["ContainedBtn", `ContainedBtn_${btnType}`].join(" ")
          }
          onClick={onClick}
          disabled={disabled}
        >
          {text}
        </button>
      ) : (
        <button
          className={
            disabled === true
              ? "ContainedBtn ContainedBtn_disabled"
              : ["ContainedBtn", `ContainedBtn_${btnType}`].join(" ")
          }
          onClick={onClick}
          disabled={disabled}
        >
          {text}
        </button>
      )}
    </>
  );
};

LegacyContainedBtn.defaultProps = {
  type: "default",
  link: null,
  onClick: null,
  submit: false,
  disabled: false,
};
