import classNames from "classnames";
import type { CSSProperties, HTMLAttributes } from "react";
import { createElement } from "react";
import { Link } from "react-router-dom";
import { Icon } from "../Icon/Icon";
import { HStack } from "../Stack/Stack";
import styles from "./Title.module.scss";

export type TitleProps = Omit<HTMLAttributes<HTMLDivElement>, "title"> & {
  title: string;
  order?: 1 | 2 | 3 | 4 | 5 | 6;
  count?: number;
  size?: "s" | "m" | "l";

  moreLink?: string;
  hasBottomBorder?: boolean;
  textAlign?: CSSProperties["textAlign"];
  noPadding?: boolean;
};

export const Title = ({
  title,
  count,
  order = 2,
  moreLink,
  hasBottomBorder,
  className,
  textAlign,
  noPadding,
  size = "s",
  ...rest
}: TitleProps) => {
  const Element = `h${order}`;

  return (
    <div
      className={classNames(
        styles.Container,
        styles[`size-${size}`],
        hasBottomBorder && styles.hasBottomBorder,
        noPadding && styles.noPadding,
        className
      )}
      {...rest}
    >
      <HStack justify="between" align="center">
        <HStack align="center" gap={6}>
          {createElement(
            Element,
            { className: styles.Title, style: { textAlign, width: "100%" } },
            title
          )}

          {count && <span className={styles.count}>{count}</span>}
        </HStack>

        {moreLink && (
          <Link to={moreLink} className={styles.link}>
            <HStack align="center" gap={0} className={styles.moreLink}>
              <span className={styles.moreLinkText}>전체보기</span>
              <Icon name="chevronSmallRight" size={20} />
            </HStack>
          </Link>
        )}
      </HStack>
    </div>
  );
};
