import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import type { CSSProperties, HTMLAttributes } from "react";
import Shared from "..";

type Props = HTMLAttributes<HTMLDivElement> & {
  id: string;
  isDragOverlay?: boolean;
  isDragged?: boolean;
};

const itemStyle = ({
  isDragOverlay,
  isDragged,
}: Pick<Props, "isDragOverlay" | "isDragged">): CSSProperties => {
  const opacity = () => {
    if (isDragOverlay) return 0.5;
    if (isDragged) return 0.1;
    return 1;
  };

  return {
    transform: isDragOverlay ? "scale(1.3)" : "scale(1)",
    opacity: opacity(),
    transition: "all 0.1s",
  };
};

export const DraggableItem = ({
  id,
  isDragOverlay,
  isDragged,
  children,
  ...rest
}: Props) => {
  const { attributes, listeners, setNodeRef, transform, transition, index } =
    useSortable({ id });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      style={{ touchAction: "none", ...style }}
      ref={setNodeRef}
      {...rest}
      {...attributes}
      {...listeners}
    >
      <div style={itemStyle({ isDragged, isDragOverlay })}>
        <Shared.VStack align="center">
          <div style={{ width: "100%" }}>{children}</div>
          <Shared.Icon name="dragHandle" color="#bbb" />
        </Shared.VStack>
      </div>
    </div>
  );
};
