import classNames from "classnames";
import styles from "./ProductModules.module.scss";

type Props = {
  className?: string;
  statusText: string;
};

export const ProductStatus = ({ className, statusText }: Props) => {
  return (
    <span className={classNames(styles.Status, className)}>{statusText}</span>
  );
};
