/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from "classnames";
import type { HTMLAttributes } from "react";
import { createPortal } from "react-dom";
import { useLockBodyScroll } from "shared/hooks/useLockBodyScroll/useLockBodyScroll";
import Shared from "..";
import type { OverlayBaseProps } from "../Overlay/OverlayBase";
import styles from "./Modal.module.scss";

export type ModalBaseProps = HTMLAttributes<HTMLDivElement> & {
  isOpen: boolean;
  onClose: () => void;
  outsideClick?: boolean;
  overlayProps?: OverlayBaseProps;
};

export const ModalBase = ({
  className,
  isOpen,
  onClose,
  children,
  outsideClick = true,
  overlayProps,
  ...rest
}: ModalBaseProps) => {
  useLockBodyScroll(isOpen);

  if (!isOpen) return null;

  return createPortal(
    <Shared.Overlay
      onClick={() => {
        if (outsideClick) {
          onClose();
        }
      }}
      {...overlayProps}
    >
      <div
        className={classNames(styles.ModalBase, className)}
        role="button"
        tabIndex={0}
        onClick={e => e.stopPropagation()}
        {...rest}
      >
        {children}
      </div>
    </Shared.Overlay>,
    document.body
  );
};
