import { ModalPostCode } from "@components/zLegacyLayouts/Modals/ModalPostCode";
import { useState } from "react";
import type {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import "./PostInput.scss";

export const PostInput = ({
  register,
  errors,
  isSubmitted,
  setValue,
  getValues,
}: {
  register: UseFormRegister<any>;
  errors: any;
  isSubmitted: boolean;
  setValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
  getValues: UseFormGetValues<any>;
}) => {
  const [openPostModal, setOpenPostModal] = useState(false);
  const [address, setAddress] = useState("");
  const [zoneCode, setZoneCode] = useState("");
  const [jibunAddress, setJibunAddress] = useState("");

  return (
    <div id="PostInput">
      <span>주소</span>
      <div className="address_wrapper" onClick={() => setOpenPostModal(true)}>
        <input
          type="number"
          readOnly
          placeholder="우편번호 찾기"
          // value={zoneCode}
          {...register("postcode")}
        />
        <img src="/assets/icons/icon_search.svg" alt="돋보기 아이콘" />

        {isSubmitted && getValues("postcode") === "" && (
          <p className="validationErrorMessage">우편번호를 입력해주세요.</p>
        )}
      </div>
      <input
        type="text"
        readOnly
        placeholder="도로명주소"
        // value={address}
        {...register("addressRoad")}
      />
      <input
        type="hidden"
        readOnly
        placeholder="지번주소"
        // value={jibunAddress}
        {...register("addressLegacy")}
      />

      {isSubmitted &&
        getValues("addressRoad") === "" &&
        getValues("addressLegacy") === "" && (
          <p className="validationErrorMessage">주소를 입력해주세요.</p>
        )}
      <input
        type="text"
        placeholder="상세주소"
        {...register("addressDetail", {
          required: "상세주소를 입력해주세요.",
        })}
      />
      {errors.addressDetail && (
        <p className="validationErrorMessage">{errors.addressDetail.message}</p>
      )}
      {openPostModal ? (
        <ModalPostCode
          setClose={setOpenPostModal}
          setAddress={setAddress}
          setZoneCode={setZoneCode}
          setJibunAddress={setJibunAddress}
          setValue={setValue}
        />
      ) : (
        ""
      )}
    </div>
  );
};

PostInput.defaultProps = {
  register: null,
  errors: null,
  watch: null,
  isSubmitted: null,
  setValue: null,
  getValues: null,
};
