import classNames from "classnames";
import { useCallback } from "react";
import { useBoolean } from "shared/hooks";
import { useScrollListener } from "shared/hooks/useScrollEventListener/useScrollListener";
import { scrollToTop } from "shared/lib";
import { ActionButton } from "./ActionButton";
import styles from "./ScrollUpButton.module.scss";

type Props = {
  hasNavigation?: boolean;
};

export const ScrollUpButton = ({ hasNavigation }: Props) => {
  const [visible, setVisible] = useBoolean();

  const onScroll = useCallback(() => {
    const visible = window.scrollY > window.innerHeight / 6;
    setVisible.setState(visible);
  }, [setVisible]);

  useScrollListener(onScroll);

  return (
    <div
      className={classNames(
        styles.ScrollUpButton,
        hasNavigation && styles.hasNavigation
      )}
    >
      {visible && (
        <ActionButton
          icon="arrowUp"
          size="l"
          iconProps={{ size: 16 }}
          rounded
          onClick={() => {
            scrollToTop("smooth");
          }}
        />
      )}
    </div>
  );
};
