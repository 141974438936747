import { COOKIE_KEYS } from "shared/constants/cookieKeys";
import { getCookie } from "./legacyUtils/cookie";

const TMP_CART_ID_LENGTH = 14;

export function generateTmpCartId() {
  // OP+ 시간 10자리 + 난수 두자리(10~99)
  let cartId = `OP${Math.floor(Date.now() / 1000)}${
    Math.floor(Math.random() * 90) + 10
  }`;

  while (cartId?.length !== TMP_CART_ID_LENGTH) {
    cartId = `OP${Math.floor(Date.now() / 1000)}${
      Math.floor(Math.random() * 90) + 10
    }`;
  }

  return cartId;
}

export function validateTmpCartId() {
  const tmpCartId: string = getCookie(COOKIE_KEYS.TEMP_CART_ID);

  return (
    Boolean(tmpCartId) &&
    tmpCartId.startsWith("OP") &&
    tmpCartId.length >= TMP_CART_ID_LENGTH
  );
}
