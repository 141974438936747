import { useQuery } from "@tanstack/react-query";
import { useAppSelector } from "app/appStore";
import { instance } from "shared/api";
import { api } from "shared/constants/api";
import { baseQueryKeys } from "shared/constants/baseQueryKeys";
import type { APIResponse, ResponseData } from "shared/types/server";
import type { UserId } from "shared/types/User.types";
import type { UserInfo } from "./types";

type UserInfoResponse = ResponseData & {
  memberInfo: UserInfo;
};

const queryKey = {
  userInfo: (userId: UserId) =>
    [baseQueryKeys.user, "user-info", userId] as const,
} as const;

const userInfoApi = {
  /**
   * 내 정보 보기
   * @see https://sbridge.atlassian.net/wiki/x/-QKtD
   */
  userInfo: async () => {
    const {
      data: { data: response },
    } = await instance.get<APIResponse<UserInfoResponse>>(api.users.info);

    return response;
  },
};

/**
 * 내 정보 보기
 */
export function useUserInfoQuery() {
  const { userId, isLoggedIn } = useAppSelector(state => state.user);

  return useQuery({
    queryKey: queryKey.userInfo(userId),
    queryFn: () => userInfoApi.userInfo(),
    select: data => data.memberInfo,
    enabled: isLoggedIn,
  });
}
