import classNames from "classnames";
import { useCallback, useState, type ReactNode } from "react";
import { useDebounce } from "shared/hooks/useDebounce/useDebounce";
import { useScrollEndListener } from "shared/hooks/useScrollEventListener/useScrollEndListener";
import Shared from "shared/ui";
import styles from "./StickyPanel.module.scss";

const getScrollTop = () => {
  const scrollTop = window.scrollY || document.documentElement.scrollTop;
  return scrollTop <= 0 ? 0 : scrollTop;
};

type Props = {
  notification?: string;

  foldedHeight: number;
  foldedChildren?: ReactNode;
  normalChildren?: ReactNode;

  offset?: number;
};

export const StickyPanel = ({
  notification,
  foldedChildren,
  normalChildren,
  foldedHeight,
  offset = 100,
}: Props) => {
  const [startScrollTop, setStartScrollTop] = useState(0);
  const [isHidden, setIsHidden] = useState(false);

  /** 스크롤이 종료되면 startScroll을 세팅합니다. */
  const handleScrollEnd = useCallback(() => {
    setStartScrollTop(getScrollTop());
  }, []);

  /** 스크롤 UP: 스크롤이 이동한 거리가 offset보다 크면 보이고, 작으면 이전값을 사용합니다. */
  const handleScrollUp = useDebounce((scrollGap: number) => {
    setIsHidden(prev => (Math.abs(scrollGap) > offset ? false : prev));
  }, 100);

  /** 스크롤 DOWN: 스크롤이 이동한 거리가 offset보다 크면 숨기고, 작으면 이전값을 사용합니다. */
  const handleScrollDown = useCallback(
    (scrollGap: number) => {
      setIsHidden(prev => (Math.abs(scrollGap) > offset ? true : prev));
    },
    [offset]
  );

  /**
   * 스크롤 시 호출되는 함수
   */
  const handleScroll = useCallback(() => {
    const currentScrollTop = getScrollTop();
    const scrollGap = startScrollTop - currentScrollTop;

    if (currentScrollTop < 100) {
      setIsHidden(false);
      return;
    }

    scrollGap > 0 ? handleScrollUp(scrollGap) : handleScrollDown(scrollGap);
  }, [handleScrollDown, handleScrollUp, startScrollTop]);

  useScrollEndListener(handleScroll, handleScrollEnd);

  return (
    <div
      className={classNames(
        styles.StickyPanel,
        isHidden && styles["is-hidden"]
      )}
    >
      {notification && (
        <div className={classNames(styles.infoText)}>
          <Shared.BaseButton
            className="graytext text13"
            onClick={() => {
              setIsHidden(false);
            }}
          >
            {notification}
          </Shared.BaseButton>
        </div>
      )}

      <div className={styles.foldedChildren} style={{ height: foldedHeight }}>
        {foldedChildren}
      </div>

      <div className={styles.normalChildren}>{normalChildren}</div>
    </div>
  );
};
