import { Link } from "react-router-dom";
import type { PanelTitleProps } from "./types";

export const PanelTitle = ({ title, to }: PanelTitleProps) => {
  return (
    <Link to={to} className="font_400">
      {title}
    </Link>
  );
};
