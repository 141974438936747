import classNames from "classnames";
import { forwardRef, type CSSProperties } from "react";
import Shared from "..";
import type { ButtonBaseProps } from "../Button/types";
import { HStack } from "../Stack/Stack";
import type { InputProps } from "./Input";
import { Input } from "./Input";
import styles from "./SearchInput.module.scss";

export type SearchInputProps = InputProps & {
  colorVariants?: "white" | "gray";
  buttonType?: ButtonBaseProps["type"];
};

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  (
    {
      className,
      style,
      colorVariants = "white",
      buttonType = "button",
      ...rest
    },
    ref
  ) => {
    const inputStyle: CSSProperties = {
      pointerEvents: buttonType !== "submit" ? "none" : "auto",
    };

    return (
      <HStack
        className={classNames(
          styles.SearchInput,
          styles[`color-${colorVariants}`],
          rest.error && styles.error,
          className
        )}
        style={style}
      >
        <Input type="search" ref={ref} {...rest} />

        <Shared.ActionButton
          className={styles.icon}
          type={buttonType}
          style={inputStyle}
          icon="search"
          iconProps={{ size: 23, color: "var(--color-black)" }}
          styleVariant="none"
        />
      </HStack>
    );
  }
);
