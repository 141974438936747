import type { CartOption } from "entities/cart";

function hasProductOption(optionList: CartOption[] | undefined): boolean;
function hasProductOption(optionList: string | undefined): boolean;
function hasProductOption(option: CartOption[] | string | undefined): boolean {
  if (option === undefined) return false;

  if (typeof option === "string") {
    return !option?.includes("없음");
  }

  return Boolean(option.length) && option[0]?.optionName !== "없음 없음";
}

export default hasProductOption;
