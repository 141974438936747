import type { CartItemDto } from "entities/cart/api/type";
import { ProductCard } from "entities/product";
import { Link } from "react-router-dom";
import Shared from "shared/ui";
import { CartProductShipping } from "./CartProductShipping";

type Props = Pick<
  CartItemDto,
  | "productId"
  | "image"
  | "productName"
  | "deliveryDay"
  | "deliveryFee"
  | "deliveryOverseas"
  | "deliveryType"
> & {
  disabled?: boolean;
};

export const CartProduct = ({
  productId,
  image,
  productName,

  deliveryDay,
  deliveryFee,
  deliveryOverseas,
  deliveryType,

  disabled,
}: Props) => {
  return (
    <Link to={`/products/detail/${productId}`}>
      <Shared.HStack gap={10} style={{ opacity: disabled ? 0.4 : 1 }}>
        <ProductCard.Image imageUrl={image} />
        <Shared.VStack gap={3}>
          <ProductCard.Name productName={productName} />
          <CartProductShipping
            deliveryDay={deliveryDay}
            deliveryFee={deliveryFee}
            deliveryOverseas={deliveryOverseas}
            deliveryType={deliveryType}
          />
        </Shared.VStack>
      </Shared.HStack>
    </Link>
  );
};
