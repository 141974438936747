import classNames from "classnames";
import type { CSSProperties, HTMLAttributes, ReactNode } from "react";
import styles from "./Message.module.scss";

export type MessageProps = HTMLAttributes<HTMLDivElement> & {
  text?: string;
  colorVariants?: "red" | "blue" | "error" | "gray" | "none";
  size?: "s" | "m" | "l";
  className?: string;
  children?: ReactNode;
  textAlign?: CSSProperties["textAlign"];
};

export const Message = ({
  text,
  className,
  colorVariants = "none",
  size = "s",
  textAlign,
  style,
  children,
  ...rest
}: MessageProps) => {
  return (
    <div
      className={classNames(
        styles.Message,
        styles[`color-${colorVariants}`],
        styles[`size-${size}`],
        className
      )}
      style={{
        textAlign,
        ...style,
      }}
      {...rest}
    >
      {text}
      {children}
    </div>
  );
};
