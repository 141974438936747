import type { PushListItem } from "entities/push-notification/api/types";
import moment from "moment";
import "moment/locale/ko";
import Shared from "shared/ui";
import styles from "./PushNotifyMetaData.module.scss";

type Props = Pick<PushListItem, "sendDate" | "category">;

export const PushNotifyMetaData = ({ category, sendDate }: Props) => {
  return (
    <Shared.HStack
      className={styles.PushNotifyMetaData}
      align="center"
      gap={9}
      fullWidth
    >
      <Shared.HStack className={styles.thumb} align="center" justify="center">
        <span className={styles.icon}>
          <Shared.Icon name="logo" size={21} />
        </span>
      </Shared.HStack>

      <Shared.HStack>
        <b className={styles.category}>{category}</b>
        {sendDate && (
          <span className={styles.date}>
            · {moment(sendDate).format("MM월 DD일 A h:mm")}
          </span>
        )}
      </Shared.HStack>
    </Shared.HStack>
  );
};

const Skeleton = () => {
  return (
    <Shared.HStack
      className={styles.PushNotifyMetaData}
      align="center"
      gap={9}
      fullWidth
    >
      <Shared.HStack align="center" justify="center">
        <Shared.Skeleton
          width={23}
          height={23}
          style={{ borderRadius: "50%" }}
        />
      </Shared.HStack>

      <Shared.HStack align="center">
        <Shared.Skeleton width={120} />
      </Shared.HStack>
    </Shared.HStack>
  );
};

PushNotifyMetaData.Skeleton = Skeleton;
