import { removeKeepLoginCookie } from "features/auth/keep-login";
import { COOKIE_KEYS } from "shared/constants/cookieKeys";
import { removeCookie } from "shared/lib/legacyUtils/cookie";

export function removeLocalDataForSignOut() {
  localStorage.removeItem("userId");
  removeCookie(COOKIE_KEYS.ACCESS_TOKEN, { path: "/" });
  removeCookie(COOKIE_KEYS.REFRESH_TOKEN, { path: "/" });
  removeKeepLoginCookie();
}
