import type { To } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Button } from "./Button";
import type { ButtonProps } from "./types";

export type LinkButtonProps = ButtonProps & {
  to: To | number;
  disabledNavigate?: boolean;
};

export const LinkButton = ({
  to,
  onClick,
  disabledNavigate,
  ...rest
}: LinkButtonProps) => {
  const navigate = useNavigate();

  return (
    <Button
      onClick={e => {
        onClick?.(e);
        if (!disabledNavigate) {
          navigate(to as To);
        }
      }}
      {...rest}
    />
  );
};
