import { baseQueryKeys } from "shared/constants/baseQueryKeys";
import type { UserId } from "shared/types/User.types";
import type {
  SalesListPayload,
  SalesOrderDetailPayload,
} from "./salesOrderApi";

export const sellerOrderQueryKey = {
  sellerOrder: baseQueryKeys.sellerOrder,

  salesOrderDetail: (payload: SalesOrderDetailPayload) =>
    [...sellerOrderQueryKey.sellerOrder, payload] as const,

  salesOrderList: (userId: UserId, payload: SalesListPayload) =>
    [...sellerOrderQueryKey.sellerOrder, userId, payload] as const,
} as const;
