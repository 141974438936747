/* eslint-disable no-param-reassign */
/* eslint-disable operator-assignment */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchWord: "",
  searchId: 0,
  recentSearchWordList: [],
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    updateSearchWord(state, action) {
      state.searchWord = action.payload;
    },
    updateSearchId(state) {
      state.searchId = state.searchId + 1;
    },
    updateRecentSearchWordList(state, action) {
      if (state.recentSearchWordList?.length > 0) {
        if (
          state.recentSearchWordList.find(
            (ele) => ele.text === action.payload.text
          )
        ) {
          const filterdArr = state.recentSearchWordList.filter(
            (ele) => ele.text !== action.payload.text
          );
          state.recentSearchWordList = [action.payload, ...filterdArr].slice(
            0,
            15
          );
        } else {
          state.recentSearchWordList = [
            action.payload,
            ...state.recentSearchWordList,
          ].slice(0, 15);
        }
      } else {
        state.recentSearchWordList = [action.payload];
      }
    },
    removeRecentSearchWord(state, action) {
      state.recentSearchWordList = state.recentSearchWordList.filter(
        (ele) => ele.id !== action.payload
      );
    },
    removeSearchWord(state) {
      state.searchWord = "";
    },
  },
  extraReducers: {},
});

export default searchSlice;
export const {
  updateSearchWord,
  updateSearchId,
  updateRecentSearchWordList,
  removeRecentSearchWord,
  removeSearchWord,
} = searchSlice.actions;
