import classNames from "classnames";
import { forwardRef, type SelectHTMLAttributes } from "react";
import { Icon } from "../Icon/Icon";
import styles from "./Select.module.scss";

export type SelectOption = {
  value: string | number | undefined;
  name: string;
  disabled?: boolean;
};

type DefaultOption = Pick<SelectOption, "name"> & {
  value?: string | number;
  enable?: boolean;
};

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  defaultOption?: DefaultOption;
  options: SelectOption[] | undefined;
  error?: boolean;
}

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  ({ className, options, defaultOption, disabled, error, ...rest }, ref) => {
    return (
      <div
        className={classNames(
          styles.Select,
          disabled && styles.disabled,
          error && styles.error,
          className
        )}
      >
        <select disabled={disabled} {...rest} ref={ref}>
          {defaultOption && (
            <option
              value={defaultOption.value ?? ""}
              disabled={!defaultOption.enable}
            >
              {defaultOption.name}
            </option>
          )}

          {options?.map(({ value, name, disabled }) => (
            <option value={value} key={`${value}${name}`} disabled={disabled}>
              {name}
            </option>
          ))}
        </select>

        <span className={styles.icon}>
          <Icon name="arrowDown" />
        </span>
      </div>
    );
  }
);
