import classNames from "classnames";
import type { CSSProperties } from "react";
import Icons from "shared/assets/svgs/index";
import styles from "./Icon.module.scss";

export type IconName = keyof typeof Icons;

export interface IconProps {
  className?: string;
  name: IconName;
  size?: number;
  color?: CSSProperties["color"];
}

export const Icon = ({ className, name, size, color }: IconProps) => {
  const IconElement = Icons[name];

  return (
    <IconElement
      className={classNames(styles.Icon, className)}
      style={{ width: size, height: size, color }}
    />
  );
};
