import { useMutation } from "@tanstack/react-query";
import { queryClient } from "app/appQueryClient";
import type { ProductRegisterData } from "entities/product/seller-product";
import { useNavigate } from "react-router-dom";
import { instance } from "shared/api";
import { api } from "shared/constants/api";
import { baseQueryKeys } from "shared/constants/baseQueryKeys";
import { showAlert } from "shared/lib";
import type {
  APIAxiosError,
  APIResponse,
  ResponseData,
} from "shared/types/server";
import { transformProductPayload } from "../model/transformProductPayload";

type AddProductPayload = {
  data: ProductRegisterData;
};

const addProductApi = {
  /**
   * 상품 등록/수정
   * @see https://sbridge.atlassian.net/wiki/x/DoB5B
   */
  addProduct: async ({ data }: AddProductPayload) => {
    const {
      data: { data: response },
    } = await instance.post<APIResponse<ResponseData>>(
      api.product.sellerProducts,
      data
    );

    return response;
  },
};

export function useAddProductMutation() {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: ({ data }: AddProductPayload) =>
      addProductApi.addProduct({
        data: transformProductPayload(data),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([baseQueryKeys.product]);
      showAlert("상품이 등록되었습니다.").then(({ isConfirmed }) => {
        if (isConfirmed) {
          navigate("/myshop/sales/products");
        }
      });
    },
    onError: (error: APIAxiosError) => {
      const errorMessage = error.response?.data?.message;
      errorMessage && showAlert(errorMessage);
    },
  });
}
