import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const FakeListPage = lazy(() =>
  import("pages/help/fake").then(module => ({
    default: module.FakeListPage,
  }))
);
const FakeInquire = lazy(() =>
  import("../../modules/boards/Fake/FakeInquire.view").then(module => ({
    default: module.FakeInquire,
  }))
);
const FakeRegister = lazy(() =>
  import("../../modules/boards/Fake/FakeRegister.view").then(module => ({
    default: module.FakeRegister,
  }))
);

export const HelpFakeRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={null}>
            <FakeListPage />
          </Suspense>
        }
      />
      <Route
        path=":number"
        element={
          <Suspense fallback={null}>
            <FakeInquire />
          </Suspense>
        }
      />
      <Route
        path="register"
        element={
          <Suspense fallback={null}>
            <FakeRegister />
          </Suspense>
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
