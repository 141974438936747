import classNames from "classnames";
import type {
  TableHTMLAttributes,
  TdHTMLAttributes,
  ThHTMLAttributes,
} from "react";
import styles from "./Table.module.scss";

export const Table = ({
  children,
  className,
  ...rest
}: TableHTMLAttributes<HTMLTableElement>) => {
  return (
    <table className={classNames(styles.Table, className)} {...rest}>
      {children}
    </table>
  );
};

const Th = ({
  children,
  className,
  ...rest
}: ThHTMLAttributes<HTMLTableCellElement>) => {
  return (
    <th className={classNames(styles.Th, className)} {...rest}>
      {children}
    </th>
  );
};

const Td = ({
  children,
  className,
  ...rest
}: TdHTMLAttributes<HTMLTableCellElement>) => {
  return (
    <td className={classNames(styles.Td, className)} {...rest}>
      {children}
    </td>
  );
};

Table.Th = Th;
Table.Td = Td;
