import classNames from "classnames";
import type { HTMLAttributes } from "react";
import styles from "./Overlay.module.scss";

export type OverlayBaseProps = HTMLAttributes<HTMLDivElement> & {
  position?: "absolute" | "fixed";
  colorVariants?: "white" | "black" | "white-opacity" | "black-opacity";
  animation?: "none" | "fadeIn" | "fadeIn-slow";
};

export const OverlayBase = ({
  children,
  className,
  position = "fixed",
  colorVariants = "black-opacity",
  animation = "none",
  ...rest
}: OverlayBaseProps) => {
  return (
    <div
      className={classNames(
        styles.Overlay,
        styles[`position-${position}`],
        styles[`color-${colorVariants}`],
        styles[`animation-${animation}`],
        className
      )}
      role="button"
      tabIndex={0}
      {...rest}
    >
      {children}
    </div>
  );
};
