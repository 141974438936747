import classNames from "classnames";
import Shared from "shared/ui";
import type { ImageUploaderPreviewProps } from "../types";
import styles from "./ImageUploaderPreview.module.scss";

export const ImageUploaderPreview = ({
  image,
  size,
  handleDeleteImage: handleImageDelete,
}: ImageUploaderPreviewProps) => {
  const { state, imagePath, id } = image;
  const isLoading =
    state === "loading" || image.imagePath?.includes("blob:htt");
  const isDone = state !== "error" && !isLoading;

  return (
    <div
      className={classNames(
        styles.ItemPreview,
        styles[`state-${state}`],
        isLoading && styles.isLoading
      )}
      style={{ width: size, height: size }}
    >
      {isDone && <Shared.Image aspectRatio={1} src={imagePath} />}

      {isLoading && (
        <div className={styles.loading}>
          <div className={styles.spinner}>
            <Shared.Spinner />
          </div>
          {imagePath && (
            <Shared.Image
              aspectRatio={1}
              className={styles.image}
              src={imagePath}
            />
          )}
        </div>
      )}

      {state === "error" && (
        <div className={styles.error}>
          <Shared.Icon name="notice" size={40} />
          <div className={styles.textContainer}>
            <span>{imagePath}</span>
          </div>
        </div>
      )}

      <Shared.ActionButton
        className={styles.closeIcon}
        icon="close"
        colorVariant={state === "error" ? "error" : "gray"}
        iconProps={{ size: 22 }}
        rounded
        onClick={() => handleImageDelete(id)}
      />
    </div>
  );
};
