import type { JwtPayload } from "jwt-decode";
import jwtDecode from "jwt-decode";
import type { COOKIE_KEYS } from "shared/constants/cookieKeys";
import { getCookie } from "shared/lib/legacyUtils/cookie";

export function getTokenTime(
  tokenName: typeof COOKIE_KEYS.ACCESS_TOKEN | typeof COOKIE_KEYS.REFRESH_TOKEN
) {
  const token = getCookie(tokenName);
  if (!token) return;

  const decodeRefreshToken: JwtPayload = jwtDecode(token);
  const exp = Number(decodeRefreshToken.exp);
  const timestamp = new Date().getTime();
  const currentTime = timestamp / 1000;

  // eslint-disable-next-line consistent-return
  return { exp, currentTime };
}

export function validateTokenTime(
  tokenName: typeof COOKIE_KEYS.ACCESS_TOKEN | typeof COOKIE_KEYS.REFRESH_TOKEN
) {
  const info = getTokenTime(tokenName);
  if (!info) return false;

  return info.exp > info.currentTime;
}
