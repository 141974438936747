import classNames from "classnames";
import { type PushListItem } from "entities/push-notification/api/types";
import { memo, type Key } from "react";
import Shared from "shared/ui";
import styles from "./PushNotifyCard.module.scss";
import { PushNotifyMetaData } from "./PushNotifyMetaData";

const borderRadius = 12;

type Props = {
  item: PushListItem;
  handleOpenNotifyDetail: (id: Key) => void;
};

export const PushNotifyCard = memo(
  ({ item, handleOpenNotifyDetail }: Props) => {
    const { id, readStats, category, title, message, sendDate } = item;

    const isColored =
      category === "반품" || category === "취소" || category === "교환";

    return (
      <Shared.Card
        className={classNames(
          styles.PushNotifyCard,
          readStats && styles.isRead
        )}
        innerStyle={{ borderRadius }}
        style={{ borderRadius }}
        role="button"
        onClick={() => handleOpenNotifyDetail(id)}
      >
        <Shared.Card.Header
          className={`${styles["card-header"]}`}
          hasBorder={false}
        >
          <PushNotifyMetaData category={category} sendDate={sendDate} />
          {/* Delete 버튼 */}
          {/* <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: 1,
            }}
          >
            <Shared.ActionButton
              icon="close"
              iconProps={{ size: 24 }}
              style={{ padding: 8 }}
              styleVariant="none"
              onClick={e => {
                e.stopPropagation();
                showAlert("알림을 삭제하시겠습니까?", {
                  showCancelButton: true,
                });
              }}
            />
          </div> */}
        </Shared.Card.Header>

        <Shared.Card.Body className={`${styles["card-body"]}`}>
          <Shared.VStack className={styles.content}>
            <b className={styles.title}>
              {isColored && (
                <span className={styles.canceledTitle}>
                  [{title.replace("알림", "").trim()}]{" "}
                </span>
              )}
              {title}
            </b>
            <p className={styles.text}>{message}</p>
          </Shared.VStack>
        </Shared.Card.Body>
      </Shared.Card>
    );
  }
);

export const PushNotifyCardSkeleton = () => {
  return (
    <Shared.Card
      className={classNames(styles.PushNotifyCard, styles.skeleton)}
      innerStyle={{ borderRadius }}
      style={{ borderRadius }}
    >
      <Shared.Card.Header
        className={`${styles["card-header"]}`}
        hasBorder={false}
      >
        <PushNotifyMetaData.Skeleton />
      </Shared.Card.Header>

      <Shared.Card.Body className={`${styles["card-body"]}`}>
        <Shared.VStack className={styles.content}>
          <Shared.Skeleton width="180px" />
          <Shared.Skeleton />
          <Shared.Skeleton />
        </Shared.VStack>
      </Shared.Card.Body>
    </Shared.Card>
  );
};
