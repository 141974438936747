import type { ReactNode } from "react";
import {
  useInfiniteScrollRef,
  type UseInfiniteScrollProps,
} from "shared/hooks";

type Props = UseInfiniteScrollProps & {
  children: ReactNode;
};

export const InfiniteScrollWrapper = ({ children, ...props }: Props) => {
  const ref = useInfiniteScrollRef(props);

  return (
    <>
      {children}
      <div ref={ref} />
    </>
  );
};
