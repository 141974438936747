export const api = {
  // 회원 API
  users: {
    signup: "/api/v1/users/signup",
    signin: "/api/v1/users/signin",
    logout: "/api/v2/users/logout",
    refresh: "/api/v1/users/refresh",
    keepLogin: "/api/v2/users/signin/keep/login",

    password: "/api/v1/users/password",
    keepPassword: "/api/v1/users/password/keep-using",

    findId: "/api/v1/users/find-id",
    findAllId: "/api/v1/users/find-id/all-id",

    info: "/api/v1/users/info",
    powerSeller: "/api/v1/users/power-seller",
    sellerList: "/api/v1/users/seller-list",

    certificationNumber: "/api/v1/users/certification-number",
    certificationNumberCheck: "/api/v1/users/certification-number/check",

    accessToken: {
      /**
       * Refresh Token으로 새로운 토큰 발급
       * @see https://sbridge.atlassian.net/wiki/x/LIDIBQ
       * @deprecated v2에서 삭제될 예정입니다.
       */
      refresh: "/api/v1/users/accessToken/refresh",
    },
  },

  // 비회원 API
  nonmember: {
    auth: "/api/v1/users/nonmember/auth",
    sendAuthNum: "/api/v1/users/nonmember/auth/send-authnum",
    order: "/api/v1/users/nonmember/order",
    kcbRequest: "/api/v1/nonmember/kcbrequest",
    safeOkName: "https://safe.ok-name.co.kr/CommonSvl",
  },

  file: {
    upload: "/api/v1/files/upload",
  },

  // 상품 API
  product: {
    list: "/api/v1/products",
    count: "/api/v1/countProduct",
    detail: (productId: string) => `${api.product.list}/${productId}`,
    favorite: (productId: string) => `${api.product.list}/${productId}/wish`,

    categories: "/api/v1/categories",
    brand: "/api/v1/brands/forProductReg",
    models: "/api/v1/models",

    productReviews: (productId: string, orderNo = "") =>
      `/api/v1/products/${productId}/reviews${orderNo ? `/${orderNo}` : ""}`,
    seller: (productId: string) => `/api/v1/products/${productId}/seller`,
    sellerProducts: "/api/v1/seller/products",
    resetRegDate: "/api/v1/seller/additional-service/product-regdate-reset",
  },

  // 장바구니 API
  basket: {
    basket: "/api/v1/basket",
    option: "/api/v1/basket/option",
    deliveryfee: "/api/v1/basket/deliveryfee",
  },

  // 주문 API
  order: {
    // 구매주문
    purchase: "/api/v1/orders/purchases",
    productOrders: (orderId: string) =>
      `/api/v1/orders/purchases/${orderId}/product-orders`,
    purchaseComplete: (productOrderId: string) =>
      `/api/v1/orders/purchases/${productOrderId}/purchase-complete`,
    cancelOrder: "/api/v2/buyer/order/cancel",
    returnRequest: (productOrderId: string) =>
      `/api/v1/orders/purchases/${productOrderId}/return-request`,

    // 판매주문
    sales: "/api/v1/seller/orders",
    returnApprove: (productOrderId: string) =>
      `/api/v1/seller/orders/${productOrderId}/return-approve`,
    returnPickup: (productOrderId: string) =>
      `/api/v1/seller/orders/${productOrderId}/return-pickup`,
    exchangeApprove: (productOrderId: string) =>
      `/api/v1/seller/orders/${productOrderId}/exchange-approve`,

    statistics: {
      seller: "/api/v1/seller/orders/statistics",
      purchases: "/api/v1/orders/purchases/statistics",
    },
  },

  // 마이페이지 API
  myshop: {
    seller: {
      addInfo: "/api/v1/seller/add-Info",
    },
  },

  talk: "/api/v1/talk",

  // 결제 API
  pay: {
    pay: "/api/v1/pay",
    seller: {
      additionalService: "/api/v1/seller/additional-service/pay",
    },
  },

  purchase: "/api/v1/purchase",
  naverPay: "/api/v1/naverPay",

  help: {
    notice: "/api/v1/help/notice",
    faq: "/api/v1/help/faq",
    illegalDeal: "/api/v1/help/illegaldeal",
    fake: "/api/v1/help/fake",
    bugReport: "/api/v1/help/bugreport",
  },

  push: {
    list: "/api/v1/push/list",
    detail: "/api/v1/push/detail",
    unread: "/api/v1/push/unread",
  },
} as const;
