import classNames from "classnames";
import type { HTMLAttributes } from "react";
import styles from "./BottomBar.module.scss";

type Props = HTMLAttributes<HTMLDivElement> & {
  shadow?: boolean;
  bordered?: boolean;
};

export const BottomBar = ({
  className,
  children,
  shadow,
  bordered,
  ...props
}: Props) => {
  return (
    <div
      className={classNames(
        styles.BottomBar,
        shadow && styles.shadow,
        bordered && styles.bordered,
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};
