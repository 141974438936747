import { baseQueryKeys } from "shared/constants/baseQueryKeys";
import type { ProductListPayload } from "./productApi";

export const productQueryKey = {
  product: baseQueryKeys.product,

  count: () => [productQueryKey.product, "count"],
  list: (payload: Partial<ProductListPayload>) => [
    productQueryKey.product,
    "list",
    payload,
  ],
};
