import type { ProductRegisterData } from "entities/product/seller-product";
import { showAlert } from "shared/lib/showAlert";

// 검증
export function validateSellerProductForm(data: ProductRegisterData) {
  // 이미지 업로드
  const hasBlob =
    data.imagesInfo.filter(({ imagePath }) => imagePath.includes("blob:http"))
      .length > 0;
  if (hasBlob) {
    showAlert(
      "이미지 업로드에 문제가 생겼습니다. 상품 이미지를 새로 등록하신 후 다시 시도해주세요."
    );
    return false;
  }

  // 상품정보제공고시에 빈값이 포함된 경우
  const nullContent = data?.noticeInfo?.noticeValueInfo?.find(
    v => v.noticeValueText === ""
  );
  if (nullContent) {
    showAlert("상품정보제공고시 항목을 입력해주세요.");
    return false;
  }

  // 상품옵션 사용을 선택했으나 옵션 설정을 안 한 경우
  if (
    data.optionInfo &&
    !data.optionInfo.orderOption &&
    !data.optionInfo.recommendedOption
  ) {
    showAlert("상품옵션을 설정해주세요.");
    return false;
  }

  return true;
}
