import classNames from "classnames";
import { useFileUpload } from "features/file/upload-file";
import { type ChangeEvent } from "react";
import Shared from "shared/ui";
import type { ImageUploaderInputProps } from "../types";
import styles from "./ImageUploaderInput.module.scss";
import {
  createImageList,
  generateFormDataWithRotatedImages,
  handleUploadError,
  handleUploadSuccess,
  validateFileList,
} from "./ImageUploaderInput.utils";

export const ImageUploaderInput = ({
  size,
  handleAddImage,
  handleUpdateImage,
  uploadType: type,
  maxCount,
  currentCount,
  limit,
  aspectRatio,

  ...rest
}: ImageUploaderInputProps) => {
  const { mutate: fileUpload, isLoading } = useFileUpload();

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const id = new Date().getTime();
    const fileList = Array.from(e.target.files);
    const images = createImageList(fileList, id);

    if (!validateFileList({ fileList, limit, maxCount, currentCount })) return;

    handleAddImage(images);

    try {
      const formData = await generateFormDataWithRotatedImages(fileList);

      fileUpload(
        { type, formData },
        {
          onSuccess: ({ fileList }) =>
            handleUploadSuccess(handleUpdateImage, images, fileList),

          onError: () => handleUploadError(handleUpdateImage, images, fileList),
        }
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      handleUploadError(handleUpdateImage, images, fileList);
    }
  };

  return (
    <div
      className={classNames(
        styles.ItemInput,
        isLoading && styles.disabled,
        styles[`ratio-${aspectRatio}`]
      )}
      style={{ width: size, height: size }}
    >
      <Shared.Uploader
        onChange={e => {
          handleFileUpload(e);
        }}
        isLoading={isLoading}
        {...rest}
      >
        <Shared.VStack align="center" justify="center" gap={2}>
          <Shared.Icon name="camera" size={40} className={styles.plusIcon} />
          <span>사진 업로드</span>
        </Shared.VStack>
      </Shared.Uploader>

      {isLoading && <Shared.LoadingOverlay />}
    </div>
  );
};
