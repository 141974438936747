import type { SkeletonProps } from "react-loading-skeleton";
import ReactSkeleton from "react-loading-skeleton";

export const Skeleton = ({
  width = "100%",
  height = "16px",
  ...props
}: SkeletonProps) => {
  return (
    <span style={{ display: "block", width }}>
      <ReactSkeleton height={height} {...props} />
    </span>
  );
};
