import { useState } from "react";
import Shared from "shared/ui";

const 직접입력 = "직접 입력";
const 수입국가목록 = [
  "미국",
  "중국",
  "일본",
  "인도네시아",
  "인도",
  "멕시코",
  "말레이시아",
  "태국",
  "대만",
  "베트남",

  "상세페이지 참조",
  "알수 없음",

  직접입력,
] as const;

type Props = {
  value: string | undefined;
  onChange: (value: string) => void;
};

export const ProductOriginAreaSelect = ({ value, onChange }: Props) => {
  const [selectedValue, setSelectedVale] = useState(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    수입국가목록.includes(value as any) ? value : 직접입력
  );

  return (
    <Shared.VStack style={{ marginTop: 4 }}>
      <Shared.Select
        defaultOption={{ name: "수입국가 선택" }}
        options={수입국가목록.map(origin => ({ name: origin, value: origin }))}
        value={selectedValue}
        onChange={e => {
          const { value } = e.target;
          setSelectedVale(value);
          onChange(value === 직접입력 ? "" : value);
        }}
        required
      />

      {selectedValue === 직접입력 && (
        <Shared.Input
          placeholder="수입국가"
          fullWidth
          value={value}
          onChange={e => onChange(e.target.value)}
          required
        />
      )}
    </Shared.VStack>
  );
};
