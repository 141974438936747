export type ProductSection =
  /**  실시간 상품 (메인 포함) */
  | "real_time"
  /**  가격 인하 상품 리스트 (메인 포함) */
  | "price_reduction"
  /**  파워존 */
  | "power_zone"
  /**  베스트 아이템 */
  | "best_product"
  /**  새로 들어 왔습니다 */
  | "new_receiving"
  /**  이런 상품 어때요 */
  | "recommend_product"
  /**  최근 본 상품 */
  | "recent_view_product"
  /**  미니샵 내 상품 검색 */
  | "minishop";

export const enum SaleType {
  /** 새상품(0)은 사업자 회원만 등록 가능 */
  새상품 = 0,
  중고상품 = 1,
}

export const enum SaleStatus {
  "판매중" = 0,
  "판매중지" = 1,
  "승인대기" = 10,
  "승인보류" = 11,
  "승인반려" = 12,
}

export const SALE_STATUS = {
  0: "판매중",
  1: "판매중지",
  10: "승인대기",
  11: "승인보류",
  12: "승인반려",
} as const;

export const enum IsTariff {
  관세미포함 = 0,
  관세포함 = 1,
}

export interface ProductBase {
  /** 상품명: 60자 (120 Byte) */
  productName: string;
  /**
   * 상품 판매유형 (수정 시 변경 불가)
   * - 0 : 새상품
   * - 1 : 중고상품
   * - 새상품(0)은 사업자 회원만 등록 가능
   */
  saleType: SaleType;
  /**
   * 판매 상태: 상품 수정 시 필수!
   * 수정하고자 하는 상품의 상태가 승인대기 또는 승인반려이면 수정 불가
   * - 0 : 판매중
   * - 1 : 판매중지
   */
  saleStatus: SaleStatus;
  /**
   * 재고 수량
   * - 판매 상태가 판매중일 경우 최소값 1개
   * - 최대값 99,999개
   */
  stockQuantity: number;
  /**
   * 등록가
   * - 최소값 5,000원 (100원 단위)
   * - 최대값 10,000,000원 (100원 단위)
   */
  orgPrice: number;
  /**
   * 배송비 유형
   * - 0 : 무료
   * - 1 : 유료(착불)
   * - 2 : 유료(선결제)
   *
   * 상품 등록시 무료배송만 가능
   *
   * 상품 수정시 기존 등록 배송 타입이 무료가 아닌 경우 모든 설정 가능
   */
  deliveryType: number;
  /** 배송비: 최대값 0원 */
  deliveryFee: number;
  /** 관세 포함 여부
   * - 0: 관세 미포함,
   * - 1: 관세 포함 */
  isTariff: IsTariff;
}
