import { DraggableImageUploader } from "./DraggableImageUploader";
import styles from "./ImageUploader.module.scss";
import { ImageUploaderInput } from "./ImageUploaderInput/ImageUploderInput";
import { ImageUploaderPreview } from "./ImageUploaderPreview/ImageUploaderPreview";
import { useImageUploaderHandlers } from "./lib/useImageUploaderHandlers";
import { useInitializePreviewImages } from "./lib/useInitializePreviewImages";
import type { ImageUploaderProps } from "./types";

export const ImageUploader = ({
  uploadType,
  size = 125,
  multiple,
  maxCount,

  defaultImages,
  onChange,
}: ImageUploaderProps) => {
  const { previewImages, setPreviewImages } = useInitializePreviewImages({
    defaultImages,
    onChange,
  });

  const { handleAddImage, handleDeleteImage, handleUpdateImage } =
    useImageUploaderHandlers({ setPreviewImages });

  return (
    <div className={styles.ImageUploader}>
      <ul className={styles.list}>
        {previewImages.map(image => (
          <li key={image.id}>
            <ImageUploaderPreview
              image={image}
              handleDeleteImage={handleDeleteImage}
              size={size}
            />
          </li>
        ))}

        <li>
          <ImageUploaderInput
            accept="image/jpg,image/png,image/jpeg"
            uploadType={uploadType}
            handleAddImage={handleAddImage}
            handleUpdateImage={handleUpdateImage}
            multiple={multiple}
            currentCount={previewImages.length}
            maxCount={maxCount}
            limit="10MB"
            size={size}
          />
        </li>
      </ul>
    </div>
  );
};

ImageUploader.Draggable = DraggableImageUploader;
