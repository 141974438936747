import type { ChangeEvent, FormEvent } from "react";
import { useRef } from "react";
import { onErrorImg } from "shared/constants/Functions";
import "./FileUpLoad.scss";
import {
  useUploadThumbnail,
  validateImageFile,
} from "./ThumbnailImageUpload.hooks";

// TODO: 전부 교체 후 삭제
export const ThumbnailImageUpload = ({
  type,
  setImageFiles,
  imageFiles = [],
  idx,
  setIsLoading,
}: any) => {
  const submitRef = useRef<HTMLButtonElement>(null);
  const fileInput = useRef<HTMLInputElement>(null);

  const { UploadWithChangeOrientation } = useUploadThumbnail({
    type,
    imageFiles,
    offLoading: () => {
      setIsLoading(false);
    },
    onSuccess: newImageList => {
      setImageFiles(newImageList);
    },
  });

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    if (!fileInput?.current?.files) return;

    const newImageFile = fileInput?.current?.files[0];
    if (!validateImageFile(newImageFile)) {
      setIsLoading(false);
      return;
    }

    await UploadWithChangeOrientation(newImageFile);
  };

  const handleFileReader = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (submitRef) {
        submitRef?.current?.click();
      }
      e.target.value = ""; // 동일한 파일을 재선택해도 이벤트가 작동하도록
    }
  };

  const onClickRemoveImage = (idx: number) => {
    const removedImageList = imageFiles?.map((img: any) =>
      img.idx === idx
        ? {
            ...img,
            path: "",
            url: "",
          }
        : img
    );
    const hasImageList = removedImageList?.filter((img: any) => !!img.url);
    const emptyList = removedImageList?.filter((img: any) => img.url === "");
    const arr: any[] = [];
    let newIdx = 0;
    hasImageList?.forEach(({ url, path }: any) => {
      arr.push({
        idx: newIdx,
        url,
        path,
      });
      newIdx++;
    });
    emptyList?.forEach(({ url, path }: any) => {
      arr.push({
        idx: newIdx,
        url,
        path,
      });
      newIdx++;
    });

    setImageFiles(arr);
  };

  return (
    <div id="ThumbnailImageUpload">
      {
        // 사진 미리보기
        imageFiles && imageFiles[idx]?.url !== "" ? (
          <div className="imageFile_wrapper">
            <img
              src={imageFiles[idx]?.url}
              alt="첨부파일1"
              onError={onErrorImg}
            />
            <img
              className="icon_close"
              src="/assets/icons/icon_navi_close.svg"
              alt="닫기 아이콘"
              onClick={() => onClickRemoveImage(idx)}
            />
          </div>
        ) : (
          // 이미지 업로드
          <div className="input_file">
            <form
              encType="multipart/form-data"
              method="post"
              onSubmit={handleSubmit}
            >
              <div
                className="preview_wrapper"
                onClick={() => fileInput?.current?.click()}
              >
                {/* <input type={"text"} placeholder={previewFile} readOnly /> */}
                {/* <button>파일 첨부하기</button> */}
                <img
                  className="plus_icon"
                  src="/assets/icons/image_plus.svg"
                  alt="파일업로드 아이콘"
                />
              </div>
              <input
                type="file"
                className="hide"
                // multiple 여러장 첨부 시 사용
                // {...(register && { ...register(inputName) })}
                accept="image/jpg,image/png,image/jpeg,image/gif"
                // name="files"
                onChange={handleFileReader}
                // readOnly
                ref={fileInput}
              />
              <button type="submit" ref={submitRef} className="submit_file_btn">
                제출
              </button>
            </form>
          </div>
        )
      }
    </div>
  );
};
