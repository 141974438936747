import type { PushCategory } from "entities/push-notification/api/types";
import { useQueryParameterUpdater } from "shared/hooks";
import Shared from "shared/ui";

const CATEGORY: PushCategory[] = [
  "전체",
  "판매",
  "구매",
  "문의",
  "교환",
  "반품",
  "취소",
  "쪽지",
]; // 공지는 일단 X

type Props = {
  currentCategory: PushCategory | undefined;
};

export const PushCategorySelector = ({ currentCategory = "전체" }: Props) => {
  const updateStatus = useQueryParameterUpdater();

  return (
    <Shared.HStack
      style={{ overflowX: "scroll", marginBottom: 6 }}
      gap={8}
      wrap
    >
      {CATEGORY.map(category => (
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        <div
          key={category}
          role="button"
          onClick={() => updateStatus({ category })}
          onKeyDown={e => {
            if (e.key === "Enter") updateStatus({ category });
          }}
          tabIndex={0}
          style={{ flexShrink: 0 }}
        >
          {category === currentCategory ? (
            <Shared.Badge
              text={category}
              colorVariant="red-light"
              className="font_500"
              size="m"
            />
          ) : (
            <Shared.Badge text={category} colorVariant="white" size="m" />
          )}
        </div>
      ))}
    </Shared.HStack>
  );
};
