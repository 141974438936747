/* eslint-disable jsx-a11y/label-has-associated-control */
import classNames from "classnames";
import { forwardRef, type InputHTMLAttributes, type ReactNode } from "react";
import { Stack } from "../Stack/Stack";
import styles from "./Radio.module.scss";

type RadioProps = Omit<InputHTMLAttributes<HTMLInputElement>, "type"> & {
  name: string;
  label: string;
};

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  ({ label, checked, disabled, ...rest }, ref) => {
    return (
      <div className={styles.Radio}>
        <label
          className={classNames(
            styles.label,
            disabled && styles.disabled,
            checked && styles.checked
          )}
        >
          <input
            ref={ref}
            type="radio"
            checked={checked}
            disabled={disabled}
            {...rest}
          />
          <span className={styles.text}>{label}</span>
        </label>
      </div>
    );
  }
);

type RadioGroupProps = { children: ReactNode };

export const RadioGroup = ({ children }: RadioGroupProps) => {
  return (
    <Stack className={styles.RadioGroup} direction="vertical">
      {children}
    </Stack>
  );
};
