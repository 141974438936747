type Option = {
  prefix?: string;
  suffix?: string;
};

/**
 * 숫자 또는 문자열을 가격 문자열로 포맷하고, 천 단위마다 쉼표를 추가합니다.
 * 선택적으로 포맷된 가격에 접두사 또는 접미사를 추가할 수 있습니다.
 */
export const formatPrice = (
  price: number | string,
  { prefix = "", suffix = "" }: Option = {}
): string => {
  if (Number.isNaN(Number(price))) return "";

  const priceString = Number(price)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `${prefix}${priceString}${suffix}`;
};
