import type { ProductBase } from "entities/product/@x/seller-product";

export const enum SalePeriod {
  "6개월" = 6,
  "12개월" = 12,
}

export const enum AfterService {
  불가능 = 0,
  가능 = 1,
}

export const enum OriginArea {
  국내산 = 0,
  수입산 = 1,
}

export const enum DeliveryType {
  무료 = 0,
  유료_착불 = 1,
  유료_선결제 = 2,
  조건부_무료 = 3,
}

/** 추가 정보 구분 */
export const enum AddTypeCode {
  "길이" = 1,
  "클럽 번호" = 2,
  "로프트 각도" = 3,
  "클럽 개수" = 4,
  "세트 구성" = 5,
  "샤프트 종류" = 6,
  "샤프트 강도" = 7,
  "헤드 형태" = 8,
  "헤드 커버" = 9,
}

/**
 * 상품 추가 정보
 * - 일부 카테고리만 필수
 */
export type AdditionalInfo = {
  /** 추가 정보 구분 */
  addTypeCode?: AddTypeCode;

  /** TODO: 확인 */
  addTypeText?: string | undefined;

  /**
   * 추가 정보 값 코드
   * - 외부 제휴사의 경우 열람 권한 요청 필요
   *
   * 아래 링크 참조
   * @see https://docs.google.com/spreadsheets/d/1lXyaWlge3Gx5bJ-P7w8ebzzqE-VC1OA3guz14mJJh_g/edit?usp=sharing
   */
  addValueCode?: number | undefined;

  /**
   * 추가 정보 값 텍스트
   * - 직접 입력일 경우 필수
   * - 50자 (100 Byte)
   */
  addValueText?: string | undefined;
};

/**
 * 상품 정보제공 고시 카테고리 코드
 * - 1 : 골프클럽용품(2,3,4,5를 제외한 나머지)
 * - 2 : 골프의류(2154)
 * - 3 : 골프화(2153r03)
 * - 4 : 골프백(2153r02)
 * - 5 : 골프모자(2153r07), 벨트/양말/썬글라스(2153r08)
 */
export const enum NoticeCategoryCode {
  "골프클럽용품" = 1,
  "골프의류" = 2,
  "골프화" = 3,
  "골프백" = 4,
  "골프모자" = 5,
}

/** 상품 정보제공 고시 값 */
export type NoticeValueInfo = {
  /**
   * 상품 정보제공 고시 코드
   * 외부 제휴사의 경우 열람 권한 요청 필요
   *
   * 아래 링크 참조
   * @see https://docs.google.com/spreadsheets/d/1w45lQmoUmJYD4hyNkVvuwdzPMeq6NWXlcD5OizYNZo4/edit?usp=sharing
   */
  noticeValueCode: number;

  /**
   * 상품 정보제공 고시 값: 100자 (200 Byte)
   */
  noticeValueText: string;
};

/** 상품 정보제공 고시 */
export type NoticeInfo = {
  /**
   * 상품 정보제공 고시 카테고리 코드
   * - 1 : 골프클럽용품(2,3,4,5를 제외한 나머지)
   * - 2 : 골프의류(2154)
   * - 3 : 골프화(2153r03)
   * - 4 : 골프백(2153r02)
   * - 5 : 골프모자(2153r07), 벨트/양말/썬글라스(2153r08)
   */
  noticeCategoryCode: NoticeCategoryCode;

  /** 상품 정보제공 고시 값 */
  noticeValueInfo: NoticeValueInfo[];
};

/**
 * 옵션 버전
 * - NEW : 추천옵션
 * - OLD : 구버전 옵션
 */
export const enum OptionType {
  선택형 = 1,
  조합형 = 2,
}

/**
 * 주문 옵션(구버전 옵션)
 * - 추천옵션과 중복 사용 불가
 * - 주문옵션 ↔︎ 추천옵션 간 상호 전환 가능
 */
export type OrderOption = {
  /** 주문 옵션 선택형 타입 */
  independent?: {
    /** 옵션명: 25자 (50 Byte) */
    optionName: string;

    optionValueInfo: {
      /** 옵션값: 50자 (100 Byte) */
      optionValue: string;
      /** 옵션 재고 수량 : 옵션 상품 등록 시 stockQuantity는 옵션별 재고 수량을 합한 금액으로 자동 계산 */
      optionQuantity: number;
    }[];
  };

  /** 주문 옵션 조합형 타입 */
  combination?: {
    optionValueInfo: {
      /** 옵션값 1: 25자 (50 Byte) */
      optionValue1: string;
      /** 옵션값 2: 50자 (100 Byte) */
      optionValue2: string;
      /** 옵션 재고 수량
       * - 옵션 상품 등록 시 stockQuantity는 옵션별 재고 수량을 합한 금액으로 자동 계산 */
      optionQuantity: number;
    }[];
  };
};

/**
 * 추천 옵션(신버전 옵션)
 * - 카테고리 별 사용 가능 옵션 및 옵션 코드 값은 옵션 조회 API 또는 아래 링크 참조
 * @see https://docs.google.com/spreadsheets/d/1c_V7xtnseJ_T8QO6vFyfFSwlnoRru2R42y0FnnZQ8DY/edit?usp=sharing
 */
export type RecommendedOption = {
  /** 옵션 코드 1: 단독으로 사용 시 선택형 옵션 */
  optionCode1: string;
  /** 옵션 코드 2: 조합형일 때 사용 */
  optionCode2: string | undefined;
  optionValueInfo: {
    /** 옵션 값 코드 1: 단독으로 사용 시 선택형 옵션 */
    optionValueCode1: string;
    /** 옵션 값 코드 2: 조합형일 때 사용 */
    optionValueCode2: string | undefined;
    /** 옵션 재고 수량: 옵션 상품 등록 시 stockQuantity는 옵션별 재고 수량을 합한 금액으로 자동 계산 */
    optionQuantity: number;
  }[];
};

/** 옵션 정보 */
export type OptionInfo = {
  /**
   * 옵션 버전
   * - NEW : 추천옵션
   * - OLD : 구버전 옵션
   */
  optionVersion: "NEW" | "OLD";

  /**
   * 옵션 타입
   * - 1 : 선택형
   * - 2 : 조합형
   */
  optionType: OptionType;

  /**
   * 주문 옵션(구버전 옵션)
   * - 추천옵션과 중복 사용 불가
   * - 주문옵션 ↔︎ 추천옵션 간 상호 전환 가능
   */
  orderOption?: OrderOption;

  /**
   * 추천 옵션(신버전 옵션)
   * - 카테고리 별 사용 가능 옵션 및 옵션 코드 값은 옵션 조회 API 또는 아래 링크 참조
   * @see https://docs.google.com/spreadsheets/d/1c_V7xtnseJ_T8QO6vFyfFSwlnoRru2R42y0FnnZQ8DY/edit?usp=sharing
   */
  recommendedOption?: RecommendedOption;
};

export type ProductImage = { imagePath: string };

export interface ProductRegisterData extends ProductBase {
  /**
   * 카테고리 코드 : 카테고리 조회 API를 통해 조회된 카테고리 코드 입력
   *
   * 반드시 Last Depth의 카테고리 코드를 입력
   * (수정 시 변경 불가)
   */
  categoryCode: string;

  /**
   * 브랜드 코드: 브랜드 조회 API를 통해 조회된 브랜드 코드
   *
   * 일치하는 브랜드가 없을 시 기타 BRAND 코드로 입력
   * - 기타 BRAND 코드 : 68
   */
  brandCode: string;

  /**
   * 상품 판매 종료 기간
   * - 6개월 기본
   * - 6,12만 가능
   * - 사업자는 9999년으로 설정
   */
  salePeriod: SalePeriod;

  /** 상품 상세설명 */
  detailContent: string;

  /**
   * 모델 코드: 모델 조회 API를 통해 조회된 모델 코드
   * 일치하는 모델 코드 없을 시 0 으로 입력
   */
  modelCode: string | 0;

  /**
   * 중고 상품 상태
   * - 100 ~ 50 까지 5단위로 입력
   * - 미사용일 경우 0으로 입력
   *
   * 중고 상품일 경우 필수
   */
  usedCondition?: number;

  /**
   * 중고 상품 사용 기간
   * - 0~99 까지 월 단위로 입력
   *
   * 중고 상품일 경우 필수
   */
  usedPeriod?: number;

  /**
   * 원산지
   * - 0 : 국내산
   * - 1 : 수입산
   */
  originArea: OriginArea;

  /**
   * 원산지명:
   * 원산지가 수입산일 경우 필수
   * - 100자 (200 Byte)
   */
  originAreaName?: string;

  /**
   * 정품구분
   * - 1 : 아시안스펙 정품
   * - 2 : 아시안스펙 직수(병행)
   * - 3 : 미국스펙 정품
   * - 4 : 미국스펙 직수(병행)
   * - 5 : 피팅클럽
   * - 6 : 모름
   */
  authenticType: number;

  /**
   * 로얄셀러 특약가
   *
   * - 회원 ID가 pricegolf , tigergolf 이거나 회원그룹이 로얄셀러(26) 일 때
   *   - 판매자 직접 입력
   *
   * - 그 외
   *    - 입력창 미노출
   *    - orgPrice * 0.9 계산하여 hidden 전송
   */
  specialPrice?: number;

  /**
   * 해외 배송 여부
   * - true : 해외배송
   * - false : 국내배송
   *
   * 해외배송(true)는 승인된 사업자 회원만 가능
   */
  deliveryOverseas: boolean;

  /** 배송 소요일수: 해외배송일 경우 필수 */
  deliveryDday: number;

  /**
   * 도서산간 배송비: 최대값 5,000원 (100원 단위)
   */
  islandFee: number;

  /**
   * 반품 배송비
   * - 최대값 10,000원 (100원 단위)
   * - 단, 풀세트 카테고리의 상품일 경우 최대값 15,000원
   */
  returnFee: number;

  /**
   * 묶음배송 여부
   * - 0 : 불가능
   * - 1 : 가능
   */
  bundleDelivery: number;

  /**
   * A/S 가능 여부
   * - 0 : 불가능
   * - 1 : 가능
   */
  afterService: AfterService;

  /**
   * A/S 책임자와 전화번호
   * - A/S 가능일 경우 필수
   * - 50자 (100 Byte)
   */
  afterServiceDetail?: string;

  /**
   * 제휴사 상품 연동 상태
   * - true : 연동
   * - false : 미연동
   */
  coopRegStatus: boolean;

  /** 제휴사 정책 동의 여부 */
  coopPolicyAgree: boolean;

  /** 프리미엄 쿠폰이 있는 경우 쿠폰 사용 여부 */
  usePremiumCoupon?: boolean;

  // TODO: 확인
  additionalInfo?: AdditionalInfo[];

  noticeInfo: NoticeInfo;

  optionInfo?: OptionInfo;

  /** 썸네일 이미지 */
  imagesInfo: ProductImage[];
}

// ===============

export type ChannelInfo = {
  coopName: string;
  coopKrName: string;
  coopStatus: string;
  coopUrl: string;
};
export interface ProductSalesData extends ProductBase {
  productId: string;
  productCode: string;
  productName: string;
  salePrice: number;
  image: string;
  registerDate: Date;
  modifyDate: Date;
  startDate: Date;
  endDate: Date;
  premiumDate: Date;
  havingOption: boolean;
  channelInfo: ChannelInfo[];
}
