import classNames from "classnames";
import type { CSSProperties } from "react";
import Shared from "..";
import styles from "./Tabs.module.scss";
import type { useTabsProps } from "./useTabs";
import { useTabs } from "./useTabs";

export type TabsProps = useTabsProps & {
  style?: CSSProperties;
  bordered?: boolean;
  isSticky?: boolean;

  styleVariants?: "underlined" | "pill";
};

export const Tabs = ({
  items,
  defaultActiveKey,
  onChange,
  style,
  bordered,
  isSticky,
  styleVariants = "underlined",
}: TabsProps) => {
  const { activeItem, handleTabClick } = useTabs({
    items,
    defaultActiveKey,
    onChange,
  });

  return (
    <div>
      <div
        className={classNames(
          styles.Tabs,
          styles[`style-${styleVariants}`],
          bordered && styles.bordered,
          isSticky && styles.sticky
        )}
      >
        <Shared.HStack className={styles.tabList} style={style}>
          {items.map(item => {
            const { key, label } = item;
            const isActiveTab = key === activeItem?.key;
            return (
              <Shared.BaseButton
                className={classNames(
                  styles.tabItem,
                  isActiveTab && styles.isActive
                )}
                key={key}
                onClick={() => handleTabClick(item)}
              >
                {label}
              </Shared.BaseButton>
            );
          })}
        </Shared.HStack>
      </div>

      <div>
        {typeof activeItem?.children === "function"
          ? activeItem?.children()
          : activeItem?.children}
      </div>
    </div>
  );
};
