import classNames from "classnames";
import styles from "./Card.module.scss";
import type { CardChildrenProps } from "./types";

export const CardBody = ({
  className,
  children,
  ...rest
}: CardChildrenProps) => {
  return (
    <div className={classNames(styles.CardBody, className)} {...rest}>
      {children}
    </div>
  );
};
