import loadImage from "blueimp-load-image";
import CallApi from "shared/api/CallApi";
import { showAlert } from "shared/lib/showAlert";

const ACCEPTABLE_IMAGE_TYPE_LIST = [
  "image/jpg",
  "image/png",
  "image/jpeg",
  "image/gif",
];
const LIMIT = 10000000; // 10MB

type Props = {
  type: string;
  imageFiles: any[];
  offLoading: VoidFunction;
  onSuccess: (newImageList: any[]) => void;
};

export const useUploadThumbnail = ({
  type,
  imageFiles = [],
  offLoading,
  onSuccess,
}: Props) => {
  /**
   * 모바일 사진 업로드 시 90도 회전하는 문제를 해결하기 위한 함수입니다.
   *
   * @see https://velog.io/@khy226/%EB%AA%A8%EB%B0%94%EC%9D%BC-%EC%82%AC%EC%A7%84-%EC%97%85%EB%A1%9C%EB%93%9C-%EC%8B%9C-90%EB%8F%84-%ED%9A%8C%EC%A0%84%ED%95%98%EB%8A%94-%EB%AC%B8%EC%A0%9C-%ED%95%B4%EA%B2%B0-feat.-Exif-%EB%A9%94%ED%83%80%EB%8D%B0%EC%9D%B4%ED%84%B0-blueimp-load-image
   */
  const UploadWithChangeOrientation = async (newImageFile: File) => {
    loadImage(
      newImageFile,
      (img, data) => {
        const hasImageHead = data?.imageHead && data.exif;
        if (!hasImageHead) {
          uploadToServer(newImageFile);
        }

        if (hasImageHead) {
          loadImage.writeExifData(
            data.imageHead!,
            data as any,
            "Orientation",
            1
          );

          (img as any).toBlob(async function (blob: any) {
            const newBlob = await loadImage.replaceHead(blob, data.imageHead!);
            if (newBlob) {
              const file = new File([newBlob], newImageFile.name, {
                type: newImageFile.type,
              });
              uploadToServer(file);
            }
          }, newImageFile.type);
        }
      },
      {
        meta: true,
        orientation: true,
        canvas: true,
      }
    );
  };

  const _inputFileList = document.querySelectorAll("#ThumbnailImageUpload");

  /**
   * 서버에 업로드하는 함수입니다. TODO: 추후 service로 옮길 예정
   */
  const uploadToServer = async (newImageFile: File) => {
    const formData = new FormData();
    formData.append("files", newImageFile);

    // 파일업로드 api
    const response = await CallApi.request(
      `/api/v1/files/upload/${type}`,
      "POST",
      null,
      formData
    );
    try {
      if (response) {
        const result = imageFiles?.find(
          (img: any) => img.url === "" && img.path === ""
        );
        const newImageList = imageFiles.map((img: any) =>
          img.idx === result.idx
            ? {
                ...img,
                path: response.fileList[0].filePath,
                url: response.fileList[0].fileUrl,
              }
            : img
        );
        // scrollIntoView
        _inputFileList[result.idx]?.scrollIntoView({
          block: "center",
          inline: "center",
          behavior: "auto",
        });
        onSuccess(newImageList);
        offLoading();
      }
    } catch (error: any) {
      offLoading();
      showAlert(error);
    }
  };
  return { UploadWithChangeOrientation };
};

// 이미지 파일의 validation을 하는 유틸함수
export function validateImageFile(newImageFile: File): boolean {
  const isCorrectType = ACCEPTABLE_IMAGE_TYPE_LIST?.includes(
    newImageFile?.type
  );
  if (!isCorrectType) {
    showAlert("JPG, PNG, JPEG, GIF만 업로드 가능합니다.");
    return false;
  }

  const isOverSize = newImageFile.size > LIMIT;
  if (isOverSize) {
    showAlert("파일용량은 10MB를 넘을 수 없습니다.");
    return false;
  }

  return true;
}
