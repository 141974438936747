import classNames from "classnames";
import { forwardRef } from "react";
import { Icon } from "../Icon/Icon";
import styles from "./ActionButton.module.scss";
import { Button } from "./Button";
import type { ActionButtonProps } from "./types";

export const ActionButton = forwardRef<HTMLButtonElement, ActionButtonProps>(
  (
    { className, icon, iconProps, rounded, size = "xs", isLoading, ...rest },
    ref
  ) => {
    return (
      <Button
        className={classNames(
          styles.ActionButton,
          styles[`size-${size}`],
          rounded && styles.rounded,
          className
        )}
        ref={ref}
        size={size}
        isLoading={isLoading}
        {...rest}
      >
        <div
          className={classNames(
            styles["icon-wrapper"],
            isLoading && styles.isLoading
          )}
        >
          <Icon name={icon} {...iconProps} />
        </div>
      </Button>
    );
  }
);
