import type { PushCategory } from "entities/push-notification";
import { usePushDetailQuery } from "entities/push-notification";
import { useEffect } from "react";
import { useDelayState } from "shared/lib/useDelayState";
import Shared from "shared/ui";
import type { BottomSheetProps } from "shared/ui/BottomSheet/BottomSheet";
import { PushNotifyMetaData } from "../PushNotifyCard/PushNotifyMetaData";
import styles from "./PushNotifyBottomSheet.module.scss";

const BUTTON_TEXT: Record<PushCategory, string> = {
  전체: "바로가기",
  공지: "공지사항 보기",
  구매: "구매 내역 보기",
  문의: "문의 내역 보기",
  판매: "판매 내역 보기",
  쪽지: "쪽지 확인하기",
  교환: "교환 내역 보기",
  반품: "반품 내역 보기",
  취소: "취소 내역 보기",
};

type Props = Omit<BottomSheetProps, "children"> & {
  id: string;
  onSuccess: VoidFunction;
};

export const PushNotifyBottomSheet = ({
  id,
  onSuccess,
  onClose,
  ...rest
}: Props) => {
  const { data, isSuccess, isFetching } = usePushDetailQuery({
    id: Number(id),
  });

  const [showSkeleton] = useDelayState({ isLoading: isFetching });

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  }, [isSuccess, onSuccess]);

  const { title, message, category, sendDate = "", link = "#" } = data ?? {};

  return (
    <Shared.ScrolledBottomSheet
      className={styles.PushNotifyBottomSheet}
      height="52dvh"
      maxHeight="calc(99dvh - 148px)"
      hasNavigation={false}
      fixedChildren={
        <Shared.HStack className={styles.buttonContainer}>
          <Shared.LinkButton to="/help">
            <Shared.Icon name="help" size={30} />
          </Shared.LinkButton>
          <Shared.LinkButton
            to={link}
            text={BUTTON_TEXT[category ?? "전체"] ?? BUTTON_TEXT["전체"]}
            fullWidth
            colorVariant="red"
            styleVariant="solid"
            size="l"
            disabled={!data}
          />
        </Shared.HStack>
      }
      onClose={onClose}
      {...rest}
    >
      {!showSkeleton ? (
        <div className={styles.contentContainer}>
          <div className={styles.header}>
            <Shared.VStack gap={14}>
              <PushNotifyMetaData category={category} sendDate={sendDate} />
              <b className={styles.title}>{title}</b>
            </Shared.VStack>
          </div>

          <p className={styles.content}>{message}</p>
        </div>
      ) : (
        // 로딩 상태
        <div className={styles.contentContainer}>
          <div className={styles.header}>
            <Shared.VStack gap={14}>
              <PushNotifyMetaData.Skeleton />
              <Shared.Skeleton height="25px" />
            </Shared.VStack>
          </div>

          <p className={styles.content}>
            <Shared.Skeleton height="300px" />
          </p>
        </div>
      )}
    </Shared.ScrolledBottomSheet>
  );
};
