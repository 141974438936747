import classNames from "classnames";
import { formatPrice } from "shared/lib";

type Props = {
  price: number;

  size?: "m" | "l";
  disabled?: boolean;
};

export const CartOptionPrice = ({ disabled, size = "m", price }: Props) => {
  if (disabled) return <span className="graytext">품절</span>;

  return (
    <div className={classNames("font_400", size === "m" ? "text15" : "text16")}>
      <span className="font_600">{formatPrice(price)}</span> 원
    </div>
  );
};
