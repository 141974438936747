export const baseQueryKeys = {
  user: "user" as const,

  order: "order" as const,
  sellerOrder: ["order", "seller-order"] as const,
  myShop: ["order", "my-shop"] as const,

  product: "product" as const,
  sellerProduct: ["product", "seller-product"] as const,
  favorite: ["product", "favorite"] as const,

  cart: "cart" as const,

  help: "help" as const,
  talk: "talk" as const,

  push: "push" as const,
} as const;
