import { useAppDispatch, useAppSelector } from "app/appStore";
import { useProductCategoriesQuery } from "entities/product/product-category";
import { memo, useCallback, useEffect, useMemo } from "react";
import { showAlert } from "shared/lib/showAlert";
import type { Category } from "shared/types/Categories.types";
import Shared from "shared/ui";
import { setCategory } from "../../model/saveProductStore";

interface Props {
  onChange: (props: { categoryCode: string | undefined }) => void;
  onLargeCategoryChange: () => void;
  isSubmitted: boolean;
  isEditMode?: boolean;
}

export const ProductCategorySelect = memo(
  ({ onChange, onLargeCategoryChange, isSubmitted, isEditMode }: Props) => {
    const dispatch = useAppDispatch();
    const {
      대분류 = "",
      중분류 = "",
      소분류 = "",
    } = useAppSelector(state => state.saveProduct.카테고리);

    useEffect(() => {
      const categoryCode = 소분류 || 중분류 || 대분류;
      onChange?.({ categoryCode });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [대분류, 소분류, 중분류]);

    // react-query
    const { data: 전체_옵션_리스트 } = useProductCategoriesQuery();

    const 중분류_옵션_리스트 = useMemo(
      () =>
        전체_옵션_리스트?.find(({ categoryCode }) => categoryCode === 대분류)
          ?.categoryListChild,
      [대분류, 전체_옵션_리스트]
    );

    const 소분류_옵션_리스트 = useMemo(
      () =>
        중분류_옵션_리스트?.find(({ categoryCode }) => categoryCode === 중분류)
          ?.categoryListChild,
      [중분류, 중분류_옵션_리스트]
    );

    useEffect(() => {
      if (isEditMode) return;
      if (중분류 === "2153r10" || 중분류 === "2153r11") {
        showAlert(
          "기능성용품/기타골프용품 관련 상품등록시 건강식품(프로틴,가공식품,음료),생활화학제품 및 살생물제품(세척크리너,액체성 살균제등)은 관련규정상 프라이스골프에서는 판매등록하실 수 없습니다.(식품위생법 및 전자상거래 정보제공고시 참고)"
        );
      }
    }, [isEditMode, 중분류]);

    const createOptions = useCallback(
      (list: Category[] | undefined) =>
        list?.map(({ categoryName, categoryCode }) => ({
          value: categoryCode,
          name: categoryName,
        })) || [],
      []
    );

    return (
      <Shared.VStack gap={10}>
        <Shared.Select
          value={대분류}
          onChange={e => {
            dispatch(
              setCategory({ 대분류: e.target.value, 소분류: "", 중분류: "" })
            );
            onLargeCategoryChange?.();
          }}
          defaultOption={{ name: "대분류" }}
          options={createOptions(전체_옵션_리스트)}
          disabled={isEditMode}
        />

        <Shared.Select
          value={중분류}
          onChange={e => {
            dispatch(setCategory({ 소분류: "", 중분류: e.target.value }));
          }}
          defaultOption={{ name: "중분류" }}
          options={중분류_옵션_리스트?.map(
            ({ categoryName, categoryCode }) => ({
              value: categoryCode,
              name: categoryName,
            })
          )}
          disabled={isEditMode}
        />

        <Shared.Select
          value={소분류}
          onChange={e => {
            dispatch(setCategory({ 소분류: e.target.value }));
          }}
          defaultOption={{ name: "소분류" }}
          options={소분류_옵션_리스트?.map(
            ({ categoryName, categoryCode }) => ({
              value: categoryCode,
              name: categoryName,
            })
          )}
          disabled={isEditMode}
        />

        {isSubmitted && !대분류 && !중분류 && !소분류 && (
          <p className="validationErrorMessage">제품분류를 선택해주세요.</p>
        )}
        {isSubmitted && 중분류_옵션_리스트 && 대분류 && !중분류 && (
          <p className="validationErrorMessage">
            중분류 카테고리를 선택해주세요.
          </p>
        )}
        {isSubmitted && 소분류_옵션_리스트 && !소분류 && (
          <p className="validationErrorMessage">
            소분류 카테고리를 선택해주세요.
          </p>
        )}

        {isEditMode && (
          <p className="redtext">* 제품분류는 등록 후 수정이 불가합니다.</p>
        )}
      </Shared.VStack>
    );
  }
);
